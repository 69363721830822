import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateInvoice}  from '../../redux/actions/Invoice';
// Components
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
import DragFile     from '../global/DragFile';
// Values
import {StateView, toastText, serviceSelect} from '../../values';
// Api
import {updateData, updateValidate}   from '../../utils/invoice';
import {deployImage}    from '../../utils';
// Styles
import {useStyles}  from '../../style';


const Form = ({data, token, stateView, changeView, updateInvoice}) => {
    const classes                   = useStyles()

    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [legalName, setLegalName]     = useState(data.LegalName?data.LegalName:'')
    const [invoiceUse, setInvoiceUse]   = useState(data.InvoiceUse?data.InvoiceUse:'')
    const [service, setService]         = useState(data.Service?data.Service:'TYRE')
    const [idService, setIdService]     = useState(data.IdService?data.IdService:'')
    const [total, setTotal]             = useState(data.TotalService?data.TotalService:'')
    const [rfc, setRfc]                 = useState(data.RFC?data.RFC:'')
    const [email, setEmail]             = useState(data.Email?data.Email:'')

    const [pdfUrl, setPdfUrl]           = useState(data.PdfUrl?data.PdfUrl:'')
    const [pdfAux, setPdfAux]           = useState(data.PdfUrl?data.PdfUrl:'')

    const [xmlUrl, setXmlUrL]           = useState(data.XmlUrl?data.XmlUrl:'')
    const [xmlAux, setXmlAux]           = useState(data.XmlUrl?data.XmlUrl:'')


    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateInvoice([])
    }
    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
        setblockData('')
        if(value===2){
            const validate = updateValidate(legalName, invoiceUse, service, total, rfc, email, pdfUrl, xmlUrl)
            if(validate.flag){
                let UrlPdf = pdfAux
                let UrlXml = xmlAux
                if(typeof pdfAux==='object'){
                    UrlPdf = await deployImage(pdfAux, "Invoice", "pdf")
                    setPdfUrl(UrlPdf)
                }
                if(typeof xmlAux==='object'){
                    UrlXml = await deployImage(xmlAux, "Invoice", "xml")
                    setXmlUrL(UrlXml)
                }
                const res = await updateData(data.Id, token, legalName, invoiceUse, service, total, rfc, email, UrlPdf, UrlXml, idService)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateInvoice([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
                setEdit(1)
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
    }

    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex', marginBottom:40}}>
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex', justifyContent:'center', paddingLeft:140}}>
                                <div style={{width:'100%', textAlign:'center', position:'relative'}}>
                                    <DragFile file={pdfUrl} setData={setPdfUrl} setDataAux={setPdfAux} option={'1'} blockData={blockData} />
                                    <div onClick={()=>window.open(pdfUrl, '_blank')}
                                        style={{background:'#266df1', width:100, position:'absolute', display:pdfUrl?'block':'none',
                                        left:'50%',  bottom:-30, transform:'translate(-50%, 0)', cursor:'pointer', borderRadius:5}}>
                                        <label style={{textDecoration:'none', color:'#fff', cursor:'pointer'}}>Ver PDF</label>
                                    </div>
                                </div>
                                <div style={{width:20}} />
                                <div style={{width:'100%', textAlign:'center', position:'relative'}}>
                                    <DragFile file={xmlUrl} setData={setXmlUrL} setDataAux={setXmlAux} accept={'text/xml'} option={'2'} blockData={blockData} />
                                    <div onClick={()=>window.open(xmlUrl, '_blank')}
                                        style={{background:'#266df1', width:100, position:'absolute', display:xmlUrl?'block':'none',
                                        left:'50%', bottom:-30, transform:'translate(-50%, 0)', cursor:'pointer', borderRadius:5}}>
                                        <label style={{textDecoration:'none', color:'#fff', cursor:'pointer'}}>Ver XML</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <Input title={'Nombre'} value={legalName} setData={setLegalName} blockData={'disabled'} />
                            <Input title={'Uso'} value={invoiceUse} setData={setInvoiceUse} blockData={'disabled'} />
                            <div style={{display:'flex', alignContent:'center', width:'100%'}}>
                                <p className={classes.title_input}>{'Servicio'}</p>
                                <select style={{cursor:'disabled' === 'disabled' ? '' : 'pointer'}}
                                    defaultValue={service} 
                                    className={classes.select} 
                                    onChange={e => setService(e.target.value)}
                                    disabled={'disabled'}>
                                        {
                                            serviceSelect.map((item, index) =>(
                                                <option key={index} value={item.Type}>{item.Text}</option>
                                            ))
                                        }
                                </select>
                            </div>
                            {/* <Input title={'Servicio'} value={service} setData={setService} blockData={blockData} /> */}
                            <Input title={'Total'} value={total} setData={setTotal} type={'number'} blockData={'disabled'} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Rfc'} value={rfc} setData={setRfc} blockData={'disabled'} />
                            <Input title={'Email'} value={email} setData={setEmail} blockData={'disabled'} />
                            <Input title={'Id Servicio'} value={idService} setData={setIdService} blockData={'disabled'} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Invoice.invoiceEdit,
    token:          state.Panel.token,
})

export default connect(mapStateToProps, {changeView, updateInvoice})(Form);