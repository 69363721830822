import {stateData, reduxState} from '../../values';

const initialState = {
    history:        [],
    status:         stateData.state1,
    historyEdit:    [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_history){
        return {
            ...state,
            history:    action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_history){
        return {
            ...state,
            historyEdit: action.payload
        }
    }
    return state
}
export default reducer