import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import firebase from "./firebase";
import { getData } from "./utils/permissions";
import store from "./redux";
import { getPermissions } from "./redux/actions/Permissions";
import { updateMenu } from "./redux/actions/Menu";

import { selectedMenu } from "./values";

import "./App.css";
import "./fonts/styles.css";

import Login from "./components/login/Login";
import Menu from "./components/global/Menu";
import Home from "./components/home/Home";

import Admins from "./components/admins/Index";
import Users from "./components/users/Index";
import Fares from "./components/fares/Main";
import Companies from "./components/companies/Index";
import Partners from "./components/partners/Index";
import Branches from "./components/branches/Index";
import Vehicles from "./components/vehicles/Index";
import Apps from "./components/app/Index";
import Services from "./components/services/Index";
import Payment from "./components/payment/Index";
import Invoice from "./components/invoice/Index";
import Contact from "./components/contact/Index";
import Reports from "./components/reports/Index";
import Notifications from "./components/notifications/Index";
import Cities from "./components/cities/Index";
import History from "./components/history/Index";
import Faqs from "./components/faqs/Index";
import Channels from "./components/channels/Index";
import ServiceCenter from "./components/serviceCenter/ServiceCenter";
import Zones from "./components/zones/Zones";

import RestorePassword from "./components/RestorePassword/RestorePassword";

const Default = () => {
  return <div></div>;
};

function App() {
  const [flag, setFlag] = useState(false);

  const getAllPermissions = async () => {
    const res = await getData();
    // console.log("RES: ", res.data);
    store.dispatch(getPermissions(res.data));
  };

  const validatePermissions = (value) => {
    let array = [];
    selectedMenu.forEach((i) => {
      if (i.permission === "Home") {
        array.push(i);
      }
      if (value.includes(i.permission)) {
        array.push(i);
      }
    });
    store.dispatch(updateMenu(array));
  };

  const verifyUser = async () => {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log("Admin auth");
        let permissions = [];
        // Update/Refresh token
        await firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (token) {
            localStorage.setItem("llaoz-key", token);
          });
        // Get Roles
        await firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            localStorage.setItem("llaoz-roles", JSON.stringify(idTokenResult.claims.roles));
            permissions = idTokenResult.claims.roles;
          });
        setFlag(true);
        // Get All Permissions
        await getAllPermissions();

        // Validate userpermision and menu
        validatePermissions(permissions);

        // Redirect
        if (window.location.pathname === "/") {
          window.location.href = "/home";
        }
      } else {
        setFlag(false);
      }
    });
  };
  useEffect(() => {
    verifyUser();
  });
  return (
    <Router>
      <ToastContainer />

      <Route exact path="/" component={Login} />
      <Route exact path="/restore-password/" component={RestorePassword} />
      {flag ? (
        <>
          <Route exact path="/home">
            <Menu component={Home} />
          </Route>
          <Route exact path="/admins">
            <Menu component={Admins} />
          </Route>
          <Route exact path="/users">
            <Menu component={Users} />
          </Route>
          <Route exact path="/cities">
            <Menu component={Cities} />
          </Route>
          <Route exact path="/fares">
            <Menu component={Fares} />
          </Route>
          <Route exact path="/companies">
            <Menu component={Companies} />
          </Route>
          <Route exact path="/partners/:id?">
            <Menu component={Partners} />
          </Route>
          <Route exact path="/branches/:id?">
            <Menu component={Branches} />
          </Route>
          <Route exact path="/vehicles/:id?">
            <Menu component={Vehicles} />
          </Route>
          <Route exact path="/app">
            <Menu component={Apps} />
          </Route>
          <Route exact path="/services">
            <Menu component={Services} />
          </Route>
          <Route exact path="/history">
            <Menu component={History} />
          </Route>
          <Route exact path="/payment">
            <Menu component={Payment} />
          </Route>
          <Route exact path="/invoice">
            <Menu component={Invoice} />
          </Route>
          <Route exact path="/contact">
            <Menu component={Contact} />
          </Route>
          <Route exact path="/reports">
            <Menu component={Reports} />
          </Route>
          <Route exact path="/notifications">
            <Menu component={Notifications} />
          </Route>
          <Route exact path="/faqs">
            <Menu component={Faqs} />
          </Route>
          <Route exact path="/channels">
            <Menu component={Channels} />
          </Route>
          <Route exact path="/servicecenter">
            <Menu component={ServiceCenter} />
          </Route>
          <Route exact path="/zones">
            <Menu component={Zones} />
          </Route>
        </>
      ) : (
        <>
          <Route path="*">
            {/* <Redirect to="/" /> */}
            <Default />
          </Route>
        </>
      )}
    </Router>
  );
}

export default App;

// target comand
// firebase target:apply hosting panel llaoz-sandbox

// target comand
// firebase target:apply hosting panel llaoz-panel

// firebase target:apply hosting llaoz-panel llaoz-c120d
