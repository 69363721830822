import {stateData, reduxState} from '../../values';

export const getCities = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_cities,
            payload:    data.Data,
            status:     stateData.state2,
            records:    data.Records
        })
    }
}

export const updateCities = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_cities,
            payload:    data,
        })
    }
}