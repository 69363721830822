import {Container}  from '@material-ui/core';
import { connect } from 'react-redux';
// Redux
import {changeView} from '../../redux/actions/Menu';
// Components
import Button       from './Button';
// Values
import {StateView}  from '../../values';
const Head = ({title='', stateView, changeView, view=true}) => {
    const addView = () =>{
        changeView(StateView.state2)
    }
    const addData = () =>{
        console.log("Agregando producto");
    }
    const editData = () =>{
        console.log("Editar productos");
    }
    const back = () =>{
        changeView(StateView.state1)
    }
    return (
        <div style={{width:'100%'}}>
            <Container maxWidth="xl">
                <div style={{ display:'flex', alignItems:'center', padding:'10px 0', justifyContent:'space-between', height:40, marginBottom:10}}>
                    <h2 style={{margin:0, marginRight:20, fontFamily:'bold'}}>{title}</h2>
                    {
                        view&&
                        stateView===StateView.state1?
                            <Button text={'Agregar'} onClick={addView} />
                        :
                            <div />
                    }
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
})

export default connect(mapStateToProps, {changeView})(Head);