import {stateData, reduxState} from '../../values';

const initialState = {
    permissions:    [],
    status:         stateData.state1,
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_permissions	){
        return {
            ...state,
            permissions:    action.payload,
            status:         action.status,
        }
    }
    return state
}
export default reducer