import {stateData, reduxState} from '../../values';

export const getUsers = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_users,
            payload:    data.Data,
            status:     stateData.state2,
            records:    data.Records
        })
    }
}

export const updateUsers = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_users,
            payload:    data,
        })
    }
}