import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Redux
import { changeView } from "../../redux/actions/Menu";
import { updateFares } from "../../redux/actions/Fares";
// Components
import Input from "../global/Input";
import ButtonsForm from "../global/ButtonsForm";

import { StateView, serviceSelect, vehiclesSelect, toastText, calcTotal, calcTotalChecOut } from "../../values";
import { useStyles } from "../../style";
import { validateData, updateData, postData, deleteData } from "../../utils/fares";
import { formatMoney } from "../../utils";

const Item = ({ title, value }) => {
  return (
    <div style={{ display: "flex", textTransform: "uppercase", marginTop: 18 }}>
      <p style={{ margin: 0, width: 180 }}>{title}</p>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
};
const Form = ({ stateView, data, token, changeView, updateFares }) => {
  const classes = useStyles();
  const [blockData, setblockData] = useState(stateView === StateView.state2 ? "" : "disabled");
  // State buttons ----------------------------------------------------------------
  const [edit, setEdit] = useState(0);
  const [erase, setErase] = useState(0);
  const [add, setAdd] = useState(0);
  // Data -------------------------------------------------------------------------
  const [service, setService] = useState(data.Service ? data.Service : "TYRE");
  const [vehicle, setVehicle] = useState(data.Vehicle ? data.Vehicle : "MOTORCYCLE");
  const [amount, setAmount] = useState(data.Amount ? data.Amount : 0); //TotalPartner
  const [kmMin, setKmMin] = useState(data.MinKm ? data.MinKm : 0);
  const [kmMax, setKmMax] = useState(data.MaxKm ? data.MaxKm : 0);
  const [extra, setExtra] = useState(data.ExtraKm ? data.ExtraKm : 0);
  const [llaozFixed, setLlaozFixed] = useState(data.LlaozFixed ? data.LlaozFixed : 0);
  const [llaozPercent, setLlaozPercent] = useState(data.LlaozPercent ? data.LlaozPercent : 0);
  const [checkOutFixed, setCheckOutFixed] = useState(data.CheckOutFixed ? data.CheckOutFixed : 0);
  const [checkOutPercent, setCheckOut] = useState(data.CheckOutPercent ? data.CheckOutPercent : 0);
  const [range, setRange] = useState(data.Range ? data.Range : 0);

  const [calcLlaoz, setCalcLlaoz] = useState(0);
  const [calcCheckout, setCalcCheckout] = useState(0);

  const [clientPayment, setClientPayment] = useState("");
  const [partnerTotal, setPartnerTotal] = useState("");

  const [total, setTotal] = useState(0);

  // Functions --------------------------------------------------------------------
  const Back = () => {
    changeView(StateView.state1);
    updateFares([]);
  };
  const Edit = async () => {
    const value = edit + 1;
    setblockData("");
    setEdit(value);
    if (value === 2) {
      const validate = validateData(
        kmMin,
        kmMax,
        amount,
        extra,
        llaozFixed,
        llaozPercent,
        checkOutFixed,
        checkOutPercent,
        range
      );
      if (validate.flag) {
        const res = await updateData(
          data.Id,
          token,
          service,
          vehicle,
          kmMin,
          kmMax,
          amount,
          extra,
          llaozFixed,
          llaozPercent,
          checkOutFixed,
          checkOutPercent,
          range
        );
        if (res) {
          toast.success(toastText.update);
          console.log(toastText.update);
          changeView(StateView.state1);
          updateFares([]);
        } else {
          toast.warn(toastText.warning);
          console.log(toastText.warning);
          setEdit(1);
        }
      } else {
        toast.warn(validate.message);
        console.log(validate.message);
        setEdit(1);
      }
    }
  };
  const Add = async () => {
    const value = add + 1;
    setAdd(value);
    if (value === 1) {
      const validate = validateData(
        kmMin,
        kmMax,
        amount,
        extra,
        llaozFixed,
        llaozPercent,
        checkOutFixed,
        checkOutPercent,
        range
      );
      if (validate.flag) {
        const res = await postData(
          token,
          service,
          vehicle,
          kmMin,
          kmMax,
          amount,
          extra,
          llaozFixed,
          llaozPercent,
          checkOutFixed,
          checkOutPercent,
          range
        );
        if (res) {
          toast.success(toastText.add);
          console.log(toastText.add);
          changeView(StateView.state1);
        } else {
          toast.warn(toastText.warning);
          console.log(toastText.warning);
          setAdd(0);
        }
      } else {
        toast.warn(validate.message);
        console.log(validate.message);
        setAdd(0);
      }
    }
  };
  const Delete = async () => {
    const value = erase + 1;
    setErase(value);
    if (value === 1) {
      const res = await deleteData(data.Id, token);
      if (res) {
        toast.success(toastText.delete);
        console.log(toastText.delete);
        changeView(StateView.state1);
        updateFares([]);
      } else {
        toast.warn(toastText.warning);
        console.log(toastText.warning);
        setErase(0);
      }
    }
  };

  const calculate = () => {
    const totalService = parseFloat(amount) * 1.16; //Pago del cliente
    // console.log("Total socio: ", totalService);

    const commisionL = (totalService + 1.16 * llaozFixed) / (1 - 1.16 * (llaozPercent / 100));
    // console.log("Comisión Llaoz: ", commisionL);

    const total = (commisionL + 1.16 * checkOutFixed) / (1 - 1.16 * (checkOutPercent / 100));
    // console.log("Total: ", total);

    const checkoutP = (total * checkOutPercent) / 100;
    const checkout = checkoutP + parseFloat(checkOutFixed) + (checkoutP + parseFloat(checkOutFixed)) * 0.16;
    // console.log("Comisión Pasarela: ", checkout);

    const llaozP = (llaozPercent * total) / 100;
    const llaozIva = (llaozP + parseFloat(llaozFixed)) * 0.16;
    const totalLlaoz = llaozP + parseFloat(llaozFixed) + llaozIva;

    setClientPayment(total);
    setCalcLlaoz(totalLlaoz);
    setPartnerTotal(totalService);
    setCalcCheckout(checkout);
  };

  useEffect(() => {
    calculate();
  }, [amount, llaozFixed, llaozPercent, checkOutPercent, checkOutPercent]);

  return (
    <Container maxWidth="xl">
      <div style={{ background: "#fff", paddingBottom: 50, paddingTop: 20 }}>
        <Container maxWidth="xl">
          <div style={{ display: "flex" }}>
            <div style={{ width: "48%" }}>
              <div style={{ display: "flex", alignContent: "center", width: "100%" }}>
                <p className={classes.title_input}>{"Servicio"}</p>
                <select
                  style={{ cursor: blockData === "disabled" ? "" : "pointer" }}
                  defaultValue={service}
                  className={classes.select}
                  onChange={(e) => setService(e.target.value)}
                  disabled={blockData}
                >
                  {serviceSelect.map((item, index) => (
                    <option key={index} value={item.Type}>
                      {item.Text}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: "flex", alignContent: "center", width: "100%" }}>
                <p className={classes.title_input}>{"Vehiculos"}</p>
                <select
                  style={{ cursor: blockData === "disabled" ? "" : "pointer" }}
                  defaultValue={vehicle}
                  className={classes.select}
                  onChange={(e) => setVehicle(e.target.value)}
                  disabled={blockData}
                >
                  {vehiclesSelect.map((item, index) => (
                    <option key={index} value={item.Type}>
                      {item.Text}
                    </option>
                  ))}
                </select>
              </div>
              <Input title={"KM - MIN"} value={kmMin} setData={setKmMin} blockData={blockData} type={"number"} />
              <Input title={"KM - MAX"} value={kmMax} setData={setKmMax} blockData={blockData} type={"number"} />
              <Input title={"Total Socio"} value={amount} setData={setAmount} blockData={blockData} type={"number"} />
              <Input title={"Extra"} value={extra} setData={setExtra} blockData={blockData} type={"number"} />
              <Input title={"Liminte - Km"} value={range} setData={setRange} blockData={blockData} type={"number"} />
            </div>
            <div style={{ width: "4%" }} />
            <div style={{ width: "48%" }}>
              <Input
                title={"Llaoz $"}
                value={llaozFixed}
                setData={setLlaozFixed}
                blockData={blockData}
                type={"number"}
              />
              <Input
                title={"Llaoz %"}
                value={llaozPercent}
                setData={setLlaozPercent}
                blockData={blockData}
                type={"number"}
              />
              <Input
                title={"Pasarela $"}
                value={checkOutFixed}
                setData={setCheckOutFixed}
                blockData={blockData}
                type={"number"}
              />
              <Input
                title={"Pasarela %"}
                value={checkOutPercent}
                setData={setCheckOut}
                blockData={blockData}
                type={"number"}
              />

              <Item title="Pago del cliente" value={`$ ${formatMoney(clientPayment)} MXN`} />
              <Item title="Comisión Llaoz" value={`$ ${formatMoney(calcLlaoz)} MXN`} />
              <Item title="Comisión Pasarela" value={`$ ${formatMoney(calcCheckout)} MXN`} />
              <Item title="Pago del socio" value={`$ ${formatMoney(partnerTotal)} MXN`} />

              {/* <Input title={'Total Llaoz'} value={(calcLlaoz).toFixed(2)} blockData={'disabled'} type={'number'} />
                            <Input title={'Total Pasarela'} value={(calcCheckout).toFixed(2)} blockData={'disabled'} type={'number'} />
                            <Input title={'Costo - MXN'} value={(total).toFixed(2)} blockData={'disabled'} type={'number'} /> */}
            </div>
          </div>
        </Container>
      </div>
      <ButtonsForm
        View={stateView}
        Back={Back}
        Edit={Edit}
        edit={edit}
        Add={Add}
        add={add}
        Delete={Delete}
        erase={erase}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.View.stateView,
  data: state.Fares.faresEdit,
  token: state.Panel.token,
});

export default connect(mapStateToProps, { changeView, updateFares })(Form);
