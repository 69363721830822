import {useState}       from 'react';
import {useLocation}    from 'react-router-dom';
import {Container}      from '@material-ui/core';
import {toast}          from 'react-toastify';
import queryString      from 'query-string'; //https://ui.dev/react-router-v5-query-strings/

// Components
import Input    from './Input';
import Button   from './Button';
// Values
import {Ic_sorry, Ic_success, Ic_lock, Img_logo} from '../../values';
// Api
import {resetPassword}  from '../../utils/resetPassword';

const RestorePassword = () => {
    const { search } = useLocation()
    const values = queryString.parse(search)

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const [eye1, setEye1]             = useState(false)
    const [eye2, setEye2]             = useState(false)

    const [loading, setLoading]     = useState(false)

    const [status1, setStatus1]     = useState(0)// 0 nada, 1 Ok, 2 error
    const [status2, setStatus2]     = useState(0)

    const [response, setResponse]   = useState(0)

    const validateData = (value, option) =>{
        if(option===1){
            setPassword1(value)
            if(value.length >= 6){
                setStatus1(1)
                if(value === password2){
                    setStatus1(1)
                    setStatus2(1)
                } 
            } else{
                setStatus1(2)
            }
        } else{
            setPassword2(value)
            if(value.length >= 6){
                setStatus2(1)
                if(value === password1){
                    setStatus1(1)
                    setStatus2(1)
                }
            } else{
                setStatus2(2)
            }
        }
    }

    const passwordsAreEquals = () =>{
        let flag = false
        if(password1===password2){
            flag = true
        }
        return flag
    }

    const standBy = (event) =>{
        console.log("Stand-By");
    }

    const reset = async() => {
        const res = await resetPassword(values.oobCode, password1)
        if(res){
            setResponse(1)
            setLoading(false)
        } else{
            setResponse(2)
            setLoading(false)
        }
    }

    const onSubmit = () =>{
        if(status1===1 && status2===1){
            setLoading(true)
            const res = passwordsAreEquals()
            if(res){
                // setResponse(1)
                setStatus1(1)
                setStatus2(1)
                reset()
                // login()
            } else{
                toast.warn('Las contraseñas tienen que coincidir.')
                setStatus1(2)
                setStatus2(2)
                setLoading(false)
            }
        }
        if(status1 !== 1){
            setStatus1(2)
        }
        if(status2 !== 1){
            setStatus2(2)
        }
    }

    return (
        <div className="container_login">
            <div style={{position:'absolute', width:'100vw', height:'35vh', backgroundColor:'#06184D', top:0, zIndex:1}} />
            <Container maxWidth="sm" style={{zIndex:5, marginTop:-100}}>
                <div className="login_card">
                    <Container>
                        <div style={{paddingTop:30, marginBottom:50, display:'flex', justifyContent:'space-between'}}>
                            <img alt="Llaoz" src={Img_logo} style={{width:100}} />
                            <img alt="Ic_lock" src={Ic_lock} style={{width:30}} />
                        </div>
                        <div style={{display:response===0?'block':'none'}}>
                            <label style={{fontFamily:'semi'}}>{"Ingresa tu nueva contraseña"}</label>
                            <div style={{height:15}} />

                            <div style={{backgroundColor:'#f1f4f8', borderRadius:10, paddingTop:40, paddingBottom:15}}>
                                <Container>
                                    <Input text={'Contraseña'} type={eye1?'text':'password'} view={true} eye={eye1} setEye={setEye1} 
                                            changeData={validateData} opt={1} status={status1} />
                                    <div style={{height:40}} />

                                    <Input text={'Confirmar contraseña'} type={eye2?'text':'password'} view={true} eye={eye2} setEye={setEye2} 
                                        changeData={validateData} opt={2} status={status2} />
                                    <div style={{height:20}} />
                                </Container>
                            </div>
                            <Button onSubmit={loading?standBy:onSubmit} loading={loading} />

                        </div>

                        <div style={{textAlign:'center', display:response===1?'block':'none', position:'relative'}}>
                            <div style={{position:'absolute', width:'100%', top:-20}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:180}}>
                                    <img alt="Ic_lock" src={Ic_success} style={{width:170}} />
                                </div>
                                <p style={{fontFamily:'semi', fontSize:20, marginBottom:0, marginTop:15}}>{"¡Listo!"}</p>
                                <p style={{color:'#6d7c92', fontFamily:'semi', fontSize:16, marginTop:0}}>{"La contraseña se ha cambiado con éxito"}</p>
                            </div>
                            <div style={{height:70}} />
                            <div style={{backgroundColor:'#f1f4f8', height:220, borderRadius:10}}/>
                        </div>

                        <div style={{textAlign:'center', display:response===2?'block':'none', position:'relative'}}>
                            <div style={{position:'absolute', width:'100%', top:-20}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:180}}>
                                    <img alt="Ic_lock" src={Ic_sorry} style={{width:170}} />
                                </div>
                                <p style={{fontFamily:'semi', fontSize:20, marginBottom:0, marginTop:15}}>{"¡Ups!"}</p>
                                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                    <p style={{color:'#6d7c92', fontFamily:'bold', fontSize:16, width:300, marginTop:5}}>{"Lo sentimos, no se pudo completar la acción, vuelve a solicitar el cambio"}</p>
                                </div>
                            </div>
                            <div style={{height:70}} />
                            <div style={{backgroundColor:'#f1f4f8', height:220, borderRadius:10}}/>
                        </div>

                    </Container>
                </div>
            </Container>
        </div>
    );
};

export default RestorePassword;