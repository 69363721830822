import {useState}   from 'react';
import {Container}  from '@material-ui/core';
import { connect }  from 'react-redux';
// Redux
import {changeView}     from '../../redux/actions/Menu';
// Components
import Input            from '../global/Input';
import ButtonsForm      from '../global/ButtonsForm';
import TablesPayments   from './TablePayments';
import MultipleInput    from '../global/MultipleInput';

// Values
import {StateView} from '../../values';
// Styles
import {useStyles} from '../../style';

const Form = ({stateView, changeView}) => {
    const classes = useStyles()
    const [blockData, setBlockData] = useState('disabled')
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [id, setId]                       = useState('')
    const [status, setStatus]               = useState(false)
    const [percentStatus, setPercentStatus] = useState(0)


    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
    }

    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
    }

    const changeSlide = (value, e) =>{
        if(value < 10){
            setStatus(false)
            setPercentStatus(0)
        }else if(value > 90){
            setStatus(true)
            setPercentStatus(100)
        } else{
            setPercentStatus(value)
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:60, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%', position:'relative'}}>
                            <Input title={'Id Pago'} value={id} setData={setId} />
                            <Input title={'Total'} value={id} setData={setId} />
                            <Input title={'N Servicios'} value={id} setData={setId} />
                            <div style={{marginLeft:140,}}>
                                <div className={classes.status} style={{background:`linear-gradient(to right,#44d7b6 ${percentStatus}%,  #EBF5F7 0%)`}}>
                                    <span class={classes.text1} style={{display:`${status?'none':'block'}`, color:'#000'}}>PAGO PENDIENTE</span>
                                    <span class={classes.text1} style={{display:`${status?'block':'none'}`}}>PAGADO</span>
                                    <input 
                                        readOnly={blockData==='disabled'?true:false}
                                        class={status?classes.confirm2:classes.confirm} 
                                        type="range" value={percentStatus} min="0" max="100" 
                                        onChange={e=>changeSlide(e.target.value, e)} />
                                </div>
                            </div>
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            {/* <Input title={'Empresa'} value={id} setData={setId} /> */}
                            <MultipleInput title={'Empresa'} 
                                value1={id} setData1={setId}
                                value2={id} setData2={setId} />
                                
                            <Input title={'CLABE'} value={id} setData={setId} />
                            <Input title={'Referencia'} value={id} setData={setId} />
                        </div>
                    </div>
                    <div style={{marginLeft:140}}>
                        <TablesPayments />
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
})

export default connect(mapStateToProps, {changeView})(Form);
