import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateVehicles} from '../../redux/actions/Vehicles';
// Components
import DragImage    from '../global/DragImage';
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
import MultipleInput    from '../global/MultipleInput';
import DatePickers      from '../global/DatePickers';
import Availability     from '../global/ButtonsAvailability';
// Values
import {StateView, toastText} from '../../values';
// Styles
import {useStyles} from '../../style';
// Api
import {updateData, postData, deleteData, postValidate, updateValidate}   from '../../utils/vehicles';
import {getACompany}    from '../../utils/companies';
import {deployImage}    from '../../utils';

const Form = ({stateView, data, changeView, updateVehicles, token}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")

    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)
    const [loadingSearch, setLoadingSearch] = useState(false)

    // Data -------------------------------------------------------------------------
    const [model, setModel]         = useState(data.Model?data.Model:'')
    const [year, setYear]           = useState(data.Year?data.Year:'')
    const [plates, setPlates]       = useState(data.Plates?data.Plates:'')
    const [docExpire, setExpire]    = useState(data.DocumentExpires?data.DocumentExpires:new Date())

    const [city, setCity]           = useState(data.City?data.City:'')
    const [company, setCompany]     = useState(data.Company?data.Company:'')
    const [companyId, setCompanyId] = useState(data.CompanyId?data.CompanyId:'')
    const [picture, setPicture]     = useState(data.Picture?data.Picture:'')
    const [docPicture, setDocPicture]       = useState(data.DocumentPicture?data.DocumentPicture:'')
    const [availability, setAvailability]   = useState(data.Availability?data.Availability:{CAR:false, MOTORCYCLE:false, TRUCK:false, VAN:false})

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateVehicles([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
        setblockData('')
        if(value===2){
            const validate = updateValidate(model, year, plates, city, company, companyId)
            if(validate.flag){
                let UrlImage1 = picture
                let UrlImage2 = docPicture
                if(typeof picture==='object'){
                    UrlImage1 = await deployImage(picture, "Vehicles", "vehicle")
                    setPicture(UrlImage1)
                }
                if(typeof docPicture==='object'){
                    UrlImage2 = await deployImage(docPicture, "Vehicles", "document")
                    setPicture(UrlImage2)
                }
                const res = await updateData(data.Id, token, model, year, plates, city, company, companyId, UrlImage1, UrlImage2, docExpire, availability)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateVehicles([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = postValidate(model, year, plates, city, company, companyId, picture, docPicture)
            if(validate.flag){
                let UrlImage1 = picture
                let UrlImage2 = docPicture
                if(typeof picture==='object'){
                    UrlImage1 = await deployImage(picture, "Vehicles", "vehicle")
                    setPicture(UrlImage1)
                }
                if(typeof docPicture==='object'){
                    UrlImage2 = await deployImage(docPicture, "Vehicles", "document")
                    setPicture(UrlImage2)
                }
                const res = await postData(token, model, year, plates, city, company, companyId, UrlImage1, UrlImage2, docExpire, availability)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateVehicles([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    const changeValue = (opt) =>{
        const value = {}
        if(blockData!=='disabled'){
            if(opt===1){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = !availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = availability.VAN
            }
            if(opt===2){
                value.CAR           = !availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = availability.VAN
            }
            if(opt===3){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = !availability.VAN
            }
            if(opt===4){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = !availability.TRUCK
                value.VAN           = availability.VAN
            }
            setAvailability(value)
        }
    }

    const getNameCompany = async() =>{
        setLoadingSearch(true)
        const res = await getACompany(token, `/${companyId}`)
        if(res.data.Name){
            setCompany(res.data.Name)
            setLoadingSearch(false)
        } else{
            setCompany("ERROR")
            setLoadingSearch(false)
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <DragImage image={picture} setImage={setPicture} height={300} text={"VEHICULO"} option={1} blockData={blockData} />
                            <div style={{height:20}} />
                            <DragImage image={docPicture} setImage={setDocPicture} height={300} text={"TARJETA C."} option={2} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <div style={{display:data.Id?'block':'none'}}>
                                <Input title={'Id'} value={data.Id} blockData={'disabled'} />
                            </div>
                            <Input title={'Modelo'} value={model} setData={setModel} blockData={blockData} />
                            <Input title={'Año'} value={year} setData={setYear} blockData={blockData} />
                            <Input title={'Placas'} value={plates} setData={setPlates} blockData={blockData} />
                            <DatePickers date={docExpire} setDate={setExpire} title={'Tarjeta C.'} disabled={blockData} />
                            <Input title={'Ciudad'} value={city} setData={setCity} blockData={blockData} />

                            <MultipleInput title={'Empresa'} 
                                search={getNameCompany}
                                value1={company} setValue1={setCompany}
                                value2={companyId} setValue2={setCompanyId} 
                                loading={loadingSearch}
                                blockData={blockData} />

                            <Availability value={availability} setValue={changeValue} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};


const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Vehicles.vehiclesEdit,
    token:          state.Panel.token,
})

export default connect(mapStateToProps, {changeView, updateVehicles})(Form);

