import { useEffect } from 'react';
import { connect } from 'react-redux';
// Redux
import {getFares}   from '../../redux/actions/Fares';
// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// APi
import {getData}    from '../../utils/fares';
const Index = ({stateView, token, getFares}) => {
    const GetData = async() =>{
        const res = await getData(token)
        getFares(res.data)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Tarifas' />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    token:          state.Panel.token
})

export default connect(mapStateToProps, {getFares})(Index);
