import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Firebase
import firebase from "../../firebase";
// Redux
import { changeView } from "../../redux/actions/Menu";
import { updatePartners } from "../../redux/actions/Partners";
// Components
import DragImage from "../global/DragImage";
import Input from "../global/Input";
import ButtonsForm from "../global/ButtonsForm";
import MultipleInput from "../global/MultipleInput";
// Styles
import { useStyles } from "../../style";
// Values
import { StateView, partnerRolesSelect, toastText } from "../../values";
// Api
import { updateValidate, postValidate, updateData, postData, deleteData } from "../../utils/partners";
import { getACompany } from "../../utils/companies";
import { deployImage } from "../../utils";

const Form = ({ stateView, data, token, changeView, updatePartners }) => {
  const [blockData, setblockData] = useState(stateView === StateView.state2 ? "" : "disabled");
  const classes = useStyles();

  // State buttons ----------------------------------------------------------------
  const [edit, setEdit] = useState(0);
  const [erase, setErase] = useState(0);
  const [add, setAdd] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);

  // Data -------------------------------------------------------------------------
  const [picture, setPicture] = useState(data.PictureProfile ? data.PictureProfile : "");
  const [document, setDocument] = useState(data.PictureDocument ? data.PictureDocument : "");
  const [name, setName] = useState(data.Name ? data.Name : "");
  const [surname, setSurname] = useState(data.Surname ? data.Surname : "");
  const [email, setEmail] = useState(data.Email ? data.Email : "");
  const [phone, setPhone] = useState(data.Phone ? data.Phone : "");
  const [pin, setPin] = useState(data.PIN ? data.PIN : "");
  const [rol, setRol] = useState(data.Rol ? data.Rol : "ADMIN");
  const [company, setCompany] = useState(data.Company ? data.Company : "");

  const [companyId, setCompanyId] = useState(data.CompanyId ? data.CompanyId : "");

  const [tokenFcm, setTokenFcm] = useState(data.TokenFCM ? data.TokenFCM : "");
  const [deviceInfo, setDeviceInfo] = useState(data.DeviceInfo ? data.DeviceInfo : "");
  const [serviceId, setServiceId] = useState(data.ServiceId ? data.ServiceId : "");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  // Functions --------------------------------------------------------------------
  const Back = () => {
    changeView(StateView.state1);
    updatePartners([]);
  };

  const Edit = async () => {
    const value = edit + 1;
    setEdit(value);
    setblockData("");
    if (value === 2) {
      const validate = updateValidate(name, surname, phone, pin, company, companyId);
      if (validate.flag) {
        let UrlImage1 = picture;
        let UrlImage2 = document;
        if (typeof picture === "object") {
          UrlImage1 = await deployImage(picture, "Partners", "picture");
          setPicture(UrlImage1);
        }
        if (typeof document === "object") {
          UrlImage2 = await deployImage(document, "Partners", "document");
          setDocument(UrlImage2);
        }
        const res = await updateData(
          data.Id,
          token,
          name,
          surname,
          phone,
          pin,
          company,
          companyId,
          UrlImage1,
          UrlImage2,
          rol
        );
        if (res) {
          toast.success(toastText.update);
          console.log(toastText.update);
          changeView(StateView.state1);
          updatePartners([]);
        } else {
          toast.warn(toastText.warning);
          console.log(toastText.warning);
          setEdit(1);
        }
      } else {
        toast.warn(validate.message);
        console.log(validate.message);
        setEdit(1);
      }
    }
  };
  const Add = async () => {
    const value = add + 1;
    setAdd(value);
    if (value === 1) {
      const validate = postValidate(
        name,
        surname,
        phone,
        pin,
        company,
        companyId,
        picture,
        document,
        email,
        password1,
        password2
      );
      if (validate.flag) {
        let UrlImage1 = picture;
        let UrlImage2 = document;
        if (typeof picture === "object") {
          UrlImage1 = await deployImage(picture, "Partners", "picture");
          setPicture(UrlImage1);
        }
        if (typeof document === "object") {
          UrlImage2 = await deployImage(document, "Partners", "document");
          setDocument(UrlImage2);
        }
        const res = await postData(
          token,
          name,
          surname,
          phone,
          pin,
          company,
          companyId,
          UrlImage1,
          UrlImage2,
          email,
          rol,
          password1
        );
        if (res) {
          toast.success(toastText.add);
          console.log(toastText.add);
          changeView(StateView.state1);
        } else {
          toast.warn(toastText.warning);
          console.log(toastText.warning);
          setAdd(0);
        }
      } else {
        toast.warn(validate.message);
        console.log(validate.message);
        setAdd(0);
      }
    }
  };
  const Delete = async () => {
    const value = erase + 1;
    setErase(value);
    if (value === 1) {
      const res = await deleteData(data.Id, token);
      if (res) {
        toast.success(toastText.delete);
        console.log(toastText.delete);
        changeView(StateView.state1);
        updatePartners([]);
      } else {
        toast.warn(toastText.warning);
        console.log(toastText.warning);
        setErase(0);
      }
    }
  };
  const restorePassword = () => {
    if (window.confirm(`¿Quieres enviar un correo, para restablecer la contraseña de la cuenta ${email}?`)) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          toast.success(`Se envío a ${email} un correo para restablecer la contraseña`);
        })
        .catch(() => {
          toast.warn("Sucedio un error intentalo más tarde.");
        });
    }
  };

  const getNameCompany = async () => {
    setLoadingSearch(true);
    const res = await getACompany(token, `/${companyId}`);
    if (res.data.Name) {
      setCompany(res.data.Name);
      setLoadingSearch(false);
    } else {
      setCompany("ERROR");
      setLoadingSearch(false);
    }
  };

  const mapData = () => {
    setPicture(data.PictureProfile);
    setDocument(data.PictureDocument);
    setName(data.Name);
    setSurname(data.Surname);
    setEmail(data.Email);
    setPhone(data.Phone);
    setPin(data.PIN);
    setRol(data.Rol);
    setCompany(data.Company);
    setCompanyId(data.CompanyId);
    setTokenFcm(data.TokenFCM);
    setDeviceInfo(data.DeviceInfo);
    setServiceId(data.ServiceId);
  };

  useEffect(() => {
    if (data.length !== 0) {
      mapData();
    }
  }, [data]);

  return (
    <Container maxWidth="xl">
      <div style={{ background: "#fff", paddingBottom: 50, paddingTop: 20 }}>
        <Container maxWidth="xl">
          <div style={{ display: "flex" }}>
            <div style={{ width: "48%" }}>
              <DragImage
                image={picture}
                setImage={setPicture}
                height={300}
                text={"FOTO DE PERFIL"}
                option={1}
                blockData={blockData}
              />
              <div style={{ height: 20 }} />
              <DragImage
                image={document}
                setImage={setDocument}
                height={300}
                text={"INE"}
                option={2}
                blockData={blockData}
              />
            </div>
            <div style={{ width: "4%" }} />
            <div style={{ width: "48%" }}>
              <div style={{ display: stateView === StateView.state2 ? "none" : "block" }}>
                <Input title={"Id"} value={data.Id} blockData={"disabled"} />
              </div>
              <Input title={"Nombre"} value={name} setData={setName} blockData={blockData} />
              <Input title={"Apellidos"} value={surname} setData={setSurname} blockData={blockData} />
              <Input
                title={"Email"}
                value={email}
                setData={setEmail}
                blockData={stateView === StateView.state2 ? "" : "disabled"}
              />
              <div style={{ display: stateView === StateView.state2 ? "block" : "none" }}>
                <Input title={"Contraseña"} value={password1} setData={setPassword1} type={"password"} />
                <Input title={"Contraseña"} value={password2} setData={setPassword2} type={"password"} />
              </div>

              <div style={{ display: stateView === StateView.state2 ? "none" : "block" }}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 20 }}>
                  <p
                    style={{ cursor: "pointer", color: "#5272f1", fontWeight: 600, fontSize: 14 }}
                    onClick={restorePassword}
                  >
                    RESTABLECER CONTRASEÑA
                  </p>
                </div>
              </div>
              <Input title={"Teléfono"} value={phone} setData={setPhone} blockData={blockData} />
              <Input title={"Pin"} value={pin} setData={setPin} blockData={blockData} />
              {/* <Input title={'Puesto'} value={rol} setData={setRol} blockData={blockData} /> */}

              <div style={{ display: "flex", alignContent: "center", width: "100%" }}>
                <p className={classes.title_input}>{"Puesto"}</p>
                <select
                  style={{ cursor: blockData === "disabled" ? "" : "pointer" }}
                  defaultValue={rol}
                  className={classes.select}
                  onChange={(e) => setRol(e.target.value)}
                  disabled={blockData}
                >
                  {partnerRolesSelect.map((item, index) => (
                    <option key={index} value={item.Value}>
                      {item.Info}
                    </option>
                  ))}
                </select>
              </div>

              <MultipleInput
                title={"Empresa"}
                search={getNameCompany}
                value1={company}
                setValue1={setCompany}
                value2={companyId}
                setValue2={setCompanyId}
                loading={loadingSearch}
                blockData={blockData}
              />
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{
          background: "#fff",
          paddingBottom: 30,
          paddingTop: 20,
          marginTop: 5,
          display: stateView === StateView.state2 ? "none" : "block",
        }}
      >
        <Container maxWidth="xl">
          <Input title={"Device Info"} value={deviceInfo} setData={setDeviceInfo} blockData={"disabled"} />
          <Input title={"Token FCM"} value={tokenFcm} setData={setTokenFcm} blockData={"disabled"} />
          <div style={{ width: "50%" }}>
            <Input title={"Service Id"} value={serviceId} setData={setServiceId} blockData={"disabled"} />
          </div>
        </Container>
      </div>
      <ButtonsForm
        View={stateView}
        Back={Back}
        Edit={Edit}
        edit={edit}
        Add={Add}
        add={add}
        Delete={Delete}
        erase={erase}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.View.stateView,
  data: state.Partners.partnersEdit,
  token: state.Panel.token,
});

export default connect(mapStateToProps, { changeView, updatePartners })(Form);
