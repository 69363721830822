import {StateView, reduxState}  from '../../values';

const initialState = {
    viewDrawer:     true,
    stateView:      StateView.state1,
    selectedMenu:   []
};

const View = (state = initialState, action) =>{
    if(action.type === reduxState.drawer){
        return{
            ...state,
            viewDrawer: action.payload
        }
    }
    if(action.type === reduxState.view){
        return{
            ...state,
            stateView: action.view
        }
    }
    if(action.type === reduxState.update_menu){
        return{
            ...state,
            selectedMenu: action.payload
        }
    }
    return state
}
export default View;