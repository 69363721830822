import { Fragment, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { makeStyles } from "@material-ui/core/styles";

// Components
// import Input from "./Input";
import Vehicles from "./Vehicles";
import Map from "./Map";
import InputService from "./InputService";
import List from "./List";

import { verifyData, getData, createNewService, calculateService } from "../../utils/serviceCenter";
import { urlPayment } from "../../values/api";

const listAuthority = [
  { value: "ROAD-POLICE", text: "Policía Vial" },
  { value: "STATE-ATTORNEY-OFFICE", text: "Fiscalía del Estado" },
  { value: "GUADALAJARA-MOBILITY", text: "Movilidad Guadalajara" },
  { value: "ZAPOPAN-MOBILITY", text: "Movilidad Zapopan" },
  { value: "TLAJOMULCO-MOBILITY", text: "Movilidad Tlajomulco" },
  { value: "TRANSPORTATION SECRETARIAT", text: "Secretaría de Transporte" },
  { value: "NATIONAL GUARD", text: "Guardia Nacional" },
  { value: "OTHERS", text: "Otros" },
];

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    height: 35,
    borderRadius: 0,
    border: "none",
    fontSize: 16,
    paddingLeft: 10,
    marginBottom: 10,
    appearance: "none",
    background: "#f6f6f6",
  },
  input: {
    width: "100%",
    height: 35,
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: "regular",
    border: "none",
    background: "#f6f6f6",
  },
}));

const Input = ({ title, value, setValue }) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
      <label style={{ maxWidth: 100, width: "100%" }}>{title}</label>
      <input className={classes.input} value={value} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
};

const Stack = (props) => {
  const { children } = props;
  return (
    <div style={{ display: "flex" }}>
      {Array.isArray(children)
        ? children.map((item, index) => (
            <Fragment key={`stack-${index}`}>
              <div style={{ width: "100%" }}>{item}</div>
              {index < children.length - 1 && <div style={{ width: 20 }} />}
            </Fragment>
          ))
        : children}
    </div>
  );
};

const ServiceCenter = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [vehicle, setVehicle] = useState(0);
  const [service, setService] = useState(0);
  const [data, setData] = useState([]);
  const [origen, setOrigen] = useState(null);
  const [destiny, setDestiny] = useState(null);
  const [check, setCheck] = useState(true);

  const [authority, setAuthority] = useState(listAuthority[0].value);
  const [others, setOthers] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [amountPlates, setAmountPlates] = useState("");
  const [brand, setBrand] = useState("");
  const [subBrand, setSubBrand] = useState("");
  const [colors, setColors] = useState("");
  const [model, setModel] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [appointmentNumber, setAppointmentNumber] = useState("");

  const [partnerData, setPartnerData] = useState(null);
  const [listVehicles, setListVehicles] = useState([]);
  const [listBranches, setListBranches] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [url, setUrl] = useState("");
  const [total, setTotal] = useState("");
  const [totalPartner, setTotalPartner] = useState("");

  const [newVehicle, setNewVehicle] = useState({ Plates: "", PlacePicture: "", PlaceName: "" });
  const [newBranches, setNewBranches] = useState({});

  const checkTotalService = async (partnerId) => {
    setLoading3(true);
    const serviceType = service === 0 ? "TYRE" : service === 1 ? "TYRE_MOBILE" : "TOW";
    const vehicleType = vehicle === 1 ? "MOTORCYCLE" : vehicle === 2 ? "CAR" : vehicle === 3 ? "VAN" : "TRUCK";
    let auxOrigin = {};
    let auxDestiny = {};

    if (origen) {
      auxOrigin.Lat = origen.lat;
      auxOrigin.Lng = origen.lng;
    }

    if (destiny) {
      auxDestiny.Lat = destiny.lat;
      auxDestiny.Lng = destiny.lng;
    }

    const res = await calculateService(partnerId.Id, serviceType, vehicleType, auxOrigin, auxDestiny);
    setTotal(res.data.total);
    setTotalPartner(res.data.totalPartner);
    setLoading3(false);
    setListVehicles(res.data.listVehicles);
    setListBranches(res.data.listBranches);
    // console.log('res: ', res.data.listVehicles);
  };

  const searchInfo = async () => {
    setLoading2(true);
    const res = verifyData(name, phone, vehicle, service, origen, destiny, check);
    if (res.flag) {
      setData([]);
      const lat = origen.lat;
      const lng = origen.lng;
      const latDes = destiny.lat;
      const lngDes = destiny.lng;
      const vehicleOption = vehicle === 1 ? "MOTORCYCLE" : vehicle === 2 ? "CAR" : vehicle === 3 ? "TRUCK" : "VAN";
      const serviceOption = service === 0 ? "TYRE" : service === 1 ? "TYRE_MOBILE" : "TOW";
      const extra = `?lat=${lat}&lng=${lng}&latDes=${latDes}&lngDes=${lngDes}&serviceType=${serviceOption}&vehicle=${vehicleOption}`;
      const res2 = await getData("", extra);

      setData(res2.data.data);
      if (res2) {
        setLoading2(false);
      }
    } else {
      setLoading2(false);
      toast.warn(res.message);
    }
  };

  const initService = async () => {
    setLoading(true);
    const serviceType = service === 0 ? "TYRE" : service === 1 ? "TYRE_MOBILE" : "TOW";
    const vehicleType = vehicle === 1 ? "MOTORCYCLE" : vehicle === 2 ? "CAR" : vehicle === 3 ? "VAN" : "TRUCK";
    let auxOrigin = {};
    let auxDestiny = {};
    let destinationAddress = "";
    let originAddress = "";

    if (origen) {
      auxOrigin.Lat = origen.lat;
      auxOrigin.Lng = origen.lng;
      await geocodeByLatLng({ lat: origen.lat, lng: origen.lng }).then((result) => {
        originAddress = result[0].formatted_address;
      });
    }

    if (destiny) {
      auxDestiny.Lat = destiny.lat;
      auxDestiny.Lng = destiny.lng;
      await geocodeByLatLng({ lat: destiny.lat, lng: destiny.lng }).then((result) => {
        destinationAddress = result[0].formatted_address;
      });
    }

    const res = await createNewService(
      partnerData.Id,
      name,
      phone,
      serviceType,
      vehicleType,
      auxOrigin,
      auxDestiny,
      originAddress,
      destinationAddress,
      newVehicle,
      newBranches,

      authority,
      others,
      serialNumber,
      plateNumber,
      amountPlates,
      brand,
      subBrand,
      colors,
      model,
      employeeNumber,
      appointmentNumber
    );
    if (res.res) {
      toast.success("Servicio creado");
      setLoading(false);
      setUrl(`${urlPayment}${res.id}`);
    } else {
      toast.warn("Ocurrio un error porfavor vuele intentarlo");
      setLoading(false);
    }
  };

  const handleChageService = (newValue) => {
    setService(newValue);
    setData([]);
    setNewVehicle({ Plates: "", PlacePicture: "", PlaceName: "" });
    setNewBranches({});
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Container maxWidth="xl">
        <div style={{ display: "flex", paddingTop: 10 }}>
          <p style={{ margin: 0, fontWeight: 600, width: "100%", fontSize: 16 }}>Crear servicio</p>
          <p style={{ margin: 0, fontWeight: 600, width: "100%" }}>Información del vehículo</p>
        </div>
        <Grid container>
          <Grid item sm={6}>
            <div style={{ padding: 20 }}>
              <Input title="Nombre" value={name} setValue={setName} />
              <Input title="Teléfono" value={phone} setValue={setPhone} />

              <Stack>
                <Input title="No. nombramiento" value={appointmentNumber} setValue={setAppointmentNumber} />
                <Input title="No. empleado" value={employeeNumber} setValue={setEmployeeNumber} />
              </Stack>

              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ maxWidth: 100, width: "100%" }}>Autoridad</label>
                <select className={classes.select} value={authority} onChange={(e) => setAuthority(e.target.value)}>
                  {listAuthority.map((i, j) => (
                    <option value={i.value}>{i.text}</option>
                  ))}
                </select>
              </div>
              {authority === "OTHERS" && <Input title="Otro" value={others} setValue={setOthers} />}

              <InputService value={service} setData={handleChageService} />
              <Map service={service} setOrigen={setOrigen} setDestiny={setDestiny} setCheck={setCheck} />
            </div>
          </Grid>
          <Grid item sm={6}>
            <div style={{ padding: 20 }}>
              <Stack>
                <Input title="No. de serie" value={serialNumber} setValue={setSerialNumber} />
                <Input title="No. de la placa" value={plateNumber} setValue={setPlateNumber} />
              </Stack>
              <Stack>
                <Input title="Marca" value={brand} setValue={setBrand} />
                <Input title="Sub-Marca" value={subBrand} setValue={setSubBrand} />
              </Stack>
              <Stack>
                <Input title="C. Placas" value={amountPlates} setValue={setAmountPlates} />
                <Input title="Color" value={colors} setValue={setColors} />
              </Stack>
              <Input title="Modelo" value={model} setValue={setModel} />

              <Vehicles value={vehicle} setValue={setVehicle} />
              <List
                search={searchInfo}
                service={service}
                data={data}
                setNewVehicle={setNewVehicle}
                setNewBranches={setNewBranches}
                setPartnerData={setPartnerData}
                initService={initService}
                checkTotalService={checkTotalService}
                total={total}
                totalPartner={totalPartner}
                listVehicles={listVehicles}
                listBranches={listBranches}
                loading={loading}
                loading2={loading2}
                loading3={loading3}
              />

              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontWeight: 600, marginRight: 10, display: url === "" ? "none" : "block" }}>
                  Link de pago:
                </p>
                <a href={url} target="_blank">
                  {url}
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceCenter;
