import {useState}   from 'react';
import {Container, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton} from '@material-ui/core';
import {connect}    from 'react-redux';
// Redux
import {changeView}             from '../../redux/actions/Menu';
import {getCities,updateCities} from '../../redux/actions/Cities';
// Values
import {Ic_edit, StateView, stateData} from '../../values';
// Components
import LoadingData      from '../global/LoadingData';
import Pagination       from '../global/ButtonsPagination';
// Styles
import {useStyles}      from '../../style';
// Api
import {getData}        from '../../utils/cities';

const Tables = ({data, status, token, records, changeView, getCities, updateCities}) => {
    const classes           = useStyles()
    const [page,setPage]    = useState(1)

    const edit = (item) => {
        changeView(StateView.state3)
        updateCities(item)
    }
    const GetData = async(page) =>{
        const res = await getData(token, `?Page=${page}`)
        getCities(res.data)
        window.scrollTo(0, 0)
    }
    const changePage = (e,v) =>{
        setPage(v)
        GetData(v)
    }
    return (
        <div style={{position:'relative'}}>
            {
                status===stateData.state2?
                    <Container maxWidth="xl">
                        <TableContainer style={{background:'#fff'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow style={{background:'#132327', height:50}}>
                                        {
                                            ['Ciudad', 'Estado', 'País', 'Área', 'Editar'].map((item, index) =>
                                                <TableCell align="center" key={index} className={classes.table_title2} style={{fontFamily:'bold', color:'#fff'}}> {item} </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data&&
                                        data.map((item, index) =>(
                                            <TableRow key={index} style={{borderBottom:`1px solid #f3f4f7`}}>
                                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>{item.City}</TableCell>
                                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>{item.State}</TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}><span style={{fontWeight:700}}>{item.Country}</span></TableCell>
                                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>
                                                    {
                                                        item.Area.map((i,j) =>( 
                                                            j===0&&
                                                            <span key={j}>{i.lat},{i.lng}...</span>
                                                        ))
                                                    }
                                                </TableCell>
                                                <TableCell align="center" className={classes.table_text2}>
                                                    <IconButton onClick={() => edit(item)}>
                                                        <img alt="ic_edit" src={Ic_edit} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination records={records} page={page} changePage={changePage} />
                    </Container>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Cities.cities,
    status:     state.Cities.status,
    token:      state.Panel.token,
    records:    state.Cities.records
})

export default connect(mapStateToProps, {changeView,getCities,updateCities})(Tables);
