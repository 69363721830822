import {stateData, reduxState} from '../../values';

export const getPermissions = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_permissions,
            payload:    data,
            status:     stateData.state2,
        })
    }
}
