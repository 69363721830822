import { useState }    from 'react';
import {Container}  from '@material-ui/core';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Redux
import {changeView} from '../../redux/actions/Menu';
import {reDonwloading, filterInvoiceData}  from '../../redux/actions/Invoice';
// Components
import Buttons       from '../global/Button';
// Values
import {StateView, Ic_sync}  from '../../values';
// Styles
import {useStyles}  from '../../style';

const Head = ({title='', stateView, changeView, view=true, reDonwloading, data, dataAux, status, filterInvoiceData}) => {
    const classes                   = useStyles();
    const [anchorEl, setAnchorEl]   = useState(null);
    const [textButton, setTextButton] = useState('Todos')

    const addView = () =>{
        changeView(StateView.state2)
    }
    const re_download = () =>{
        reDonwloading()
    }
    const filter = (opt) =>{
        setAnchorEl(null);

        let newOption   = 0
        let newData     = []
        if(0===opt){
            setTextButton('Todos')
            newOption = 0
            newData = dataAux
        } else{
            if(opt===1){
                setTextButton('Solicitudes')
            } else{
                setTextButton('Enviados')
            }
            newOption = opt
        }
        if(newOption!==0){
            dataAux.forEach(i=>{
                if(newOption===1){
                    if(!i.PdfUrl){
                        newData.push(i)
                    }
                } else{
                    if(i.PdfUrl){
                        newData.push(i)
                    }
                }
            })
        }
        filterInvoiceData(newData, newOption)
    }
    const back = () =>{
        changeView(StateView.state1)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div style={{width:'100%'}}>
            <Container maxWidth="xl">
                <div style={{ display:'flex', alignItems:'center', padding:'10px 0', justifyContent:'space-between', height:40, marginBottom:10}}>
                    <h2 style={{margin:0, marginRight:20, fontFamily:'bold'}}>{title}</h2>
                    {
                        view&&
                        stateView===StateView.state1?
                            <Button text={'Agregar'} onClick={addView} />
                        :
                            <div />
                    }
                    <div style={{display:'flex'}}>
                        <div className={classes.button_re_loading} onClick={re_download}>
                            <img alt="" src={Ic_sync} />
                        </div>
                        <div style={{width:10}} />

                        <Buttons text={textButton} onClick={handleClick} />

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={()=>setAnchorEl(null)}
                            >
                            <MenuItem onClick={()=> filter(0)}>{"Todos"}</MenuItem>
                            <MenuItem onClick={()=> filter(1)}>{"Solicitudes"}</MenuItem>
                            <MenuItem onClick={()=> filter(2)}>{"Enviados"}</MenuItem>
                        </Menu>
                        <div style={{width:10}} />
                        {/* <Button text={'Enviados'} onClick={()=>filter(2)} /> */}
                    </div>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Invoice.invoice,
    dataAux:        state.Invoice.invoiceAux,
    status:         state.Invoice.statusSearch
})

export default connect(mapStateToProps, {changeView, reDonwloading, filterInvoiceData})(Head);