import {routesApi, urlApi} from '../../values/api';

const FloatingMessage = () => {
    return (
        <div style={{position:'absolute', left:'50%', top:0, zIndex:99999999}}>
            {
                urlApi!==routesApi.productive?
                    <h1>SANDBOX</h1>
                :
                    <div />
            }
        </div>
    );
};

export default FloatingMessage;