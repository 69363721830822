import { Fragment, useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { formatDate3 as formatDate, formatMoney } from "../../utils";

import SelectVehicle from "./SelectVehicle";
import SelectBranches from "./SelectBranches";

const useStyles = makeStyles((theme) => ({
  container_list: {
    overflowY: "auto",
    overflowX: "hidden",
    height: 380,
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  button_service_center: {
    background: "#15398f",
    border: "none",
    borderRadius: 5,
    color: "#fff",
    height: 40,
    width: "100%",
    fontWeight: 600,
    cursor: "pointer",
  },
  flex: {
    display: "flex",
  },
  justify_between: {
    justifyContent: "space-between",
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: 5,
  },
  text: {
    margin: 0,
  },
  text_bold: {
    margin: 0,
    fontWeight: 600,
    fontSize: 18,
  },
  container_loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  select: {
    width: "100%",
    height: 35,
    borderRadius: 0,
    border: "none",
    fontSize: 16,
    paddingLeft: 10,
    marginBottom: 20,
    appearance: "none",
  },
  input: {
    width: "100%",
    height: 35,
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: "regular",
    border: "none",
    background: "#e9e9ed",
  },
}));

const List = ({
  service,
  search,
  data,
  setNewVehicle,
  setNewBranches,
  setPartnerData,
  initService,
  checkTotalService,
  total,
  totalPartner,
  listVehicles,
  listBranches,
  loading,
  loading2,
  loading3,
}) => {
  const classes = useStyles();
  const [auxId, setAuxId] = useState(null);

  const assignWorker = (value) => {
    setPartnerData(value);
    setAuxId(value.Id);
    checkTotalService(value);
  };

  const createService = (value) => {
    if (value.Status !== "ACCEPTED") {
      initService();
    } else {
      toast.warn("El socio ya esta en servicio");
    }
  };

  const checkColor = (status) => {
    //#2fd25a -  #f4d12b - #15398f
    if (status === "ACCEPTED") {
      return "#15398f";
    }
    if (status === "OFFLINE") {
      return "#f4d12b";
    }
    if (status === "CONNECTED") {
      return "#2fd25a";
    }
  };

  useEffect(() => {
    setAuxId(null);
  }, [service]);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15, marginBottom: 10 }}>
        <button
          onClick={search}
          className={classes.button_service_center}
          style={{ position: "relative", background: "#2fd25a" }}
        >
          {loading2 ? (
            <div className={classes.container_loading}>
              <ReactLoading type={"bars"} color={"white"} style={{ fill: "#fff", height: 40, width: 40 }} />
            </div>
          ) : (
            "BUSCAR"
          )}
        </button>
      </div>
      <div className={classes.container_list}>
        {data &&
          data.map((i, j) => (
            <div key={j} style={{ background: auxId === i.Id ? "#dde8fd" : "#f7f8fa", padding: 10, marginBottom: 2 }}>
              <div onClick={() => assignWorker(i)} style={{ cursor: "pointer" }}>
                <div className={`${classes.flex} ${classes.justify_between}`}>
                  <div className={classes.flex}>
                    <img className={classes.image} alt="" src={i.PictureProfile} />
                    <div style={{ padding: 10 }}>
                      <p className={classes.text_bold}>
                        {i.Name} {i.Surname}
                      </p>
                      <p className={classes.text}>{i.Email}</p>
                      <p className={classes.text}>{i.Phone}</p>
                    </div>
                  </div>
                  <div style={{ textAlign: "end", position: "relative", display: auxId === i.Id ? "block" : "none" }}>
                    {loading3 ? (
                      <div style={{ position: "absolute", top: "55%", right: 10, transform: "translate(-50%, -50%)" }}>
                        <ReactLoading type={"spinningBubbles"} style={{ fill: "#000", height: 40, width: 40 }} />
                      </div>
                    ) : (
                      <Fragment>
                        <p className={classes.text}>Costo del servicio</p>
                        <p className={classes.text_bold}>{`$ ${formatMoney(999)} MXN`}</p>
                        {/* <p className={classes.text}>{"Ganancia Socio"}</p>
                        <p className={classes.text_bold}>{`$ ${formatMoney(totalPartner)} MXN`}</p>
                        <p className={classes.text}>{"Total usuario"}</p>
                        <p className={classes.text_bold}>{`$ ${formatMoney(total)} MXN`}</p> */}
                      </Fragment>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: 3 }}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                      marginRight: 5,
                      background: checkColor(i.Status),
                    }}
                  />
                  <p className={classes.text}>{formatDate(i.PositionDateTime)}</p>
                </div>
              </div>

              {auxId === i.Id && (
                <Fragment>
                  {service !== 0 ? (
                    <SelectVehicle data={i} listVehicles={listVehicles} setNewVehicle={setNewVehicle} />
                  ) : (
                    <SelectBranches data={i} list={listBranches} setNewBranches={setNewBranches} />
                  )}

                  <button
                    onClick={() => createService(i)}
                    className={classes.button_service_center}
                    style={{ marginTop: 10, position: "relative" }}
                  >
                    {loading ? (
                      <div className={classes.container_loading}>
                        <ReactLoading type={"bars"} color={"white"} style={{ fill: "#fff", height: 40, width: 40 }} />
                      </div>
                    ) : (
                      "ASIGNAR"
                    )}
                  </button>
                </Fragment>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default List;
