import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.invoice

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, version, system, action, code, release) =>{
    const obj = {
        Action:     action,
        Code:       code,
        Release:    release,
        System:     system,
        Version:    version
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, legalName, invoiceUse, service, total, rfc, email, pdfUrl, xmlUrl, idService) =>{
    const obj = {
        LegalName:      legalName,
        RFC:            rfc,
        InvoiceUse:     invoiceUse,
        Email:          email,
        Service:        service,
        TotalService:   total,
        PdfUrl:         pdfUrl,
        XmlUrl:         xmlUrl,
        IdService:      idService
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const updateValidate = (legalName, invoiceUse, service, total, rfc, email, pdfUrl, xmlUrl) =>{
    if(!legalName){
        return {flag:false, message:'Parece que algo anda mal con el nombre.'}
    }
    if(!invoiceUse){
        return {flag:false, message:'Parece que algo anda mal con el uso.'}
    }
    if(!service){
        return {flag:false, message:'Parece que algo anda mal con el servicio.'}
    }
    if(!total){
        return {flag:false, message:'Parece que algo anda mal con el total.'}
    }
    if(!rfc){
        return {flag:false, message:'Parece que algo anda mal con el rfc.'}
    }
    if(!email){
        return {flag:false, message:'Parece que algo anda mal con el email.'}
    }
    if(!pdfUrl){
        return {flag:false, message:'Parece que algo anda mal con el pdf.'}
    }
    if(!xmlUrl){
        return {flag:false, message:'Parece que algo anda mal con el xml.'}
    }

    return {flag:true, message:''}
}