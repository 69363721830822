import {stateData, reduxState} from '../../values';

const initialState = {
    admins:         [],
    status:         stateData.state1,
    adminsEdit:     [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_admin){
        return {
            ...state,
            admins:     action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_admin){
        return {
            ...state,
            adminsEdit: action.payload
        }
    }
    return state
}
export default reducer