import GoogleMapReact from 'google-map-react';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"

const Marker = ({color}) => 
<div>
    <div style={{width:30, height:30, background:color, borderRadius:50}} />
</div>;

const Map = ({center = {lat:20.662926, lng:-103.364936}, zoom = 11, origen, destination, partner, user}) => {
    return (
        <div style={{width:'100%', height:430, paddingBottom:70}}>
            <GoogleMapReact
                    // options= {MAP_OPTIONS}
                    bootstrapURLKeys = {{ key:keyMap}}
                    tracksViewChanges={false}
                    defaultCenter = { center } 
                    defaultZoom  = { zoom }>
                        <Marker
                            color={"#44d7b6"}
                            lat={ origen.Lat } 
                            lng={ origen.Lng }/>
                        {
                            destination.length!==0&&
                            <Marker
                                color={"#005cbb"}
                                lat={ destination.Lat } 
                                lng={ destination.Lng }/>
                        }
                        <Marker
                            color={"#b5015a"}
                            lat={ partner.Lat } 
                            lng={ partner.Lng }/>

                        {/* <Marker
                            color={"#f7b500"}
                            lat={ user.Lat } 
                            lng={ user.Lng }/> */}
                </GoogleMapReact>
            <div style={{background:'#a0bdca', display:'flex', justifyContent:'space-around', height:30, borderBottomLeftRadius:5, borderBottomRightRadius:5}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:15, height:15, background:'#44d7b6', borderRadius:50, marginRight:5}} />
                    <label style={{color:'#fff'}}>Origen</label>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:15, height:15, background:'#005cbb', borderRadius:50, marginRight:5}} />
                    <label style={{color:'#fff'}}>Destino</label>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:15, height:15, background:'#b5015a', borderRadius:50, marginRight:5}} />
                    <label style={{color:'#fff'}}>Socio</label>
                </div>
                {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:15, height:15, background:'#f7b500', borderRadius:50, marginRight:5}} />
                    <label style={{color:'#fff'}}>Usuario</label>
                </div> */}
            </div>
        </div>
    );
};

export default Map;