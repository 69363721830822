import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import ReactLoading from "react-loading";

import { textPanel, Img_logo, Logo, Ic_ok, Ic_error, Background, Ic_next, toastText } from "../../values";
import { emailformat } from "../../utils";
import { VerifyLogin } from "../../utils/login";
import { saveToken } from "../../redux/actions/PanelAdmin";

// Api
import { sendToEmailToResetPassword } from "../../utils/resetPassword";

const Login = ({ saveToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [status1, setStatus1] = useState(0); //0 nada, 1 verde y imagen, 2 rojo e imagen, 3 bien verde e imagen
  const [status2, setStatus2] = useState(0);

  const standBy = (event) => {
    event.preventDefault();
    console.log("stand-by");
  };
  const validateData = (value, opt) => {
    if (opt === 1) {
      setEmail(value);
      if (value.match(emailformat)) {
        setStatus1(3);
      } else {
        setStatus1(2);
      }
    } else {
      setPassword(value);
      if (value.length >= 8) {
        setStatus2(3);
      } else {
        setStatus2(2);
      }
    }
  };
  const onChangeStatus = (opt) => {
    if (opt === 1) {
      if (status1 === 0) {
        setStatus1(1);
      }
    } else {
      if (status2 === 0) {
        setStatus2(1);
      }
    }
  };

  const login = async () => {
    const res = await VerifyLogin(email, password);
    // console.log("RES: ", res);

    if (res.flag) {
      localStorage.setItem("llaoz-key", res.token);
      localStorage.setItem("llaoz-name", res.data.Name);
      localStorage.setItem("llaoz-lastname", res.data.Surname);
      localStorage.setItem("llaoz-image", res.data.Image);
      localStorage.setItem("llaoz-roles", JSON.stringify(res.data.Roles));
      saveToken(res.token, res.data.Name, res.data.Surname, res.data.Image);
      setLoading(false);
    } else {
      toast.warn(toastText.failLogin);
      setLoading(false);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (status1 === 3 && status2 === 3) {
      setLoading(true);
      login();
    }
    if (status1 !== 3) {
      setStatus1(2);
    }
    if (status2 !== 3) {
      setStatus2(2);
    }
  };

  const restorePassword = async () => {
    if (email.match(emailformat)) {
      const res = await sendToEmailToResetPassword(email);
      if (res) {
        toast.success(`Se envío a ${email} un correo para restablecer la contraseña.`);
      } else {
        toast.warn("Parece que surgio un problema, revisa tus datos.");
      }
    } else {
      toast.warn("Introduce un correo valido");
    }
  };
  return (
    <div style={{ display: "flex", backgroundColor: "#f5f6fa" }}>
      <div className="container-image-login">
        <img alt="" src={Background} style={{ position: "absolute", left: 0, right: 0, height: "100vh", zIndex: 1 }} />
      </div>
      <div className="container-login" style={{ zIndex: 1000 }}>
        <div className="card-login">
          <div style={{ width: "calc(100% - 50px)" }}>
            <div style={{ textAlign: "center" }}>
              <img alt="" src={Logo} className="Jalisco Gobierno Del Estado" style={{ width: "70%" }} />
            </div>
            <form onSubmit={loading ? standBy : onSubmit}>
              <div className="container-input" onClick={() => onChangeStatus(1)} onFocus={() => onChangeStatus(1)}>
                <input
                  type="text"
                  placeholder={textPanel.email}
                  style={{ fontFamily: "regular" }}
                  className={`input-email-login ${
                    status1 === 1 || status1 === 3
                      ? "border-status2"
                      : status1 === 0
                      ? "border-status1"
                      : "border-status3"
                  }`}
                  onChange={(e) => validateData(e.target.value, 1)}
                />
                <img
                  alt=""
                  src={status1 === 1 || status1 === 3 ? Ic_ok : status1 === 2 ? Ic_error : ""}
                  className="img-ok-login"
                  style={{ display: status1 === 0 ? "none" : "block" }}
                />
              </div>

              <div className="container-input" onClick={() => onChangeStatus(2)} onFocus={() => onChangeStatus(2)}>
                <input
                  type="password"
                  placeholder={textPanel.password}
                  style={{ fontFamily: "regular" }}
                  className={`input-email-login ${
                    status2 === 1 || status2 === 3
                      ? "border-status2"
                      : status2 === 0
                      ? "border-status1"
                      : "border-status3"
                  }`}
                  onChange={(e) => validateData(e.target.value, 2)}
                />
                <img
                  alt=""
                  src={status2 === 1 || status2 === 3 ? Ic_ok : status2 === 2 ? Ic_error : ""}
                  className="img-ok-login"
                  style={{ display: status2 === 0 ? "none" : "block" }}
                />
              </div>
              <div style={{ textAlign: "right", marginTop: 20 }}>
                <p
                  onClick={restorePassword}
                  style={{
                    textDecoration: "none",
                    color: "#0047CC",
                    fontWeight: 600,
                    fontSize: 15,
                    cursor: "pointer",
                    fontFamily: "bold",
                  }}
                >
                  {textPanel.recover}
                </p>
                <br />
                <button className="button-login" style={{ fontFamily: "bold" }}>
                  {textPanel.enter}
                  {loading ? (
                    <div style={{ position: "absolute", top: 10, right: 10 }}>
                      <ReactLoading type={"bars"} color={"white"} style={{ fill: "white", height: 35, width: 35 }} />
                    </div>
                  ) : (
                    <img alt="" src={Ic_next} style={{ position: "absolute", right: 15, top: 20 }} />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { saveToken })(Login);
