import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
import { useHistory }   from "react-router-dom";
// Redux
import {changeView} from '../../redux/actions/Menu';
import {updateCompanies}    from '../../redux/actions/Companies';
// Components
import DragImage    from '../global/DragImage';
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
import ButtonsSeeMore   from './ButtonsSeeMore';
// Values
import {StateView, bussinesSelect, toastText} from '../../values';
// Styles
import {useStyles} from '../../style';
// Api
import {validateUpdate, validatePost, updateData, postData, deleteData}   from '../../utils/companies';
import {deployImage}    from '../../utils';

const Form = ({stateView, data, token, cities, changeView, updateCompanies}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    const classes                   = useStyles()
    const history                   = useHistory();

    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [picture, setPicture]     = useState(data.Picture?data.Picture:'')
    const [document, setDocument]   = useState(data.Document?data.Document:'')
    const [name, setName]           = useState(data.Name?data.Name:'')
    const [bussines, setBussines]   = useState(data.Bussines?data.Bussines:'TOW')
    const [rfc, setRfc]             = useState(data.RFC?data.RFC:'')
    const [clabe, setClabe]         = useState(data.CLABE?data.CLABE:'')
    const [addres, setAddres]       = useState(data.Addres?data.Addres:'')
    const [city, setCity]           = useState(data.City?data.City:'')
    const [cityId, setCityId]       = useState(data.CityId?data.CityId:'')

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateCompanies([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setblockData('')
        setEdit(value)
        if(value===2){
            const validate = validateUpdate(name, rfc, clabe, addres, city)
            if(validate.flag){
                let UrlImage1 = picture
                let UrlImage2 = document
                if(typeof picture==='object'){
                    UrlImage1 = await deployImage(picture, "Companies", "picture")
                    setPicture(UrlImage1)
                }
                if(typeof document==='object'){
                    UrlImage2 = await deployImage(document, "Companies", "document")
                    setDocument(UrlImage2)
                }
                const res = await updateData(data.Id, token, name, bussines, rfc, clabe, addres, city, cityId, UrlImage1, UrlImage2)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateCompanies([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = validatePost(name, rfc, clabe, addres, city, picture, document)
            if(validate.flag){
                let UrlImage1 = picture
                let UrlImage2 = document
                if(typeof picture==='object'){
                    UrlImage1 = await deployImage(picture, "Companies", "picture")
                    setPicture(UrlImage1)
                }
                if(typeof document==='object'){
                    UrlImage2 = await deployImage(document, "Companies", "document")
                    setDocument(UrlImage2)
                }
                const res = await postData(token, name, bussines, rfc, clabe, addres, city, cityId, UrlImage1, UrlImage2)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateCompanies([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    const changePageBranches = () => {
        changeView(StateView.state1)
        updateCompanies([])
        history.push(`/branches/${data.Id}`)
    }
    const changePagePartner = () =>{
        changeView(StateView.state1)
        updateCompanies([])
        history.push(`/partners/${data.Id}`)
    }
    const changePageVehicles = () =>{
        changeView(StateView.state1)
        updateCompanies([])
        history.push(`/vehicles/${data.Id}`)
    }
    const setAnNewCity = (e) =>{
        const index = e.nativeEvent.target.selectedIndex
        const text = e.nativeEvent.target[index].text
        setCityId(e.target.value)
        setCity(text)
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <DragImage image={picture} setImage={setPicture} height={300} text={"FACHADA"} option={1} blockData={blockData} />
                            <div style={{height:20}} />
                            <DragImage image={document} setImage={setDocument} height={300} text={"COMPROBANTE"} option={2} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <div style={{display:data.Id?'block':'none'}}>
                                <Input title={'Id'} value={data.Id} blockData={'disabled'} />
                            </div>
                            <Input title={'Nombre'} value={name} setData={setName} blockData={blockData} />
                            <div style={{display:'flex', alignContent:'center', width:'100%'}}>
                                <p className={classes.title_input}>{'Giro'}</p>
                                <select style={{cursor:blockData==='disabled'?'':'pointer'}}
                                    defaultValue={bussines} 
                                    className={classes.select} 
                                    onChange={e => setBussines(e.target.value)}
                                    disabled={blockData}>
                                        {
                                            bussinesSelect.map((item, index) =>(
                                                <option key={index} value={item.Value}>{item.Info}</option>
                                            ))
                                        }
                                </select>
                            </div>
                            <Input title={'Rfc'} value={rfc} setData={setRfc} blockData={blockData} />
                            <Input title={'CLABE'} value={clabe} setData={setClabe} blockData={blockData} />
                            <Input title={'Dirección'} value={addres} setData={setAddres} blockData={blockData} />
                            {/* <Input title={'Ciudad'} value={city} setData={setCity} blockData={blockData} /> */}
                            <div style={{display:'flex', alignContent:'center', width:'100%'}}>
                                <p className={classes.title_input}>{'Ciudad'}</p>
                                <select style={{cursor:blockData==='disabled'?'':'pointer'}}
                                    defaultValue={cityId} 
                                    className={classes.select} 
                                    onChange={e => setAnNewCity(e)}
                                    disabled={blockData}>
                                        <option value="" disabled hidden>Selecciona la ciudad</option>
                                        {
                                            cities.map((item, index) =>(
                                                <option key={index} value={item.Id}>{item.City}</option>
                                            ))
                                        }
                                </select>
                            </div>
                            <div style={{display:data.Id?'block':'none'}}>
                                <ButtonsSeeMore bussines={bussines} branches={changePageBranches} partners={changePagePartner} vehicles={changePageVehicles} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Companies.companiesEdit,
    token:          state.Panel.token,
    cities:         state.Cities.cities
})

export default connect(mapStateToProps, {changeView, updateCompanies})(Form);
