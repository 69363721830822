import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateBranches} from '../../redux/actions/Branches';
// Components
import DragImage        from '../global/DragImage';
import Input            from '../global/Input';
import ButtonsForm      from '../global/ButtonsForm';
import Availability     from '../global/ButtonsAvailability';
import MultipleInput    from '../global/MultipleInput';
// Values
import {StateView, toastText} from '../../values';
// Api
import {updateData, postData, deleteData, postValidate, updateValidate}   from '../../utils/branches';
import {getACompany}    from '../../utils/companies';
import {deployImage}    from '../../utils';


const Form = ({stateView, data, changeView, updateBranches, token}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")

    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)
    const [loadingSearch, setLoadingSearch] = useState(false)

    // Data -------------------------------------------------------------------------
    const [name, setName]                   = useState(data.Name?data.Name:'')
    const [address, setAddress]             = useState(data.Address?data.Address:'')
    const [lat, setLat]                     = useState(data.Position?data.Position.Lat:'')
    const [lng, setLng]                     = useState(data.Position?data.Position.Lng:'')
    const [postalCode, setPostalCode]       = useState(data.PostalCode?data.PostalCode:'')
    const [city, setCity]                   = useState(data.City?data.City:'')
    const [company, setCompany]             = useState(data.Company?data.Company:'')
    const [companyId, setCompanyId]         = useState(data.CompanyId?data.CompanyId:'')
    const [availability, setAvailability]   = useState(data.Availability?data.Availability:{CAR:false, MOTORCYCLE:false, TRUCK:false, VAN:false})
    const [picture, setPicture]             = useState(data.Picture?data.Picture:'')

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateBranches([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
        setblockData('')
        if(value===2){
            const validate = updateValidate(name, address, lat, lng, postalCode, city, company, companyId)
            if(validate.flag){
                let UrlImage = picture
                if(typeof picture==='object'){
                    UrlImage = await deployImage(picture, "Branches", "branches")
                    setPicture(UrlImage)
                }
                const res = await updateData(data.Id, token, name, address, lat, lng, postalCode, city, company, companyId, availability, UrlImage)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateBranches([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = postValidate(name, address, lat, lng, postalCode, city, company, companyId, picture)
            if(validate.flag){
                let UrlImage = picture
                if(typeof picture==='object'){
                    UrlImage = await deployImage(picture, "Partners", "picture")
                    setPicture(UrlImage)
                }
                const res = await postData(token, name, address, lat, lng, postalCode, city, company, companyId, availability, UrlImage)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateBranches([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    const changeValue = (opt) =>{
        const value = {}
        if(blockData!=='disabled'){
            if(opt===1){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = !availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = availability.VAN
            }
            if(opt===2){
                value.CAR           = !availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = availability.VAN
            }
            if(opt===3){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = availability.TRUCK
                value.VAN           = !availability.VAN
            }
            if(opt===4){
                value.CAR           = availability.CAR
                value.MOTORCYCLE    = availability.MOTORCYCLE
                value.TRUCK         = !availability.TRUCK
                value.VAN           = availability.VAN
            }
            setAvailability(value)
        }
    }

    const getNameCompany = async() =>{
        setLoadingSearch(true)
        const res = await getACompany(token, `/${companyId}`)
        if(res.data.Name){
            setCompany(res.data.Name)
            setLoadingSearch(false)
        } else{
            setCompany("ERROR")
            setLoadingSearch(false)
        }
    }

    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <DragImage image={picture} setImage={setPicture} height={300} text={"FOTO"} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <div style={{display:data.Id?'block':'none'}}>
                                <Input title={'Id'} value={data.Id} blockData={'disabled'} />
                            </div>
                            <Input title={'Nombre'} value={name} setData={setName} blockData={blockData} />
                            <Input title={'Dirección'} value={address} setData={setAddress} blockData={blockData} />
                            <Input title={'Lat'} value={lat} setData={setLat} type={'number'} blockData={blockData} />
                            <Input title={'Lng'} value={lng} setData={setLng} type={'number'} min={-180} blockData={blockData} />
                            <Input title={'Cp'} value={postalCode} setData={setPostalCode} blockData={blockData} />
                            <Input title={'Ciudad'} value={city} setData={setCity} blockData={blockData} />

                            <MultipleInput title={'Empresa'} 
                                search={getNameCompany}
                                value1={company} setValue1={setCompany}
                                value2={companyId} setValue2={setCompanyId} 
                                loading={loadingSearch}
                                blockData={blockData} />

                            <Availability value={availability} setValue={changeValue} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Branches.branchesEdit,
    token:          state.Panel.token,
})

export default connect(mapStateToProps, {changeView, updateBranches})(Form);
