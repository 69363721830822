import firebase from "../firebase";
import { toast } from "react-toastify";
import store from "../redux";

import { refreshToken as refresh } from "../redux/actions/PanelAdmin";

export const routesApi = {
  local_sandbox: "http://localhost:5001/llaoz-sandbox/us-central1/llaoz/",
  local_http: "http://192.168.0.120:5000/llaoz-sandbox/us-central1/llaoz/",
  sandbox: "https://us-central1-llaoz-sandbox.cloudfunctions.net/llaoz/",
  local_productive: "http://localhost:5001/llaoz-c120d/us-central1/llaoz/",
  productive: "https://us-central1-llaoz-c120d.cloudfunctions.net/llaoz/",
};

export const paymentsRoutes = {
  sandbox: "https://llaoz-payments.web.app/",
  productive: "https://llaoz-pagos.web.app/",
};

export const urlPayment = paymentsRoutes.productive;
export const urlApi = routesApi.productive;

export const urlPath = {
  admins: "admins",
  validateAdmins: "validate-admin/",
  cities: "cities",
  users: "users",
  fares: "fares",
  companies: "companies",
  partners: "partners",
  partner: "partner",
  branches: "branches",
  vehicles: "vehicles",
  apps: "app",
  contact: "contact",
  reports: "reports",
  notifications: "notifications",
  service: "service",
  history: "history",
  invoice: "invoice",
  faqs: "faqs",
  channels: "channels",
  permissions: "permissions",
  getNearestPartner: "getNearestPartner",
  createService: "createService",
  calculateService: "calculateService",
  changeStatusService: "changeStatusService",
};

const refreshToken = async () => {
  console.log("REFRESCANDO TOKEN");
  await firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (token) {
      localStorage.setItem("llaoz-key", token);
      store.dispatch(refresh(token));
    });
};

export const get = async (url, token, extra = "") => {
  let data = [];
  let flag = false;
  // console.log("URL: ", url+extra);
  const request = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await fetch(url + extra, request)
    .then(async (res) => {
      if (res.status === 200) {
        flag = true;
        console.log(res.status);
        return res.json();
      } else {
        console.log(res);
        if (res.status === 401) {
          await refreshToken();
        }
      }
    })
    .then((response) => {
      if (flag === true) {
        data = response;
      }
    })
    .catch(() => {
      flag = false;
    });
  return { data: data, flag: flag };
};

export const update = async (obj, url, id, token) => {
  const request = {
    method: "PATCH",
    body: JSON.stringify(obj),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url + "/" + id, request)
    .then(async (res) => {
      if (res.status === 200) {
        console.log(res.status);
        return true;
      } else {
        console.log(res);
        if (res.status === 401) {
          await refreshToken();
          console.log("Porfavor intentalo de nuevo");
          toast.warn("Porfavor intentalo de nuevo");
        }
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
  return res;
};

export const post = async (obj, url, token) => {
  const request = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url, request)
    .then(async (res) => {
      if (res.status === 200) {
        console.log(res.status);
        return true;
      } else {
        console.log(res);
        if (res.status === 401) {
          await refreshToken();
          console.log("Porfavor intentalo de nuevo");
          toast.warn("Porfavor intentalo de nuevo");
        }
        return false;
      }
    })
    .catch(() => {
      return false;
    });
  return res;
};

export const post2 = async (obj, url, token) => {
  let response = {
    res: false,
    id: "",
  };
  const request = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  await fetch(url, request)
    .then(async (res) => {
      if (res.status === 200) {
        console.log(res.status);
        response.res = true;
        return res.json();
      } else {
        console.log(res);
        if (res.status === 401) {
          await refreshToken();
          console.log("Porfavor intentalo de nuevo");
          toast.warn("Porfavor intentalo de nuevo");
        }
        response.res = false;
      }
    })
    .then((res) => {
      if (response.res === true) {
        response.id = res.ServiceId ? res.ServiceId : "";
        response.data = res.data ? res.data : {};
      }
    })
    .catch(() => {
      response.res = false;
    });
  return response;
};

export const deleteRegister = async (url, id, token) => {
  const request = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const res = await fetch(url + "/" + id, request)
    .then(async (res) => {
      if (res.status === 200) {
        console.log(res.status);
        return true;
      } else {
        console.log(res.status);
        if (res.status === 401) {
          await refreshToken();
          console.log("Porfavor intentalo de nuevo");
          toast.warn("Porfavor intentalo de nuevo");
        }
        if (res.status === 409) {
          console.log(
            "No se puede eliminar una empresa si estan vinculados a socios / sucursales / vehiculos, editalos o eliminalos antes, con el fin de eliminar una empresa."
          );
          alert(
            "No se puede eliminar una empresa si estan vinculados a socios / sucursales / vehiculos, editalos o eliminalos antes, con el fin de eliminar una empresa."
          );
          // toast.warn("No se puede eliminar una empresa si esta asociado a socios/sucursales/vehiculos")
        }
        return false;
      }
    })
    .catch(() => {
      return false;
    });
  return res;
};

export const restorePassword = (email) => {
  if (window.confirm(`¿Quieres enviar un correo, para restablecer la contraseña de la cuenta ${email}?`)) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(`Se envío a ${email} un correo para restablecer la contraseña`);
      })
      .catch(() => {
        toast.warn("Sucedio un error intentalo más tarde.");
      });
  }
};
