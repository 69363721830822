import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';

import { getPartners } from '../../redux/actions/Partners';

import Head from '../global/Head';
import Tables from './Tables';
import Map from './Map';

// Values
import { StateView } from '../../values';
// Api
import { getData } from '../../utils/partners';

const Zones = ({ stateView, token, getPartners }) => {
    const GetData = async () => {
        const res = await getData(token)
        getPartners(res.data)
    }
    useEffect(() => {
        if (token) {
            if (stateView === StateView.state1) {
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Socios' view={false} />
            <Grid container>
                <Grid item xs={6}>
                    <Tables />
                </Grid>
                <Grid item xs={6}>
                    {
                        stateView === StateView.state1 ? null : <Map />
                    }
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    stateView: state.View.stateView,
    token: state.Panel.token
})

export default connect(mapStateToProps, { getPartners })(Zones);
