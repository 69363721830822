import {Img_tyre, Img_mobile_tyre, Img_tow, arrow} from '../../values';

import {useStyles}  from '../../style';
const InputService = ({value, setData}) => {
    const classes = useStyles()
    const changeService = () =>{
        if(value===0){
            setData(1)
        }
        if(value===1){
            setData(2)
        }
        if(value===2){
            setData(0)
        }
    }
    return (
        <div style={{display:'flex', justifyContent:'space-between', paddingTop:15, paddingBottom:15}}>
            <p className={classes.title_input2}>{'Servicio'}</p>
            <div onClick={changeService} style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                <img alt='' src={value===0?Img_tyre:value===1?Img_mobile_tyre:Img_tow} style={{width:'100%'}} />
                {/* <img src={arrow} style={{padding:'10px 0 10px 10px'}} /> */}
            </div>
        </div>
    );
};

export default InputService;