import ReactLoading from "react-loading";

// Styles
import {useStyles}  from '../../style';
import {Ic_search} from '../../values';

const MultipleInput = ({title, value1, value2, setValue1, setValue2, loading=false, blockData, search}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{title}</p>
            <div style={{width:'49%', position:'relative'}}>
                <input type={'text'}
                    placeholder='Nombre'
                    onChange={e => setValue1(e.target.value)}
                    value={value1} 
                    className={classes.input}
                    disabled={'diabled'} />
                <div style={{position:'absolute', top:0, right:10, display:loading?'block':'none'}}>
                    <ReactLoading type={"bars"} color={"white"} style={{fill:'#132327', height:35, width:35}} />
                </div>
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%',position:'relative'}}>
                <img alt="" src={Ic_search} style={{position:'absolute', right:8, top:8, cursor:'pointer'}} onClick={search} />
                <input type={'text'}
                    placeholder='Id'
                    onChange={e => setValue2(e.target.value)}
                    defaultValue={value2} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
        </div>
    );
};

export default MultipleInput;