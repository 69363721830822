import firebase from '../../firebase';

export const resetPassword = async(code, newPassword) =>{
    let flag = false

    await firebase.auth().confirmPasswordReset(code, newPassword)
    .then(function(succes) {
        flag = true
    })
    .catch(function(error) {
        flag = false
    })

    return flag
}

export const sendToEmailToResetPassword = async(email) =>{
    let flag = false
    await firebase.auth().sendPasswordResetEmail(email)
    .then(() =>{
        flag = true
    })
    .catch(() =>{
        flag = false
    })

    return flag
}