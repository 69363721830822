import {stateData, reduxState} from '../../values';

const initialState = {
    partners:       [],
    status:         stateData.state1,
    partnersEdit:   [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_partners){
        return {
            ...state,
            partners:   action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_partners){
        return {
            ...state,
            partnersEdit: action.payload
        }
    }
    return state
}
export default reducer