import Button       from '../global/Button';
import {useStyles}  from '../../style';

const ButtonsSeeMore = ({bussines, branches, partners, vehicles}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input} style={{marginTop:18}}>{"Ver"}</p>
            <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap', width:'100%'}}>
                <div style={{marginTop:10}}>
                    <Button text={'Socios'} 
                        border={0} onClick={partners} />
                </div>
                <div style={{marginTop:10, display:bussines==='TOW'?'none':'block'}}>
                    <Button text={'Sucursales'} 
                        border={0} onClick={branches} />
                </div>
                <div style={{marginTop:10}}>
                    <Button text={'Vehículos'} 
                        border={0} onClick={vehicles} />
                </div>
            </div>
        </div>
    );
};

export default ButtonsSeeMore;