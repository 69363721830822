import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.fares

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, service, vehicle, kmMin, kmMax, amount, extra, llaozFixed, llaozPercent, checkOutFixed, checkOutPercent, range) =>{
    const obj = {
        Service:            service,
        Vehicle:            vehicle,
        MinKm:              parseFloat(kmMin),
        MaxKm:              parseFloat(kmMax),
        Amount:             parseFloat(amount),
        ExtraKm:            parseFloat(extra),
        LlaozFixed:         parseFloat(llaozFixed),
        LlaozPercent:       parseFloat(llaozPercent),
        CheckOutFixed:      parseFloat(checkOutFixed),
        CheckOutPercent:    parseFloat(checkOutPercent),
        Range:              parseFloat(range)
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, service, vehicle, kmMin, kmMax, amount, extra, llaozFixed, llaozPercent, checkOutFixed, checkOutPercent, range) =>{
    const obj = {
        Service:            service,
        Vehicle:            vehicle,
        MinKm:              parseFloat(kmMin),
        MaxKm:              parseFloat(kmMax),
        Amount:             parseFloat(amount),
        ExtraKm:            parseFloat(extra),
        LlaozFixed:         parseFloat(llaozFixed),
        LlaozPercent:       parseFloat(llaozPercent),
        CheckOutFixed:      parseFloat(checkOutFixed),
        CheckOutPercent:    parseFloat(checkOutPercent),
        Range:              parseFloat(range)
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}


export const validateData = (kmMin, kmMax, amount, extra, llaozFixed, llaozPercent, checkOutFixed, checkOutPercent, range) =>{
    if(!kmMin){
        if(kmMin!==0){
            return {flag:false, message:'Parece que algo anda mal con los kilometros minimos.'}
        }
    }
    if(!kmMax){
        if(kmMax===0){
            return {flag:false, message:'Parece que algo anda mal con los kilometros maximos.'}
        }
    }
    if(!amount){
        if(amount!==0){
            return {flag:false, message:'Parece que algo anda mal con los costos.'}
        }
    }
    if(!extra){
        if(extra!==0){
            return {flag:false, message:'Parece que algo anda mal con el extra.'}
        }
    }
    if(!llaozFixed){
        if(llaozFixed!==0){
            return {flag:false, message:'Parece que algo anda mal con el la comisión de llaoz1.'}
        }
    }
    if(!llaozPercent){
        if(llaozPercent!==0){
            return {flag:false, message:'Parece que algo anda mal con el la comisión de llaoz2.'}
        }
    }
    if(!checkOutFixed){
        if(checkOutFixed!==0){
            return {flag:false, message:'Parece que algo anda mal con el la comisión de la pasarela1.'}
        }
    }
    if(!checkOutPercent){
        if(checkOutPercent!==0){
            return {flag:false, message:'Parece que algo anda mal con el la comisión de la pasarela2.'}
        }
    }
    if(!range){
        if(range!==0){
            return {flag:false, message:'Parece que algo anda mal con el limite de km.'}
        }
    }

    return {flag:true, message:''}
}