import { urlApi, urlPath, get, post, update, deleteRegister } from "../../values/api";
const url = urlApi + urlPath.users;

export const getData = async (token, extra = "") => {
  return await get(url, token, extra);
};

export const updateData = async (id, token, name, surname, phone, image) => {
  const obj = {
    Name: name,
    Surname: surname,
    Phone: phone,
    Picture: image,
  };
  return await update(obj, url, id, token);
};

export const deleteData = async (id, token) => {
  return deleteRegister(url, id, token);
};

export const validateData = (name, surname, phone) => {
  if (!name) {
    return { flag: false, message: "Parece que algo anda mal con el nombre." };
  }
  if (!surname) {
    return { flag: false, message: "Parece que algo anda mal con el apellido." };
  }
  if (!phone) {
    return { flag: false, message: "Parece que algo anda mal con el teléfono." };
  }
  return { flag: true, message: "" };
};
