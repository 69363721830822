import Pagination from '@material-ui/lab/Pagination';

let valueRecords = 30

const ButtonsPagination = ({ records, page, changePage }) => {
    // console.log("V: ", Math.ceil(records/valueRecords));
    return (
        <div style={{ height: 80, display: Math.ceil(records / valueRecords) <= 1 ? 'none' : 'block' }}>
            <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                <Pagination count={Math.ceil(records / valueRecords)} page={page} variant="outlined" color="primary" shape="rounded" onChange={changePage} />
            </div>
        </div>
    );
};

export default ButtonsPagination;