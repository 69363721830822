import {stateData, reduxState} from '../../values';

const initialState = {
    companies:      [],
    status:         stateData.state1,
    companiesEdit:  [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_companies){
        return {
            ...state,
            companies:  action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_companies){
        return {
            ...state,
            companiesEdit: action.payload
        }
    }
    return state
}
export default reducer
