import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.apps

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, version, system, action, code, release) =>{
    const obj = {
        Action:     action,
        Code:       code,
        Release:    release,
        System:     system,
        Version:    version
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, version, system, action, code, release) =>{
    const obj = {
        Action:     action,
        Code:       code,
        Release:    release,
        System:     system,
        Version:    version
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const postValidate = (code) =>{
    if(!code){
        return {flag:false, message:'Parece que algo anda mal con el codigo.'}
    }
    return {flag:true, message:''}
}

export const updateValidate = (code) =>{
    if(!code){
        return {flag:false, message:'Parece que algo anda mal con el codigo.'}
    }
    return {flag:true, message:''}
}