import { useEffect } from 'react';
import { connect } from 'react-redux';
// Redux
import {getCompanies}   from '../../redux/actions/Companies';
import {getCities}      from '../../redux/actions/Cities';
// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// Api
import {getData}    from '../../utils/companies';
import {getData as getCitiesData}   from '../../utils/cities';

const Index = ({stateView, token, getCompanies, getCities}) => {
    const GetData = async() =>{
        const res1 = await getData(token)
        const res2 = await getCitiesData(token)
        getCompanies(res1.data)
        getCities(res2.data)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Empresas' />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    token:          state.Panel.token
})

export default connect(mapStateToProps, {getCompanies, getCities})(Index);
