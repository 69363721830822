import {Container, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton} from '@material-ui/core';
import {connect} from 'react-redux';
import Pagination           from '@material-ui/lab/Pagination';
// Redux
import {changeView} from '../../redux/actions/Menu';
// Values
import {Ic_edit, Ic_delete, StateView} from '../../values';
// Styles
import {useStyles}          from '../../style';
const Tables = ({changeView}) => {
    const classes = useStyles()
    const edit = (item) => {
        changeView(StateView.state3)
    }
    return (
        <div style={{position:'relative'}}>
            <Container maxWidth="xl">
                <TableContainer style={{background:'#fff'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow style={{background:'#132327', height:50}}>
                                {
                                    ['Empresa', 'Total', 'Estatus', 'N Servicios', 'Editar'].map((item, index) =>
                                        <TableCell align="center" key={index} className={classes.table_title2} style={{fontFamily:'bold', color:'#fff'}}> {item} </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{borderBottom:`1px solid #f3f4f7`}}>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>Llantek</TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>$ 325.00 MXN</TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}><span style={{fontWeight:700}}>Pendiente</span></TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>1</TableCell>
                                <TableCell align="center" className={classes.table_text2}>
                                    <IconButton onClick={() => edit()}>
                                        <img alt="ic_edit" src={Ic_edit} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{borderBottom:`1px solid #f3f4f7`}}>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>Notend</TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>$ 250.00 MXN</TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}><span style={{fontWeight:700, color:'#44d7b6'}}>Pagado</span></TableCell>
                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}>1</TableCell>
                                <TableCell align="center" className={classes.table_text2}>
                                    <IconButton onClick={() => edit()}>
                                        <img alt="ic_edit" src={Ic_edit} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{height:80}}>
                    <div style={{position:'absolute', bottom:10, right:20}}>
                        <Pagination count={1} variant="outlined" color="primary" shape="rounded" />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default connect(null, {changeView})(Tables);
