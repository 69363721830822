import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.companies

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const getACompany = async(token, extra="") =>{
    return await get(url, token, extra)
}

export const postData = async(token, name, bussines, rfc, clabe, addres, city, cityId, picture, document) =>{
    const obj = {
        Name:       name,
        Bussines:   bussines,
        RFC:        rfc,
        CLABE:      clabe,
        Addres:     addres,
        City:       city,
        CityId:     cityId,
        Picture:    picture,
        Document:   document
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, name, bussines, rfc, clabe, addres, city, cityId, picture, document) =>{
    const obj = {
        Name:       name,
        Bussines:   bussines,
        RFC:        rfc,
        CLABE:      clabe,
        Addres:     addres,
        City:       city,
        CityId:     cityId,
        Picture:    picture,
        Document:   document
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const validatePost = (name, rfc, clabe, addres, city, picture, document) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con el nombre.'}
    }
    if(!rfc){
        return {flag:false, message:'Parece que algo anda mal con el rfc.'}
    }
    if(!clabe){
        return {flag:false, message:'Parece que algo anda mal con la clabe.'}
    }
    if(!addres){
        return {flag:false, message:'Parece que algo anda mal con la dirección.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!picture){
        return {flag:false, message:'Parece que algo anda mal con tu fotografia de la fachada.'}
    }
    if(!document){
        return {flag:false, message:'Parece que algo anda mal con tu fotografia del comprobante.'}
    }
    return {flag:true, message:''}
}

export const validateUpdate = (name, rfc, clabe, addres, city) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con el nombre.'}
    }
    if(!rfc){
        return {flag:false, message:'Parece que algo anda mal con el rfc.'}
    }
    if(!clabe){
        return {flag:false, message:'Parece que algo anda mal con la clabe.'}
    }
    if(!addres){
        return {flag:false, message:'Parece que algo anda mal con la dirección.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    return {flag:true, message:''}
}