import { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import firebase from "../../firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Drawer, Container, AppBar, Toolbar, List, CssBaseline, ListItem } from "@material-ui/core";

// Redux
import { updateDrawer, changeView, cleanData } from "../../redux/actions/Menu";
// Values
import { Logo, Logo_small, Img_logo, Img_logo_wheel, StateView, Ic_close_session } from "../../values";
// Components
import FloatingMessage from "./FloatingMessage";

const drawerWidth = 290;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    boxShadow: " 0px 0px 5px -4px rgba(0,0,0,0.75)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    boxShadow: " 0px 0px 5px -4px rgba(0,0,0,0.75)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 10,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 2,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // marginTop:30,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#f3f4f7",
    minHeight: "100vh",
  },
  listScroll: {
    overflowY: "auto",
    overflowX: "hidden",
    // overflowY:'hidden',
    height: "100vh",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      // height:6,
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  button_open: {
    minWidth: 150,
    marginLeft: -15,
    cursor: "pointer",
    paddingTop: 10,
    paddingBottom: 10,
  },
  button_close: {
    minWidth: 150,
    marginLeft: 70,
    cursor: "pointer",
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const Menu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(props.View);

  // Functions ------------------------------------------------------------
  const handleDrawerOpen = () => {
    setOpen(true);
    props.updateDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    props.updateDrawer(false);
  };
  const logOut = () => {
    if (window.confirm("Seguro que quieres proceder a cerrar tu sesión?")) {
      window.localStorage.removeItem("llaoz-key");
      window.localStorage.removeItem("llaoz-roles");
      firebase.auth().signOut();
      history.push("/");
    }
  };

  const menuChange = (item) => {
    if (props.stateView !== StateView.state1) {
      props.changeView(StateView.state1);
      props.cleanData();
    }

    // Redirect
    history.push(`/${item.path.toLowerCase()}`);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ background: "white", boxShadow: " 0px 0px 0px 0px rgba(0,0,0,0.75)" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div
            onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
            className={clsx({ [classes.button_open]: open, [classes.button_close]: !open })}
            style={{ display: "flex", alignItems: "center" }}
          >
            {open ? (
              <>
                <ArrowBackIcon style={{ color: "black" }} />
                <label style={{ color: "black", marginLeft: 10, cursor: "pointer", fontFamily: "regular" }}>
                  Ocultar menú
                </label>
              </>
            ) : (
              <>
                <label style={{ color: "black", marginRight: 10, cursor: "pointer", fontFamily: "regular" }}>
                  Mostrar menú
                </label>
                <ArrowForwardIcon style={{ color: "black" }} />
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img alt="" src={props.admin.image} style={{ width: 40, borderRadius: 50, marginRight: 20 }} />
              <label style={{ color: "black", fontFamily: "regular" }}>
                {props.admin.name} {props.admin.lastname}
              </label>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.listScroll} id="scroll">
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <label style={{ textAlign: "right", paddingRight: 20, fontSize: 10 }}>V 1.3.4</label>
            </div>
            <div
              onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
              style={{ marginTop: 10, marginBottom: 30, display: "flex", justifyContent: "center", cursor: "pointer" }}
            >
              <img
                alt="Nayarit"
                src={open ? Logo : Logo_small}
                style={{ width: open ? 120 : "40%", userSelect: "none", pointerEvents: "none" }}
              />
              {/* <img
                alt=""
                src={open ? Img_logo : Img_logo_wheel}
                style={{ height: open ? 75 : 35, userSelect: "none", pointerEvents: "none" }}
              /> */}
            </div>
          </div>
          <div style={{ height: "100%", display: "flex" }}>
            <List style={{ width: "100%" }}>
              {props.selectedMenu.map((item, index) =>
                item.path.toLowerCase() === window.location.pathname.slice(1) ? (
                  <ListItem button key={index} style={{ paddingLeft: 0, height: 50 }} onClick={() => menuChange(item)}>
                    <Container>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={item.image2} style={{ width: 30, height: 30, objectFit: "contain" }} />
                        <label
                          style={{
                            fontSize: 16,
                            margin: 0,
                            paddingLeft: 30,
                            cursor: "pointer",
                            fontWeight: 600,
                            fontFamily: "bold",
                          }}
                        >
                          {open && item.text}
                        </label>
                      </div>
                    </Container>
                  </ListItem>
                ) : (
                  <ListItem button key={index} onClick={() => menuChange(item)} style={{ paddingLeft: 0, height: 50 }}>
                    <Container>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={item.image} style={{ width: 30, height: 30, objectFit: "contain" }} />
                        <label
                          style={{ fontSize: 16, margin: 0, paddingLeft: 30, cursor: "pointer", fontFamily: "regular" }}
                        >
                          {open && item.text}
                        </label>
                      </div>
                    </Container>
                  </ListItem>
                )
              )}
              <ListItem button onClick={() => logOut()} style={{ paddingLeft: 0, height: 50 }}>
                <Container>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" src={Ic_close_session} style={{ width: 30, height: 30, objectFit: "contain" }} />
                    <label style={{ fontSize: 16, margin: 0, paddingLeft: 20, fontFamily: "regular" }}>
                      {open && "Cerrar sesión"}
                    </label>
                  </div>
                </Container>
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <props.component />
        <FloatingMessage />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  View: state.View.viewDrawer,
  stateView: state.View.stateView,
  selectedMenu: state.View.selectedMenu,
  admin: state.Panel.admin,
});

const mapDispatchToProps = {
  updateDrawer,
  changeView,
  cleanData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
