import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}         from '../../redux/actions/Menu';
import {updateReports}      from '../../redux/actions/Reports';
// Components
import Input        from '../global/Input';
import Button       from '../global/Button';
import ButtonsForm  from '../global/ButtonsForm';
import InputArea    from '../global/InputArea';
// Values
import {StateView, toastText}   from '../../values';
// Api
import {deleteData}             from '../../utils/reports';

const Form = ({stateView, data, changeView, token, updateReports}) => {
    const [blockData, setBlockData] = useState('disabled')
    // State buttons ----------------------------------------------------------------
    const [erase, setErase]         = useState(0)

    // Data -------------------------------------------------------------------------
    const [name, setName]           = useState(data.Name?data.Name:'')
    const [email, setEmail]         = useState(data.Email?data.Email:'')
    const [message, setMessage]     = useState(data.Message?data.Message:'')
    const [phone, setPhone]         = useState(data.Phone?data.Phone:'')
    const [serviceId, setServiceId] = useState(data.ServiceId?data.ServiceId:'')
    const [subject, setSubject]     = useState(data.Subject?data.Subject:'')

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateReports([])
    }

    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateReports([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <Input title={'Nombre'} value={name} setData={setName} blockData={blockData} />
                            <Input title={'Correo'} value={email} setData={setEmail} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Asunto'} value={subject} setData={setSubject} blockData={blockData} />
                            <Input title={'Teléfono'} value={phone} setData={setPhone} blockData={blockData} />
                        </div>
                    </div>
                    <InputArea title={'Mensaje'} value={message} setData={setMessage} blockData={blockData} />
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div style={{maxWidth:570, width:'100%'}}>
                            <Input title={'Servicio'} value={serviceId} setData={setServiceId} blockData={blockData} />
                        </div>
                        <div style={{width:10}} />
                        <Button text={'VER'} height={35} />
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} edit={2} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Reports.reportsEdit,
    token:          state.Panel.token,
})
export default connect(mapStateToProps, {changeView, updateReports})(Form);
