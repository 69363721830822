
import {storage} from '../firebase';

export const emailformat = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/;
export const phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") =>{
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (error) {
        console.log(error)
    }
}

export const formatDate = (date) =>{
    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    // const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric' }
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const formatDate2 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const formatDate3 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const HexToRgb = (value) =>{
    return value.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16))
}

export const RgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')

export const deployImage = async(image, location, name) =>{
    console.log("Deploy image");
    let urlImage = ""
    let name1   = ""
    if(image !== "" && image !== undefined){        
        const format = (image.name).split(".").pop()
        name1 = name+(new Date()).toISOString() + "." + format
        const upload = storage.ref(`/Panel Web/${location}/${name1}`).put(image)
        await upload.then((uploadSnapshot) =>{
            return uploadSnapshot.ref.getDownloadURL();
        })
        .then((url) =>{
            urlImage = url
        })
    }
    return urlImage
}
