import {Ic_visibility, Ic_visibility_off} from '../../values';

const Input = ({text, type='text', changeData, opt, pattern='', view=false, eye, setEye, status}) =>{
    return(
        <div style={{position:'relative'}}>
            <img alt="" style={{display:view?'block':'none', position:'absolute', right:10, top:5, cursor:'pointer', width:20}}
                src={eye?Ic_visibility:Ic_visibility_off} onClick={()=> setEye(!eye)} />
            <label style={{userSelect:'none'}}>
                <input style={{borderBottomColor:status===0?'':status===1?'#2fd25a':'#FF1973', backgroundColor:'#f1f4f8'}}
                    className={'login_input'} 
                    type={type} pattern={pattern} 
                    onChange={(e) =>changeData(e.target.value, opt)} />
                <span className="login_input_text">{text}</span>
            </label>
        </div>
    )
}

export default Input;