import {useState}   from 'react';
import {es}         from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {TextField}  from '@material-ui/core';
import 'date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';


// Styles
import {useStyles} from '../../style';

// Component
const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
}

const DatePickers = ({date, setDate, disabled="disabled", title="Fecha", position="start"}) => {
    const classes = useStyles()
    const [selectedDate, setSelectedDate] = useState(date?date:new Date())
    const handleDateChange = (date) => {
        setSelectedDate(date)
        // const fecha = JSON.stringify(date)
        // console.log("F1: ", fecha);
        // console.log("F:2 ", date.toISOString());
        setDate(date.toISOString())
    }
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{title}</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <KeyboardDateTimePicker
                    TextFieldComponent={TextFieldComponent}
                    InputAdornmentProps={{ position: position }}
                    className={classes.input}
                    style={{paddingTop:2, width:'100%'}}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy hh:mm a"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage={"Fecha incorrecta"}
                    InputProps={{ disableUnderline: true }}
                    disabled={disabled==='disabled'?true:false}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DatePickers;