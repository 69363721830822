import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from "react-loading";

// export const useStyles = makeStyles(theme => ({
//     button:{
//         border:'none',
//         width:120,
//         height:40,
//         cursor:'pointer',
//         // fontSize:15,
//         // fontWeight:600
//     }
// }));

import {useStyles} from '../../style';

const Button = ({typeText='uppercase', border=5, text='Enter', background='#1E7DFF', color='#fff',fontSize=14, fontWeight=500, loading=false, height=40, onClick}) => {
    const classes = useStyles()
    return (
        <>
            <button onClick={onClick} className={classes.button} 
                style={{borderRadius:border, backgroundColor:background, 
                color:color, textTransform:typeText, fontSize:fontSize, 
                fontWeight:fontWeight, fontFamily:'bold', height:height}}>
                    {
                        !loading?
                        <span> {text} </span>
                        :
                        <div style={{position:'relative'}}>
                            <div style={{position:'absolute', top:-15, left:'50%', transform:'translate(-50%)'}}>
                                <ReactLoading type={"bars"} color={"white"} style={{fill:'white', height:35, width:35}} />
                            </div>
                        </div>
                    }
            </button>
        </>
    );
};

export default Button;