import {stateData, reduxState} from '../../values';

const initialState = {
    services:       [],
    status:         stateData.state1,
    servicesEdit:   [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_service){
        return {
            ...state,
            services:   action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_service){
        return {
            ...state,
            servicesEdit: action.payload
        }
    }
    return state
}
export default reducer