import { emailformat } from "../index";
import { urlApi, urlPath, get, post, update, deleteRegister } from "../../values/api";
const url = urlApi + urlPath.partners;

export const getData = async (token, extra = "") => {
  return await get(url, token, extra);
};

export const getOneData = async (id, token, extra = "") => {
  const auxUrl = urlApi + urlPath.partner + `/${id}`;
  return await get(auxUrl, token, extra);
};

export const postData = async (
  token,
  name,
  surname,
  phone,
  pin,
  company,
  companyId,
  picture,
  document,
  email,
  rol,
  password1
) => {
  const obj = {
    Name: name,
    Surname: surname,
    Email: email.trim(),
    Phone: phone,
    PIN: pin,
    Rol: rol,
    Company: company,
    CompanyId: companyId.trim(),
    PictureProfile: picture,
    PictureDocument: document,
    Password: password1,
  };
  return await post(obj, url, token);
};

export const updateData = async (id, token, name, surname, phone, pin, company, companyId, picture, document, rol) => {
  const obj = {
    Name: name,
    Surname: surname,
    Phone: phone,
    PIN: pin,
    Rol: rol,
    Company: company,
    CompanyId: companyId.trim(),
    PictureProfile: picture,
    PictureDocument: document,
  };
  return await update(obj, url, id, token);
};

export const updateRadius = async (id, Radius, position, token) => {
  const PositionRadius = {
    Lat: position.lat,
    Lng: position.lng,
  };
  const obj = {
    Radius: parseFloat(Radius),
    PositionRadius,
  };
  return await update(obj, url, id, token);
};

export const deleteData = async (id, token) => {
  return deleteRegister(url, id, token);
};

export const postValidate = (
  name,
  surname,
  phone,
  pin,
  company,
  companyId,
  picture,
  document,
  email,
  password1,
  password2
) => {
  if (!picture) {
    return { flag: false, message: "Parece que algo anda mal con la foto de perfil." };
  }
  if (!document) {
    return { flag: false, message: "Parece que algo anda mal con la foto del ine." };
  }
  if (!name) {
    return { flag: false, message: "Parece que algo anda mal con tu nombre." };
  }
  if (!surname) {
    return { flag: false, message: "Parece que algo anda mal con tu apellido." };
  }
  if (!email.match(emailformat)) {
    return { flag: false, message: "Parece que algo anda mal con tu correo." };
  }
  if (!password1) {
    return { flag: false, message: "Parece que algo anda mal con tu contraseña." };
  }
  if (!password2) {
    return { flag: false, message: "Parece que algo anda mal con tu contraseña." };
  }
  if (password1 !== password2) {
    return { flag: false, message: "Tus contraseñas deben de coincidir." };
  }
  if (password1.length <= 7 || password2.length <= 7) {
    return { flag: false, message: "Tu contraseña tiene que tener mas de 7 caracteres." };
  }
  if (!phone) {
    return { flag: false, message: "Parece que algo anda mal con el teléfono." };
  }
  if (!pin) {
    return { flag: false, message: "Parece que algo anda mal con el pin." };
  }
  if (!company || company === "ERROR") {
    return { flag: false, message: "Parece que algo anda mal con el id de la empresa." };
    // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
  }
  if (!companyId) {
    return { flag: false, message: "Parece que algo anda mal con el id de la empresa." };
  }

  return { flag: true, message: "" };
};

export const updateValidate = (name, surname, phone, pin, company, companyId) => {
  if (!name) {
    return { flag: false, message: "Parece que algo anda mal con tu nombre." };
  }
  if (!surname) {
    return { flag: false, message: "Parece que algo anda mal con tu apellido." };
  }
  if (!phone) {
    return { flag: false, message: "Parece que algo anda mal con el teléfono." };
  }
  if (!pin) {
    return { flag: false, message: "Parece que algo anda mal con el pin." };
  }
  if (!company || company === "ERROR") {
    return { flag: false, message: "Parece que algo anda mal con el id de la empresa." };
    // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
  }
  if (!companyId) {
    return { flag: false, message: "Parece que algo anda mal con el id de la empresa." };
  }
  return { flag: true, message: "" };
};
