import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.faqs

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, type, question, answer) =>{
    const obj = {
        Type:       type,
        Question:   question,
        Answer:     answer
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, type, question, answer) =>{
    const obj = {
        Type:       type,
        Question:   question,
        Answer:     answer
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const validateData = (type, question, answer) =>{
    if(!type){
        return {flag:false, message:'Parece que algo anda mal con el tipo.'}
    }
    if(!question){
        return {flag:false, message:'Parece que algo anda mal con la pregunta.'}
    }
    if(!answer){
        return {flag:false, message:'Parece que algo anda mal con la respuesta.'}
    }
    return {flag:true, message:''}
}