import React from 'react';

import {Ic_car, Ic_motorcycle, Ic_truck, Ic_van} from '../../values';
import {useStyles}  from '../../style';

const Item = ({image, value, setValue, valueDef}) =>{
    const classes = useStyles()
    return(
    <div className={classes.card_vehicle} onClick={() => setValue(valueDef)} style={{backgroundColor:value===valueDef?'#dde8fd':'#f6f6f6'}}>
            <img alt={`${image}`} src={image} style={{width:50}} />
        </div>
    )
}

const Vehicles = ({value, setValue}) => {
    const classes = useStyles()
    return (
        <div>
            <p className={classes.title_input2} style={{marginBottom:0}}>{'Vehículo'}</p>
            <div style={{display:'flex', flexWrap:'wrap'}}>
                <Item image={Ic_motorcycle} value={value} setValue={setValue} valueDef={1} />
                <Item image={Ic_car} value={value} setValue={setValue} valueDef={2} />
                <Item image={Ic_van} value={value} setValue={setValue} valueDef={3} />
                <Item image={Ic_truck} value={value} setValue={setValue} valueDef={4} />
            </div>
        </div>
    );
};

export default Vehicles;