import {stateData, reduxState} from '../../values';

const initialState = {
    invoice:        [],
    invoiceAux:     [],
    statusSearch:   0,
    status:         stateData.state1,
    invoiceEdit:    [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_invoice){
        return {
            ...state,
            invoice:    action.payload,
            invoiceAux: action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_invoice){
        return {
            ...state,
            invoiceEdit: action.payload
        }
    }
    if(action.type === reduxState.re_downloading){
        return {
            ...state,
            status: stateData.state1
        }
    }
    if(action.type === reduxState.filter_invoice){
        return {
            ...state,
            invoice: action.payload,
            statusSearch: action.statusSearch
        }
    }
    return state
}
export default reducer