import { useEffect }    from 'react';
import { connect }      from 'react-redux';
// Redux
import {getInvoice}     from '../../redux/actions/Invoice';
// Components
import Head         from './Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// Api
import {getData}    from '../../utils/invoice';

const Index = ({stateView, status, token, getInvoice}) => {

    const GetData = async() =>{
        const res = await getData(token)
        getInvoice(res.data)
    }
    useEffect(() =>{
        console.log("GET_INVOICE_DATA");
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    },[status])
    return (
        <div>
            <Head title='Facturación' view={false} />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    status:         state.Invoice.status,
    token:          state.Panel.token
})

export default connect(mapStateToProps, {getInvoice})(Index);
