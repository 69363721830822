import ic_error from "../images/ic_error.svg";
import ic_ok from "../images/ic_ok.svg";
import img_logo from "../images/img_logo.svg";
import img_logo_wheel from "../images/img_logo_wheel.svg";
import img_we_can from "../images/img_we_can.svg";
import oval_a from "../images/oval_a.svg";
import oval_b from "../images/oval_b.svg";
import background from "../images/background.svg";
import ic_next from "../images/ic_next.svg";
import ic_access from "../images/ic_access.svg";
import ic_propbably from "../images/ic_propbably.svg";
import ic_suspicious from "../images/ic_suspicious.svg";
import img_logo_a from "../images/img_logo.svg";
import ic_picture from "../images/ic_picture.svg";
import ic_search from "../images/ic_search.svg";
import ic_edit from "../images/ic_edit.svg";
import ic_delete from "../images/ic_delete.svg";
import ic_car from "../images/ic_car.svg";
import ic_motorcycle from "../images/ic_motorcycle.svg";
import ic_truck from "../images/ic_truck.svg";
import ic_van from "../images/ic_van.svg";
import ic_sync from "../images/ic_sync.svg";
import ic_sorry from "../images/ic_sorry.svg";
import ic_success from "../images/ic_success.svg";
import ic_lock from "../images/ic_lock.svg";
import ic_visibility from "../images/ic_visibility.svg";
import ic_visibility_off from "../images/ic_visibility_off.svg";
import ic_next_white from "../images/ic_next_white.svg";
import ic_service_center from "../images/ic_service_center.svg";
import ic_service_center_selected from "../images/ic_service_center_selected.svg";
import img_tyre from "../images/img_tyre.svg";
import img_mobile_tyre from "../images/img_mobile_tyre.svg";
import img_tow from "../images/img_tow.svg";
import ic_marker2 from "../images/ic_marker.svg";
import ic_marker_origin from "../images/ic_marker_origin.svg";
import ic_marker_destiny from "../images/ic_marker_destiny.svg";
import ic_zones from "../images/ic_zones.svg";
import ic_zones_selected from "../images/ic_zones_selected.svg";
import location from "../images/location.svg";
import ic_whatsapp from "../images/ic_whatsapp.svg";

import ic_close_session from "../images/ic_close_session.svg";
// import ic_close_session_b   from '../images/ic_close_session_b.svg';

import ic_faqs from "../images/ic_faqs.svg";
import ic_faqs_b from "../images/ic_faqs_b.svg";
import ic_messenger from "../images/ic_messenger.svg";
import ic_messenger_b from "../images/ic_messenger_b.svg";

import ic_options from "../images/ic_options.svg";
import ic_location from "../images/ic_location.svg";

import ic_home from "../images/ic_home.svg";
import ic_home_b from "../images/ic_home_b.svg";
import ic_admins from "../images/ic_admins.svg";
import ic_admind_b from "../images/ic_admind_b.svg";
import ic_app from "../images/ic_app.svg";
import ic_app_b from "../images/ic_app_b.svg";
import ic_branches from "../images/ic_branches.svg";
import ic_branches_b from "../images/ic_branches_b.svg";
import ic_city from "../images/ic_city.svg";
import ic_city_b from "../images/ic_city_b.svg";
import ic_company from "../images/ic_company.svg";
import ic_company_b from "../images/ic_company_b.svg";
import ic_contact from "../images/ic_contact.svg";
import ic_contact_b from "../images/ic_contact_b.svg";
import ic_fare from "../images/ic_fare.svg";
import ic_fare_b from "../images/ic_fare_b.svg";
import ic_history from "../images/ic_history.svg";
import ic_history_b from "../images/ic_history_b.svg";
import ic_invoice from "../images/ic_invoice.svg";
import ic_invoice_b from "../images/ic_invoice_b.svg";
import ic_notifications from "../images/ic_notifications.svg";
import ic_notifications_b from "../images/ic_notifications_b.svg";
import ic_partners from "../images/ic_partners.svg";
import ic_partners_b from "../images/ic_partners_b.svg";
import ic_payment from "../images/ic_payment.svg";
import ic_payment_b from "../images/ic_payment_b.svg";
import ic_reports from "../images/ic_reports.svg";
import ic_reports_b from "../images/ic_reports_b.svg";
import ic_services from "../images/ic_services.svg";
import ic_services_b from "../images/ic_services_b.svg";
import ic_users from "../images/ic_users.svg";
import ic_users_b from "../images/ic_users_b.svg";
import ic_vehicles from "../images/ic_vehicles.svg";
import ic_vehicles_b from "../images/ic_vehicles_b.svg";
import img_xml from "../images/img_xml.svg";
import img_pdf from "../images/img_pdf.svg";
import pdf from "../images/PDF.svg";
import xml from "../images/XML.svg";

import ic_arrow_select from "../images/ic_arrow_select.svg";
import ic_arrow_white from "../images/ic_arrow_white.svg";
import ic_payment_a from "../images/ic_payment_a.svg";
import ic_sort_white from "../images/ic_sort_bottom_selected.svg";
import ic_sort_gray from "../images/ic_sort_bottom.svg";

import ic_marker from "../images/ic_marker.png";
import ic_marker_selected from "../images/ic_marker_selected.png";
import ic_set_destiny from "../images/ic_set_destiny.svg";
import ic_set_origin from "../images/ic_set_origin.svg";

import image from "../images/image.jpg";
import logo from "../images/logo.svg";
import logo_small from "../images/logo_small.svg";

export const Logo = logo;
export const Logo_small = logo_small;

export const User = image;
export const Ic_error = ic_error;
export const Ic_ok = ic_ok;
export const Img_logo = img_logo;
export const Img_logo_wheel = img_logo_wheel;
export const Img_we_can = img_we_can;
export const Oval_a = oval_a;
export const Oval_b = oval_b;
export const Background = background;
export const Ic_next = ic_next;
export const Ic_access = ic_access;
export const Ic_propbably = ic_propbably;
export const Ic_suspicious = ic_suspicious;
export const Img_logo_a = img_logo_a;
export const Ic_picture = ic_picture;
export const Ic_search = ic_search;
export const Ic_edit = ic_edit;
export const Ic_delete = ic_delete;
export const arrow = ic_arrow_select;
export const arrow_white = ic_arrow_white;
export const Img_xml = img_xml;
export const Img_pdf = img_pdf;
export const Pdf = pdf;
export const Xml = xml;
export const Ic_payment_a = ic_payment_a;
export const Ic_zones_selected = ic_zones_selected;
export const Ic_zones = ic_zones;

export const Ic_car = ic_car;
export const Ic_motorcycle = ic_motorcycle;
export const Ic_truck = ic_truck;
export const Ic_van = ic_van;

export const Ic_close_session = ic_close_session;

export const Ic_options = ic_options;
export const Ic_location = ic_location;

export const Ic_marker = ic_marker;
export const Ic_marker_selected = ic_marker_selected;

export const Ic_sync = ic_sync;
export const Ic_sorry = ic_sorry;
export const Ic_success = ic_success;
export const Ic_lock = ic_lock;
export const Ic_visibility = ic_visibility;
export const Ic_visibility_off = ic_visibility_off;
export const Ic_next_white = ic_next_white;

export const Img_tyre = img_tyre;
export const Img_mobile_tyre = img_mobile_tyre;
export const Img_tow = img_tow;

export const Ic_marker2 = ic_marker2;
export const Ic_marker_origin = ic_marker_origin;
export const Ic_marker_destiny = ic_marker_destiny;
export const Ic_set_destiny = ic_set_destiny;
export const Ic_set_origin = ic_set_origin;

export const Location = location;
export const Ic_whatsapp = ic_whatsapp;
export const Ic_sort_white = ic_sort_white;
export const Ic_sort_gray = ic_sort_gray;

export const selectedMenu = [
  { permission: "Home", path: "home", text: "Home", image: ic_home, image2: ic_home_b },
  {
    permission: "Home",
    path: "servicecenter",
    text: "Centro de servicio",
    image: ic_service_center,
    image2: ic_service_center_selected,
  },
  { permission: "Admins", path: "admins", text: "Admins", image: ic_admins, image2: ic_admind_b },
  { permission: "Users", path: "users", text: "Usuarios", image: ic_users, image2: ic_users_b },
  { permission: "Cities", path: "cities", text: "Ciudades", image: ic_city, image2: ic_city_b },
  { permission: "Fares", path: "fares", text: "Tarifas", image: ic_fare, image2: ic_fare_b },
  { permission: "Companies", path: "companies", text: "Empresas", image: ic_company, image2: ic_company_b },
  { permission: "Partners", path: "partners", text: "Socios", image: ic_partners, image2: ic_partners_b },
  { permission: "Branches", path: "branches", text: "Sucursales", image: ic_branches, image2: ic_branches_b },
  { permission: "Vehicles", path: "vehicles", text: "Vehiculos", image: ic_vehicles, image2: ic_vehicles_b },
  { permission: "App", path: "app", text: "App", image: ic_app, image2: ic_app_b },
  { permission: "Services", path: "services", text: "Servicios", image: ic_services, image2: ic_services_b },
  { permission: "History", path: "history", text: "Historial", image: ic_history, image2: ic_history_b },
  { permission: "Payment", path: "payment", text: "Pagos", image: ic_payment, image2: ic_payment_b },
  { permission: "Invoice", path: "invoice", text: "Facturacion", image: ic_invoice, image2: ic_invoice_b },
  { permission: "Contact", path: "contact", text: "Contacto", image: ic_contact, image2: ic_contact_b },
  { permission: "Reports", path: "reports", text: "Reportes", image: ic_reports, image2: ic_reports_b },
  {
    permission: "Notifications",
    path: "notifications",
    text: "Notificaciones",
    image: ic_notifications,
    image2: ic_notifications_b,
  },
  { permission: "Faqs", path: "faqs", text: "Preguntas fecuentes", image: ic_faqs, image2: ic_faqs_b },
  { permission: "Channels", path: "channels", text: "Mensajero", image: ic_messenger, image2: ic_messenger_b },
  { permission: "Home", path: "zones", text: "Zonas", image: ic_zones, image2: ic_zones_selected },

  // {path:'school',     text:'Escuela',         image:ic_school,    image2:ic_school_b},
  // {path:'students',   text:'Estudiantes',     image:ic_students,  image2:ic_students_b},
  // {path:'tutor',      text:'Tutores',         image:ic_tutor,     image2:ic_tutor_b},
  // {path:'survey',     text:'Cuestionarios',   image:ic_survey,    image2:ic_survey_b},
];

export const textPanel = {
  email: "Correo",
  password: "Contraseña",
  recover: "¿Olvidaste tu contraseña?",
  enter: "Entrar",
};

//
// ─── TOAST TEXT ─────────────────────────────────────────────────────────────────
//
export const toastText = {
  update: "Datos actualizados.",
  add: "Datos agregados.",
  delete: "Datos eliminados.",
  warning: "Parace que surgio un problema.",
  void: "Parace que faltan campos por llenar.",
  password: "Las contraseñas coinciden.",
  failLogin: "No se pudo iniciar sesión, verifica tus datos.",
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const stateData = {
  state1: "DOWNLOADING",
  state2: "DOWNLOADED",
};

//
// ─── STATE OF REDUX ─────────────────────────────────────────────────────────────
//
export const reduxState = {
  drawer: "UPDATE_DRAWER",
  view: "CHANGE_VIEW",
  login: "LOGIN",
  refresh: "REFRESH",
  get_admin: "GET_ADMIN",
  update_admin: "UPDARE_ADMIN",
  get_cities: "GET_CITIES",
  update_cities: "UPDATE_CITIES",
  get_users: "GET_USERS",
  update_users: "UPDATE_USERS",
  get_fares: "GET_FARES",
  update_fares: "UPDATE_FARES",
  get_companies: "GET_COMPANIES",
  update_companies: "UPDATE_COMPANIES",
  get_partners: "GET_PARTNERS",
  update_partners: "UPDATE_PARTNERS",
  get_branches: "GET_BRANCHES",
  update_branches: "UPDATE_BRANCHES",
  get_vehicles: "GET_VWEHICLES",
  update_vehicles: "UPDATE_VEHICLEs",
  get_apps: "GET_APPS",
  update_apps: "UPDATE_APPS",
  get_contacts: "GET_CONTACTS",
  update_contacts: "UPDATE_CONTACTS",
  get_reports: "GET_REPORTS",
  update_reports: "UPDATE_REPORTS",
  get_notifications: "GET_NOTIFICATIONS",
  update_notification: "UPDATE_NOTIFICATION",
  get_service: "GET_SERVICE",
  update_service: "UPDATE_SERVICE",
  get_history: "GET_HISTORY",
  update_history: "UPDATE_HISTORY",
  get_invoice: "GET_INVOICE",
  update_invoice: "UPDATE_INVOICE",
  re_downloading: "RE_DOWNLOADING",
  filter_invoice: "FILTER_INVOICE",
  get_faqs: "GET_FAQS",
  update_faqs: "UPDATE_FAQS",
  get_channels: "GET_CHANNELS",
  update_channels: "UPDATE_CHANNELS",
  get_permissions: "GET_PERMISSIONS",
  update_menu: "UPDATE_MENU",
};
//
// ─── VIEW ───────────────────────────────────────────────────────────────────────
//
export const StateView = {
  state1: "VIEW_DATA",
  state2: "CREATE_DATA",
  state3: "EDIT_DATA",
};
//
// ─── APP ────────────────────────────────────────────────────────────────────────
//
export const systemValue = {
  ANDROID: "ANDROID",
  text1: "Android",
  IOS: "IOS",
  text2: "IOS",
};
export const codeVersion = {
  AGENT: "AGENT",
  text1: "Agente",
  USER: "USER",
  text2: "Usuario",
};
export const actionValue = {
  NOTIFY: "NOTIFY",
  FORCE: "FORCE",
  OPEN: "OPEN",
  text1: "Notificar",
  text2: "Forzar",
  text3: "Abierto",
};

export const notificationValue = {
  value1: "morning",
  value2: "afternoon",
  text1: "Temprano",
  text2: "Tarde",
};

//
// ─── FARES ──────────────────────────────────────────────────────────────────────
//
// export const serviceSelect  = ['TYRE', 'TYRE_MOBILE', 'TOW']

export const serviceSelect = [
  { Type: "TYRE", Text: "Llantera fija" },
  { Type: "TYRE_MOBILE", Text: "Llantera móvil" },
  { Type: "TOW", Text: "Grúa" },
];

export const vehiclesSelect = [
  { Type: "MOTORCYCLE", Text: "Motocicleta" },
  { Type: "CAR", Text: "Carro" },
  { Type: "VAN", Text: "Camioneta" },
  { Type: "TRUCK", Text: "Camión" },
];

export const calcTotal = (llaozPercent, llaozFixed, amount) => {
  return (parseFloat(amount) * (parseFloat(llaozPercent) / 100) + parseFloat(llaozFixed)) * 1.16;
};

export const calcTotalChecOut = (totalLlaoz, amount, checkOutPercent, checkOutFixed) => {
  return (
    ((parseFloat(amount) + parseFloat(totalLlaoz)) * (parseFloat(checkOutPercent) / 100) + parseFloat(checkOutFixed)) *
    1.16
  );
};

//
// ─── COMPANIES ──────────────────────────────────────────────────────────────────
//
export const bussinesSelect = [
  { Value: "TOW", Info: "Gruas" },
  { Value: "TYRE", Info: "Llantera" },
];

//
// ─── PARNERS ────────────────────────────────────────────────────────────────────
//
export const partnerRolesSelect = [
  { Value: "ADMIN", Info: "Admin" },
  { Value: "WORKER", Info: "Operador" },
];

//
// ─── FAQS ───────────────────────────────────────────────────────────────────────
//
export const faqsSelect = {
  Value1: "PARTNER",
  Info1: "Socio",
  Value2: "USER",
  Info2: "Usuario",
};

//
// ─── CHANNELS ───────────────────────────────────────────────────────────────────
//
export const subjectsSelect = ["CONTACT_USER", "CONTACT_PARTNER", "REPORT", "INFO", "INVOICE"];

// testConductores
export const testService = [
  { id: 1, check: "#92cf48", picture: User, name: "Jesus Ramirez", phone: "(33) 2113 8519", date: "18-09-2019 15:35" },
  { id: 2, check: "#f8d247", picture: User, name: "Jesus Ramirez", phone: "(33) 2113 8519", date: "18-09-2019 15:35" },
  { id: 3, check: "#f8d247", picture: User, name: "Jesus Ramirez", phone: "(33) 2113 8519", date: "18-09-2019 15:35" },
];

export const listServiceType = [
  { text: "Todos", value: "ALL" },
  { text: "Llantera móvil", value: "TYRE_MOBILE" },
  { text: "Llantera fija", value: "TYRE" },
  { text: "Grúa", value: "TOW" },
];

export const listVehiclesType = [
  { text: "Todos", value: "ALL" },
  { text: "Camioneta", value: "VAN" },
  { text: "Carro", value: "CAR" },
  { text: "Motocicleta", value: "MOTORCYCLE" },
  { text: "Camión", value: "TRUCK" },
];
