import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';

import {es}         from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {TextField}  from '@material-ui/core';
import 'date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';

// Redux
import {changeView} from '../../redux/actions/Menu';
import {updateHistory}    from '../../redux/actions/History';

// Components
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
import MapService   from '../global/MapService';
import DragImage    from '../global/DragImage';

// Values
import {StateView}      from '../../values';
import {formatMoney}    from '../../utils';
// Styles
import {useStyles}  from '../../style';

// Component --------------------------------------------------------
const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
}

const MultipleInput1 = ({text, value1, value2, setValue, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{text}</p>
            <div style={{width:'49%'}}>
                <input type={'text'}
                    onChange={e => setValue(e.target.value)}
                    defaultValue={value1} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%'}}>
                <input type={'text'}
                        onChange={e => setValue(e.target.value)}
                        defaultValue={value2} 
                        className={classes.input}
                        disabled={blockData} />
            </div>
        </div>
    )
}

const MultipleInput2 = ({text, value1, value2, setValue, date, blockData}) =>{
    const classes = useStyles()
    return(
    <div style={{display:'flex', alignItems:'center'}}>
        <p className={classes.title_input}>{text}</p>
        <div style={{width:'25%'}}>
            <input type={'text'}
                onChange={e => setValue(e.target.value)}
                defaultValue={value1} 
                className={classes.input}
                disabled={blockData} />
        </div>
        <div style={{width:'1%'}} />
        <div style={{width:'25%'}}>
            <input type={'text'}
                onChange={e => setValue(e.target.value)}
                defaultValue={value2} 
                className={classes.input}
                disabled={blockData} />
        </div>
        <div style={{width:'1%'}} />
        <div style={{width:'35%', minWidth:200}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <KeyboardDateTimePicker
                    TextFieldComponent={TextFieldComponent}
                    InputAdornmentProps={{position:'end'}}
                    className={classes.input}
                    style={{paddingTop:2, width:'100%'}}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy hh:mm a"
                    value={date}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage={"Fecha incorrecta"}
                    InputProps={{ disableUnderline: true }}
                    disabled={blockData} />
            </MuiPickersUtilsProvider>
        </div>
    </div>
    )
}

const MultipleInput3 = ({text, value, setValue, date, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{text}</p>
            <div style={{width:'49%'}}>
                <input type={'text'}
                    onChange={e => setValue(e.target.value)}
                    defaultValue={value} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <KeyboardDateTimePicker
                        TextFieldComponent={TextFieldComponent}
                        InputAdornmentProps={{position:'end'}}
                        className={classes.input}
                        style={{paddingTop:2, width:'100%'}}
                        id="date-picker-dialog"
                        format="dd/MM/yyyy hh:mm a"
                        value={date}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        invalidDateMessage={"Fecha incorrecta"}
                        InputProps={{ disableUnderline: true }}
                        disabled={blockData} />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

const InputDate = ({title, date, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
                <p className={classes.title_input}>{title}</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <KeyboardDateTimePicker
                        TextFieldComponent={TextFieldComponent}
                        InputAdornmentProps={{position:'end'}}
                        className={classes.input}
                        style={{paddingTop:2, width:'100%'}}
                        id="date-picker-dialog"
                        format="dd/MM/yyyy hh:mm a"
                        value={date}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        invalidDateMessage={"Fecha incorrecta"}
                        InputProps={{ disableUnderline: true }}
                        disabled={blockData} />
                </MuiPickersUtilsProvider>
        </div>
    )
}

const Form = ({stateView, data, token, changeView, updateHistory}) => {
    const [blockData] = useState('disabled')
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [origen]              = useState(data.Origen?data.Origen:[])
    const [origenAddres]        = useState(data.OrigenAddress?data.OrigenAddress:'')
    const [destination]         = useState(data.Destination?data.Destination:[])
    const [destAddres]          = useState(data.DestinationAddress?data.DestinationAddress:'')

    const [partnerId]           = useState(data.PartnerId?data.PartnerId:'')
    const [partnerName]         = useState(data.PartnerName?data.PartnerName:'')
    const [partnerPhone]        = useState(data.PartnerPhone?data.PartnerPhone:'')
    const [partnerCompany]      = useState(data.PartnerCompany?data.PartnerCompany:'')
    const [partnerPosition]     = useState(data.PartnerPosition?data.PartnerPosition:[])
    const [partnerPosDate]      = useState(data.PartnerPositionDate?data.PartnerPositionDate:'')

    const [service]             = useState(data.Service?data.Service:'')
    const [id]                  = useState(data.Id?data.Id:'')
    const [status]              = useState(data.Status?data.Status:'')
    const [statusDate]          = useState(data.StatusDate?data.StatusDate:'')

    const [userId]              = useState(data.UserId?data.UserId:'')
    const [userName]            = useState(data.UserName?data.UserName:'')
    const [userPhone]           = useState(data.UserPhone?data.UserPhone:'')
    const [userPosition]        = useState(data.UserPosition?data.UserPosition:[])
    const [userPosDate]         = useState(data.UserPositionDate?data.UserPositionDate:'')
    const [chatId]              = useState(data.ChatId?data.ChatId:'')
    
    const [totalLlaoz]          = useState(data.TotalLlaoz?data.TotalLlaoz:'')
    const [totalCheckOut]       = useState(data.TotalCheckOut?data.TotalCheckOut:'')
    const [totalService]        = useState(data.TotalService?data.TotalService:'')

    const [vehiclePicture]      = useState(data.VehiclePicture?data.VehiclePicture:'')
    const [partnerPicture]      = useState(data.PartnerPicture?data.PartnerPicture:'')
    const [userPicture]         = useState(data.UserPicture?data.UserPicture:'')

    const [vehicleName]         = useState(data.VehicleName?data.VehicleName:'')

    const [rate]                = useState(data.Rate?data.Rate:'')
    const [invoiceState]        = useState(data.InvoiceState?data.InvoiceState:'')
    const [comment]             = useState(data.Comment?data.Comment:'')
    const [medal]               = useState(data.Medal?data.Medal:'')
    const [inoiceDate]          = useState(data.InvoiceDate?data.InvoiceDate:'')
    const [report]              = useState(data.Report?data.Report:false)


    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateHistory([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <MapService origen={origen} destination={destination} partner={partnerPosition} user={userPosition} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex', paddingBottom:50}}>
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={vehiclePicture} option='1' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>Vehiculos</p>
                                </div>
                                <div style={{width:75}} />
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={partnerPicture} option='2' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>Socio</p>
                                </div>
                                <div style={{width:75}} />
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={userPicture} option='3' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>Usuario</p>
                                </div>
                            </div>
                            <MultipleInput1 text={'Servicio & Id'} value1={service} value2={id} blockData={blockData} />
                            <MultipleInput3 text={'Estatus'} value={status} date={statusDate} blockData={blockData} />
                        </div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <MultipleInput1 text={'Origen'} value1={origen.Lat} value2={origen.Lng} blockData={blockData} />
                            <MultipleInput1 text={'Destino'} value1={destination.Lat} value2={destination.Lng} blockData={blockData} />
                            <Input title={'Id Socio'} value={partnerId} blockData={blockData} />
                            <Input title={'Socio'} value={partnerName} blockData={blockData} />
                            <MultipleInput2 text={'Pos Socio'} value1={partnerPosition.Lat} value2={partnerPosition.Lng} date={partnerPosDate} blockData={blockData} />
                            <Input title={'Tel Socio'} value={partnerPhone} blockData={blockData} />
                            <Input title={'Company'} value={partnerCompany} blockData={blockData} />
                            <Input title={'Vehículo'} value={vehicleName} blockData={blockData} />
                            
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Dirección O.'} value={origenAddres} blockData={blockData} />
                            <Input title={'Dirección D.'} value={destAddres} blockData={blockData} />
                            <Input title={'Id Usuario'} value={userId} blockData={blockData} />
                            <Input title={'Usuario'} value={userName} blockData={blockData} />
                            <MultipleInput2 text={'Pos Usuario'} value1={userPosition.Lat} value2={userPosition.Lng} date={userPosDate} blockData={blockData} />
                            <Input title={'Tel Usuario'} value={userPhone} blockData={blockData} />
                            <Input title={'Id Chat'} value={chatId} blockData={blockData} />
                        </div>
                    </div>

                    <div style={{display:'flex', marginTop:20}}>
                        <div style={{width:'48%'}}>
                            <InputDate title={'F Facturación'} date={inoiceDate} blockData={blockData} />
                            <Input title={'Rate'} value={rate} blockData={blockData} />
                            <Input title={'Comentarios'} value={comment} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Medalla'} value={medal} blockData={blockData} />
                            <Input title={'Facturacion e.'} value={invoiceState} blockData={blockData} />
                            <Input title={'Reporte'} value={report} blockData={blockData} />

                        </div>
                    </div>

                    <div style={{display:'flex', marginTop:20}}>
                        <div style={{width:'48%'}}>
                            <Input title={'T socio'} value={`$ ${formatMoney(totalService)}`} blockData={blockData} />
                            <Input title={'Llaoz'} value={`$ ${formatMoney(totalLlaoz)}`} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Pasarela'} value={`$ ${formatMoney(totalCheckOut)}`} blockData={blockData} />
                            <Input title={'Total'} value={`$ ${formatMoney(totalService+totalLlaoz+totalCheckOut)}`} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.History.historyEdit,
    token:          state.Panel.token,
})
export default connect(mapStateToProps, {changeView, updateHistory})(Form);
