import { useEffect }    from 'react';
import { connect }      from 'react-redux';
// Redux
import {getReports}     from '../../redux/actions/Reports';
// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// Api
import {getData}    from '../../utils/reports';

const Index = ({stateView, token, getReports}) => {
    const GetData = async() =>{
        const res = await getData(token)
        getReports(res.data)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Reportes' view={false} />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    token:          state.Panel.token
})


export default connect(mapStateToProps, {getReports})(Index);
