import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.channels

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, email, subject) =>{
    const obj = {
        Email:      email,
        Subject:    subject
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, email, subject) =>{
    const obj = {
        Email:      email,
        Subject:    subject
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const validateData = (email) =>{
    if(!email){
        return {flag:false, message:'Parece que algo anda mal con el correo.'}
    }
    return {flag:true, message:''}
}