import {urlApi, urlPath, get, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.history

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 


export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}
