import {stateData, reduxState} from '../../values';

const initialState = {
    notifications:      [],
    status:             stateData.state1,
    notificationsEdit:  [],
    records:            1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_notifications){
        return {
            ...state,
            notifications:  action.payload,
            status:         action.status,
            records:        action.records
        }
    }
    if(action.type === reduxState.update_notification){
        return {
            ...state,
            notificationsEdit: action.payload
        }
    }
    return state
}
export default reducer