import {useState}   from 'react';
import GoogleMapReact from 'google-map-react';

import Button from './Button';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"

const Map = ({setLocation, center = {lat:20.662926, lng:-103.364936}, zoom = 11}) => {
    const [overlay, setOverlay]     = useState()
    var overlay2    = []
    var location    = []
    const handleGoogleMapApi = (google) => {
        let array = []
        let drawingManager = new google.maps.drawing.DrawingManager({
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [ 'polygon']
            },
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            polygonOptions: {
                draggable: false,
                editable: false
            },
        })
        drawingManager.setMap(google.map);
        google.maps.event.addListener(drawingManager, 'overlaycomplete', async(event) =>{
            array = []
            let res = location
            if(res.length !== 0){
                overlay2.setPath([])
                CleanData()
            }
            let path = event.overlay.getPath()
            for (let i = 0; i < path.getLength(); i++) {
                let xy = path.getAt(i);
                const obj ={}
                obj.lat = xy.lat()
                obj.lng = xy.lng()
                array.push(obj)
            }
            // setLocation(array)
            SaveLocation(array, event.overlay)
        })
    }
    const SaveLocation = (array, overlay) =>{
        setLocation(array)
        location = array
        overlay2 = overlay
        setOverlay(overlay)
    }
    const CleanData = () =>{
        try {
            console.log("Clean");
            overlay.setPath([])
            setOverlay([])
            setLocation([])
            overlay2.setPath([])
        } catch (error) {
            // console.log("Error: ", error);
        }
    }
    return (
        <div style={{width:'100%', height:430, paddingBottom:70}}>
            <GoogleMapReact
                bootstrapURLKeys={{ libraries: 'drawing', key: keyMap }}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleGoogleMapApi} />
            <div style={{height:10}} />
            <Button text={'Limpiar'} onClick={CleanData} height={35} />
        </div>
    );
};

export default Map;