import {usePlacesWidget} from "react-google-autocomplete";
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';

// Styles
import {useStyles} from '../../style';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"

const SearchBox = ({setCenter, setZoom}) => {
    const classes = useStyles()

    const { ref } = usePlacesWidget({
        apiKey: keyMap,
        onPlaceSelected: (place) => {
            // console.log("place: ", place);
            infoLocation(place)
        },
        options: {
            types: ["address"],
            componentRestrictions: { country: "mx" },
        },
    });

    const infoLocation = async(place) =>{
        let positions       = {}
        let locationName    = place?place.formatted_address:''
        await geocodeByAddress(locationName)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>{
            positions = {lat, lng}
        });

        setCenter(positions)
        setZoom(17)
    }
    return(
        <>
            <input ref={ref} className={classes.input3} placeholder='' />
        </>
    )
};

export default SearchBox;