import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// Firebase
import firebase from "../../firebase";
// Redux
import { changeView } from "../../redux/actions/Menu";
import { updateUsers } from "../../redux/actions/Users";
// Components
import DragImage from "../global/DragImage";
import Input from "../global/Input";
// import ButtonsForm  from '../global/ButtonsForm';
import Button from "../global/Button";
// Values
import { StateView, toastText } from "../../values";
import { restorePassword } from "../../values/api";
// Api
import { validateData, updateData, deleteData } from "../../utils/users";
import { deployImage } from "../../utils";

const Form = ({ data, token, stateView, changeView, updateUsers }) => {
  const [blockData, setblockData] = useState(stateView === StateView.state2 ? "" : "disabled");
  // State buttons ----------------------------------------------------------------
  const [edit, setEdit] = useState(0);
  const [erase, setErase] = useState(0);
  const [add, setAdd] = useState(0);

  // Data -------------------------------------------------------------------------
  const [image, setImage] = useState(data.Picture ? data.Picture : "");
  const [id, setId] = useState(data.Id ? data.Id : "");
  const [name, setName] = useState(data.Name ? data.Name : "");
  const [surname, setSurname] = useState(data.Surname ? data.Surname : "");
  const [email, setEmail] = useState(data.Email ? data.Email : "");
  const [phone, setPhone] = useState(data.Phone ? data.Phone : "");
  const [tokenFcm, setTokenFcm] = useState(data.TokenFCM ? data.TokenFCM : "");
  const [deviceInfo, setDeviceInfo] = useState(data.DeviceInfo ? data.DeviceInfo : "");
  const [serviceId, setServiceId] = useState(data.ServiceId ? data.ServiceId : "");

  // Functions --------------------------------------------------------------------
  const Back = () => {
    changeView(StateView.state1);
    updateUsers([]);
  };

  const Edit = async () => {
    const value = edit + 1;
    setblockData("");
    setEdit(value);
    if (value === 2) {
      const validate = validateData(name, surname, phone);
      if (validate.flag) {
        let UrlImage = image;
        if (typeof image === "object") {
          UrlImage = await deployImage(image, "Users", "users");
          setImage(UrlImage);
        }
        const res = await updateData(id, token, name, surname, phone, UrlImage);
        if (res) {
          toast.success(toastText.update);
          console.log(toastText.update);
          changeView(StateView.state1);
          updateUsers([]);
        } else {
          toast.warn(toastText.warning);
          console.log(toastText.warning);
          setEdit(1);
        }
      } else {
        toast.warn(validate.message);
        console.log(validate.message);
        setEdit(1);
      }
    }
  };
  const Add = async () => {
    const value = add + 1;
    setAdd(value);
  };
  const Delete = async () => {
    const value = erase + 1;
    setErase(value);
    if (value === 1) {
      const res = await deleteData(id, token);
      if (res) {
        toast.success(toastText.delete);
        console.log(toastText.delete);
        changeView(StateView.state1);
        updateUsers([]);
      } else {
        toast.warn(toastText.warning);
        console.log(toastText.warning);
        setErase(0);
      }
    }
  };

  // const restorePassword = () =>{
  //     if(window.confirm(`¿Quieres enviar un correo, para restablecer la contraseña de la cuenta ${email}?`)){
  //         firebase.auth().sendPasswordResetEmail(email)
  //         .then(() =>{
  //             toast.success(`Se envío a ${email} un correo para restablecer la contraseña`)
  //         })
  //         .catch(() =>{
  //             toast.warn('Sucedio un error intentalo más tarde.')
  //         })
  //     }
  // }

  const mapData = () => {
    setImage(data.Picture);
    setId(data.Id);
    setName(data.Name);
    setSurname(data.Surname);
    setEmail(data.Email);
    setPhone(data.Phone);
    setTokenFcm(data.TokenFCM);
    setDeviceInfo(data.DeviceInfo);
    setServiceId(data.ServiceId);
  };

  useEffect(() => {
    if (data.length !== 0) {
      mapData();
    }
  }, [data]);
  return (
    <Container maxWidth="xl">
      <div style={{ background: "#fff", paddingBottom: 50, paddingTop: 20 }}>
        <Container maxWidth="xl">
          <div style={{ display: "flex" }}>
            <div style={{ width: "48%" }}>
              <DragImage image={image} setImage={setImage} height={300} text={"FOTO DE PERFIL"} blockData={blockData} />
            </div>
            <div style={{ width: "4%" }} />
            <div style={{ width: "48%" }}>
              <Input title={"Id"} value={id} setData={setId} blockData={"disabled"} />
              <Input title={"Nombre"} value={name} setData={setName} blockData={blockData} />
              <Input title={"Apellido"} value={surname} setData={setSurname} blockData={blockData} />
              <Input title={"Teléfono"} value={phone} setData={setPhone} blockData={blockData} />
              <Input title={"Email"} value={email} setData={setEmail} blockData={"disabled"} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <p
                  onClick={() => restorePassword(email)}
                  style={{ cursor: "pointer", color: "#5272f1", fontWeight: 600, fontSize: 14 }}
                >
                  RESTABLECER CONTRASEÑA
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: "#fff", paddingBottom: 50, paddingTop: 20, marginTop: 5 }}>
        <Container maxWidth="xl">
          <Input title={"Device Info"} value={deviceInfo} setData={setDeviceInfo} blockData={"disabled"} />
          <Input title={"Token FCM"} value={tokenFcm} setData={setTokenFcm} blockData={"disabled"} />
          <div style={{ width: "50%" }}>
            <Input title={"Service Id"} value={serviceId} setData={setServiceId} blockData={"disabled"} />
          </div>
        </Container>
      </div>
      <div style={{ background: "#fff", marginTop: 5, paddingTop: 10, paddingBottom: 10 }}>
        <Container maxWidth="xl">
          <Button text={"Volver"} border={50} background={"#132327"} onClick={Back} />
        </Container>
      </div>
      {/* <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} /> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.View.stateView,
  data: state.Users.usersEdit,
  token: state.Panel.token,
});

export default connect(mapStateToProps, { changeView, updateUsers })(Form);
