import { useState } from 'react';
import { Container, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// Redux
import { changeView } from '../../redux/actions/Menu';
import { getPartners, updatePartners } from '../../redux/actions/Partners';
// Componetns
import LoadingData from '../global/LoadingData';
import Pagination from '../global/ButtonsPagination';
// Values
import { Ic_edit, StateView, stateData } from '../../values';
// Styles
import { useStyles } from '../../style';
// Api
import { getData } from '../../utils/partners';

const Tables = ({ data, status, id, token, records, changeView, getPartners, updatePartners }) => {
    const classes = useStyles()
    const [page, setPage] = useState(1)

    const edit = (item) => {
        changeView(StateView.state3)
        updatePartners(item)
    }

    const GetData = async (page) => {
        const res = await getData(token, `?Page=${page}`)
        getPartners(res.data)
        window.scrollTo(0, 0)
    }
    const changePage = (e, v) => {
        setPage(v)
        GetData(v)
    }
    return (
        <div style={{ position: 'relative' }}>
            {
                status === stateData.state2 ?
                    <Container maxWidth="xl">
                        <TableContainer style={{ background: '#fff' }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow style={{ background: '#132327', height: 50 }}>
                                        {
                                            ['Nombre', 'Puesto', 'Empresa'].map((item, index) =>
                                                <TableCell align="center" key={index} className={classes.table_title3} style={{ fontFamily: 'bold', color: '#fff' }}> {item} </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data &&
                                        data.map((item, index) => (
                                            <TableRow key={index} onClick={() => edit(item)} style={{ borderBottom: `1px solid #f3f4f7`, cursor: 'pointer', background: id === item.Id ? '#ebf5f7' : '#fff' }}>
                                                <TableCell align="center" className={classes.table_text3} style={{ fontFamily: 'regular' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: 20, height: 20, background: item.Radius === 0 ? '#FF0080' : '#00CE53', borderRadius: '100%', marginRight: 10 }} />
                                                        <p>
                                                            {item.Name}
                                                        </p>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{ fontFamily: 'regular' }}><span style={{ fontWeight: 700 }}>{item.Rol === 'ADMIN' ? 'Admin' : 'Operador'}</span></TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{ fontFamily: 'regular' }}>{item.Company}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination records={records} page={page} changePage={changePage} />
                    </Container>
                    :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    data: state.Partners.partners,
    id: state.Partners.partnersEdit.Id,
    status: state.Partners.status,
    token: state.Panel.token,
    records: state.Partners.records
})


export default connect(mapStateToProps, { changeView, getPartners, updatePartners })(Tables);
