const filterService = (data, service) => {
  if (service !== "ALL") {
    data = data.filter((i) => {
      return i.Service === service;
    });
  }
  return data;
};

const filterVehicle = (data, vehicle) => {
  if (vehicle !== "ALL") {
    data = data.filter((i) => {
      return i.Vehicle === vehicle;
    });
  }
  return data;
};

export const sortService = (data, order, service, vehicle) => {
  let aux = [...data];

  aux = filterService(aux, service);
  aux = filterVehicle(aux, vehicle);

  if (order) {
    aux.sort((a, b) => {
      let fa = b.Service.toLowerCase(),
        fb = a.Service.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  } else {
    aux.sort((a, b) => {
      let fa = a.Service.toLowerCase(),
        fb = b.Service.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
  return aux;
};

export const sortVehicle = (data, order, service, vehicle) => {
  let aux = [...data];

  aux = filterService(aux, service);
  aux = filterVehicle(aux, vehicle);

  if (order) {
    aux.sort((a, b) => {
      let fa = b.Vehicle.toLowerCase(),
        fb = a.Vehicle.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  } else {
    aux.sort((a, b) => {
      let fa = a.Vehicle.toLowerCase(),
        fb = b.Vehicle.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
  return aux;
};

export const sortTotal = (data, order, service, vehicle) => {
  let aux = [...data];

  aux = filterService(aux, service);
  aux = filterVehicle(aux, vehicle);

  if (order) {
    aux.sort((a, b) => {
      let da = a.Total,
        db = b.Total;
      return da - db;
    });
  } else {
    aux.sort((a, b) => {
      let da = b.Total,
        db = a.Total;
      return da - db;
    });
  }
  return aux;
};

export const sortMinKm = (data, order, service, vehicle) => {
  let aux = [...data];

  aux = filterService(aux, service);
  aux = filterVehicle(aux, vehicle);

  if (order) {
    aux.sort((a, b) => {
      let da = a.MinKm,
        db = b.MinKm;
      return da - db;
    });
  } else {
    aux.sort((a, b) => {
      let da = b.MinKm,
        db = a.MinKm;
      return da - db;
    });
  }
  return aux;
};

export const sortMaxKm = (data, order, service, vehicle) => {
  let aux = [...data];

  aux = filterService(aux, service);
  aux = filterVehicle(aux, vehicle);

  if (order) {
    aux.sort((a, b) => {
      let da = a.MaxKm,
        db = b.MaxKm;
      return da - db;
    });
  } else {
    aux.sort((a, b) => {
      let da = b.MaxKm,
        db = a.MaxKm;
      return da - db;
    });
  }
  return aux;
};

export const translateService = (service) => {
  let text = "";
  if (service === "TYRE_MOBILE") {
    text = "Llantera móvil";
  }
  if (service === "TYRE") {
    text = "Llantera fija";
  }
  if (service === "TOW") {
    text = "Grúa";
  }
  return text;
};

export const translateVehicle = (vehicle) => {
  let text = "";
  if (vehicle === "VAN") {
    text = "Camioneta";
  }
  if (vehicle === "CAR") {
    text = "Carro";
  }
  if (vehicle === "MOTORCYCLE") {
    text = "Motocicleta";
  }
  if (vehicle === "TRUCK") {
    text = "Camión";
  }
  return text;
};
