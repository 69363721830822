import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateFaqs}    from '../../redux/actions/Faqs';
// Components
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
// import DatePickers  from '../global/DatePickers';
// Values
import {StateView, toastText, faqsSelect}  from '../../values';
// Styles
import {useStyles}  from '../../style';
// Api
import {updateData, postData, deleteData, validateData}   from '../../utils/faqs';

const Form = ({data, token, stateView, changeView, updateFaqs}) => {
    const classes = useStyles()
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [answer, setAnswer]       = useState(data.Answer?data.Answer:'')
    const [question, setQuestion]   = useState(data.Question?data.Question:'')
    const [type, setType]           = useState(data.Type?data.Type:'PARTNER')

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateFaqs([])
    }
    const Edit = async() =>{
        const value = edit + 1
        setEdit(value)
        setblockData('')
        if(value===2){
            const validate = validateData(type, question, answer)
            if(validate.flag){
                const res = await updateData(data.Id, token, type, question, answer)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateFaqs([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = validateData(type, question, answer)
            if(validate.flag){
                const res = await postData(token, type, question, answer)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateFaqs([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <Input title={'Pregunta'} value={question} setData={setQuestion} blockData={blockData} />
                            <div style={{display:'flex', alignContent:'center'}}>
                                <p className={classes.title_input}>{'Tipo'}</p>
                                <select style={{cursor:blockData === 'disabled' ? '' : 'pointer'}}
                                    defaultValue={type} 
                                    className={classes.select} 
                                    onChange={e => setType(e.target.value)}
                                    disabled={blockData}>
                                        <option  value={faqsSelect.Value1}>{faqsSelect.Info1}</option>
                                        <option  value={faqsSelect.Value2}>{faqsSelect.Info2}</option>
                                </select>
                            </div>
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Respuesta'} value={answer} setData={setAnswer} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Faqs.faqsEdit,
    token:          state.Panel.token,
})

export default connect(mapStateToProps, {changeView, updateFaqs})(Form);
