import {useState, useEffect}   from 'react';

import {useStyles}  from '../../style';

const MultipleSelect = ({title, def, values, setValue, blockData, height=250}) => {
    const classes                       = useStyles()
    const [check, setCheck]             = useState('none')
    const [allValues, setValues]        = useState([])
    const [textValues, setTextValues]   = useState('')

    const showCheckBoxes = () =>{
        if(check==='none'){
            setCheck('block')
        } else{
            setCheck('none')
        }
    }
    const dataSelect = (e) =>{
        const auxValues = []
        const textAux   = []
        let text        = ""
        allValues.forEach(i =>{
            if(i.Title===e.target.value){
                auxValues.push({Title:i.Title, Value:!i.Value})
            } else{
                auxValues.push(i)
            }
        })
        auxValues.forEach(i =>{
            if(i.Value){
                textAux.push(i.Title)
            }
        })
        textAux.forEach((i,j) =>{
            j===textAux.length-1?
                text += i
            :
                text += `${i}, `
        })
        setTextValues(text)
        setValues([...auxValues])
    }
    const returnData = () =>{
        setCheck('none')
        if(check!=='none'){
            let array = []
            allValues.forEach(i =>{
                if(i.Value){
                    array.push(i.Title)
                }
            })
            setValue(array)
        }
    }
    const standBy = () =>{}
    useEffect(() =>{
        const aux       = []
        const textAux   = []
        let text        = ""
        def.forEach(i =>{
            let flag = false
            values.forEach(j =>{
                if(i===j){
                    flag = true
                    textAux.push(i)
                }
            })
            aux.push({Title:i, Value:flag})
        })
        textAux.forEach((i,j) =>{
            j===textAux.length-1?
                text += i
            :
                text += `${i}, `
        })
        setTextValues(text)
        setValues(aux)
    },[])
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{title}</p>
            <div style={{width:'100%', position:'relative', cursor:blockData==='disabled'?'':'pointer'}} onMouseLeave={() => returnData()}>
                <div style={{position:'relative'}} onClick={blockData==='disabled'?standBy:showCheckBoxes}>
                    <select className={classes.select} style={{fontFamily:'regular'}} disabled={blockData}>
                        <option>{textValues}</option>
                    </select>
                    <div style={{position:'absolute', left:0, top:0, right:0, bottom:0}} />
                </div>
                <div className={classes.container_select} style={{display:check, height:height}}>
                    {
                        allValues.map((item, index) =>(
                            <label key={index} htmlFor={item.Title} className={classes.label_select} onChange={(e) => dataSelect(e)}>
                                <input style={{marginRight:15,fontFamily:'regular'}}
                                    type="checkbox" 
                                    value={item.Title} 
                                    id={item.Title} 
                                    defaultChecked={item.Value} />{item.Title}
                            </label>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default MultipleSelect;