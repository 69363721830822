import { makeStyles } from "@material-ui/core/styles";
import { arrow, Ic_payment_a } from "./values";

export const useStyles = makeStyles((theme) => ({
  home_container_card: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    padding: "25px 0",
    display: "flex",
    alignItems: "center",
  },
  card: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    // minHeight:'80vh',
    position: "relative",
  },
  button: {
    border: "none",
    width: 120,
    cursor: "pointer",
    fontSize: 15,
    // fontWeight:600,
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ic_edit_delete: {
    width: 40,
    marginRight: 10,
    cursor: "pointer",
    "&:active ": {
      transform: "scale(0.95)",
    },
  },
  table_title1: {
    color: "#fff",
    fontSize: 16,
    width: "25%",
    fontFamily: "bold",
  },
  table_text1: {
    fontSize: 14,
    width: "25%",
    fontFamily: "regular",
  },
  table_title2: {
    color: "#fff",
    fontSize: 16,
    width: "20%",
    fontFamily: "bold",
  },
  table_text2: {
    fontSize: 14,
    width: "20%",
    fontFamily: "regular",
  },
  table_title3: {
    color: "#fff",
    fontSize: 16,
    width: "16.66666%",
    fontFamily: "bold",
  },
  table_text3: {
    fontSize: 14,
    width: "16.66666%",
    fontFamily: "regular",
  },
  select: {
    // fontFamily:'regular',
    width: "100%",
    height: 35,
    borderRadius: 0,
    border: "none",
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 30,
    appearance: "none",
    marginTop: 6,
    background: `url(${arrow}) 98% / 12px no-repeat #EBF5F7`,
  },
  label_select: {
    fontFamily: "regular",
    fontSize: 16,
    display: "block",
    cursor: "pointer",
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "#1e90ff",
    },
  },
  container_select: {
    border: "1px solid #dadada",
    position: "absolute",
    width: "100%",
    background: "#EBF5F7",

    overflowY: "auto",
    overflowX: "hidden",
    // height:250,
    // scrollbarWidth:'thin',
    // scrollbarColor:'rgba(205, 205, 205, 0.5) #f0f0f0',
    // '&::-webkit-scrollbar':{
    //     width:6,
    //     background: '#f0f0f0',
    // },
    // '&::-webkit-scrollbar-thumb':{
    //     background: '#cdcdcd',
    // }
  },
  input: {
    width: "100%",
    height: 35,
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: "regular",
    border: "none",
    background: "#EBF5F7",
    // background:'#dde9ee'
  },
  input2: {
    width: "100%",
    height: 35,
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: "regular",
    border: "none",
    background: "#f6f6f6",
  },
  input3: {
    width: "95%",
    height: 35,
    fontSize: 14,
    paddingLeft: 30,
    borderRadius: 50,
    fontFamily: "regular",
    border: "none",
    background: "#fff",
    position: "absolute",
    // boxShadow:'0px 0px 5px -3px rgba(0,0,0,0.75)',
    top: 10,
    zIndex: 2,
  },
  title_input: {
    // color:'#a4a8ba',
    fontWeight: 500,
    width: 150,
    fontSize: 14,
    textTransform: "uppercase",
    fontFamily: "regular",
    minWidth: 140,
  },
  title_input2: {
    // color:'#a4a8ba',
    fontWeight: 500,
    // width:150,
    fontSize: 14,
    textTransform: "uppercase",
    fontFamily: "regular",
    // minWidth:140
    paddingRight: 30,
  },
  input_area: {
    width: "100%",
    height: 150,
    fontSize: 16,
    paddingLeft: 10,
    paddingTop: 10,
    // border:'2px solid #ecedee',
    fontFamily: "regular",
    border: "none",
    background: "#EBF5F7",
    resize: "none",
  },
  confirm2: {
    appearance: "none",
    background: "transparent",
    position: "absolute",
    left: 0,
    height: 35,
    margin: 0,
    width: "100%",
    "&::-webkit-slider-thumb": {
      appearance: "none!important",
      height: 35,
      width: 50,
      border: "none",
      borderRadius: 5,
      cursor: "grab",
      background: "no-repeat center center",
      backgroundColor: "#1eb996",
      backgroundImage: `url(${Ic_payment_a})`,
      "&:active": {
        cursor: "grabbing",
      },
    },
    "&::-moz-range-thumb": {
      height: 35,
      width: 50,
      border: "none",
      borderRadius: 5,
      cursor: "grab",
      background: "no-repeat center center",
      backgroundColor: "#1eb996",
      backgroundImage: `url(${Ic_payment_a})`,
      "&:active": {
        cursor: "grabbing",
      },
    },
  },
  confirm: {
    appearance: "none",
    background: "transparent",
    position: "absolute",
    left: 0,
    height: 35,
    margin: 0,
    width: "100%",
    "&::-webkit-slider-thumb": {
      appearance: "none!important",
      height: 35,
      width: 50,
      border: "none",
      borderRadius: 5,
      cursor: "grab",
      background: "no-repeat center center",
      backgroundColor: "#1eb996",
      backgroundImage: `url(${Ic_payment_a})`,
      "&:active": {
        cursor: "grabbing",
      },
    },
    "&::-moz-range-thumb": {
      height: 35,
      width: 50,
      border: "none",
      borderRadius: 5,
      cursor: "grab",
      background: "no-repeat center center",
      backgroundColor: "#1eb996",
      backgroundImage: `url(${Ic_payment_a})`,
      "&:active": {
        cursor: "grabbing",
      },
    },
  },
  status: {
    position: "relative",
    // background:`linear-gradient(to right,#132327 0%,  #A0BDCA 0%)`,
    border: "none",
    borderRadius: 5,
    height: 35,
    width: 350,
  },
  text1: {
    color: "#ffffffe7",
    position: "absolute",
    width: 250,
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  paper: {
    width: 250,
    height: 60,
    boxShadow: " 0px 0px 5px -3px rgba(0,0,0,0.75)",
    position: "relative",
    borderRadius: 5,
    margin: 10,
    marginLeft: 0,
  },
  paper2: {
    width: 250,
    height: 60,
    boxShadow: " 0px 0px 5px -3px rgba(0,0,0,0.75)",
    position: "relative",
    borderRadius: 5,
    margin: 10,
    marginLeft: 0,
    "&:active": {
      transform: "scale(0.97)",
    },
  },
  image_availability: {
    position: "absolute",
    top: "50%",
    left: 30,
    transform: "translate(0, -50%)",
    width: 50,
    userSelect: "none", //evita seleccionar imagenes
    pointerEvents: "none", //evita arrastrar imagenes
  },
  text_availability: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: 120,
    transform: "translate(0, -50%)",
    userSelect: "none", //evita seleccionar el texto
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  styleButtonMap: {
    background: "#fff",
    width: 30,
    height: 30,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none", //evita seleccionar el texto
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  buttonHome1: {
    border: "1px solid silver",
    cursor: "pointer",
    width: "100%",
    minWidth: 92,
    height: 35,
    borderRadius: 5,
    fontSize: 15,
    background: "#fff",
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  buttonHome2: {
    border: "1px solid silver",
    cursor: "pointer",
    width: "100%",
    minWidth: 92,
    height: 35,
    borderRadius: 5,
    fontSize: 15,
    background: "#1d54b7",
    color: "#fff",
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  buttonHomeCard: {
    cursor: "pointer",
    borderRadius: 50,
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  scrollHomeCard: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "75vh",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  modalScroll: {
    overflowY: "auto",
    overflowX: "hidden",
    height: 500,
    scrollbarWidth: "thin",
    marginRight: 10,
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  button_re_loading: {
    background: "#000",
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    cursor: "pointer",
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  invoice_container: {
    overflowY: "auto",
    overflowX: "hidden",
    height: 500,
    scrollbarWidth: "thin",
    // marginRight:10,
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  card_vehicle: {
    width: 100,
    height: 50,
    // boxShadow:' 0px 0px 5px -3px rgba(0,0,0,0.75)',
    borderRadius: 5,
    margin: 10,
    marginLeft: 0,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    "&:active": {
      transform: "scale(0.97)",
    },
  },
  container_list: {
    overflowY: "auto",
    overflowX: "hidden",
    height: 360,
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  button_service_center: {
    background: "#15398f",
    border: "none",
    borderRadius: 5,
    color: "#fff",
    height: 40,
    width: "100%",
    fontWeight: 600,
    cursor: "pointer",
  },
  button_payment: {
    padding: "0 5px",
    width: 180,
    height: 30,
    cursor: "pointer",
    border: "none",
    borderRadius: 5,
    fontWeight: 600,
    color: "#fff",
    background: "#15398f",
    position: "relative",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
}));
