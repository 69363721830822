import GoogleMapReact from 'google-map-react';

import Button from './Button';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"

const Map = ({ setState, Area, setArea, center = { lat: 20.662926, lng: -103.364936 }, zoom = 11, blockData }) => {
    // const [location, setLocation]   = useState(
    //     [
    //         {lat:20.665355340520176,    lng:-103.43662579167939},
    //         {lat:20.666198573601374,    lng:-103.43169052709199},
    //         {lat:20.66133988068287,     lng:-103.43093950856782},
    //         {lat:20.660878095997386,    lng:-103.43615372289277},
    //     ]
    // )
    const setHandleGoogleMapApi = (google) => {
        new google.maps.Polygon({
            map: google.map,
            paths: Area,
            strokeColor: '#000000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#000000',
            fillOpacity: 0.35,
            draggable: false,
            editable: false,
            geodesic: false
        });
    }
    const CleanData = () => {
        setState(true)
        setArea([])
    }
    return (
        <div style={{ width: '100%', height: 430, paddingBottom: 70 }}>
            <GoogleMapReact
                bootstrapURLKeys={{ libraries: 'drawing', key: keyMap }}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={setHandleGoogleMapApi} />
            <div style={{ height: 10 }} />
            {
                blockData !== 'disabled' && <Button text={'Limpiar'} onClick={CleanData} height={35} />
            }
        </div>
    );
};

export default Map;