import GoogleMapReact from 'google-map-react';

import {Ic_marker, Ic_marker_selected} from '../../values';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"

const Marker = ({image=Ic_marker, zIndex=0}) => 
<>
    {/* <img alt="" src={image} style={{width:30, height:30, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -65%)', zIndex:zIndex}} /> */}
    <div style={{width:30, height:30, background:'red', position:'absolute', borderRadius:50, top:'50%', left:'50%', transform:'translate(-50%, -50%)'}} />
</>;

const Map = ({center = {lat:20.662926, lng:-103.364936}, zoom = 11, style, position={lat:20.662926, lng:-103.364936}, marker=false, partner}) => {
    return (
        <div style={{width:'100%', height:'calc(100vh - 120px)'}}>
            <GoogleMapReact
                bootstrapURLKeys = {{ key:keyMap}}
                // tracksViewChanges={true}
                center = { center } 
                zoom  = { zoom } 
                options={{
                    styles: style,
                    fullscreenControl:false,
                    zoomControl:false,
                }}>
                    { marker && <Marker lat={ position.lat } lng={ position.lng } image={Ic_marker_selected} zIndex={9} /> }
                    {
                        partner.map((item, index) =>(
                            <Marker key={index} lat={ item.lat } lng={ item.lng } />
                        ))
                    }
                </GoogleMapReact>
        </div>
    );
};

export default Map;