    import {useStyles}  from '../../style';

const Input = ({title, value, blockData, setData, type='text', min = 0, max = 999999999999999999999, }) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{title}</p>
            {
                type === 'number'?
                    <input type={type}
                        style={{WebkitAppearance:'textfield'}}
                        step="any"
                        min={min}
                        max={max}
                        onChange={e => setData(e.target.value)}
                        value={value} 
                        className={classes.input}
                        disabled={blockData} />
                :
                    <input type={type}
                        onChange={e => setData(e.target.value)}
                        defaultValue={value} 
                        className={classes.input}
                        disabled={blockData} />
            }
        </div>
    );
};

export default Input;