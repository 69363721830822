import {stateData, reduxState} from '../../values';

const initialState = {
    cities:         [],
    status:         stateData.state1,
    citiesEdit:     [],
    records:        1
};

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.get_cities){
        return {
            ...state,
            cities:     action.payload,
            status:     action.status,
            records:    action.records
        }
    }
    if(action.type === reduxState.update_cities){
        return {
            ...state,
            citiesEdit: action.payload
        }
    }
    return state
}
export default reducer
