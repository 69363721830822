import { useEffect } from 'react';
import { connect } from 'react-redux';

// Redux
import {getAdmins}  from '../../redux/actions/Admins';

// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';

// Values
import {StateView}  from '../../values';

// Api
import {getData}    from '../../utils/admins';

const Index = ({stateView, token, getAdmins}) => {
    const GetData = async() =>{
        const res = await getData(token)
        getAdmins(res.data)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Admins' />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    token:          state.Panel.token
})

export default connect(mapStateToProps, {getAdmins})(Index);
