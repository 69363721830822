import { useState, useEffect, Fragment } from "react";
import GoogleMapReact from "google-map-react";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import queryString from "query-string"; //https://ui.dev/react-router-v5-query-strings/
import { toast } from "react-toastify";

// Components
import SearchBox from "./SearchBox";
import InputWhatsapp from "./InputWhatsapp";
// import Extra from "./Extra";

// Values
import {
  Ic_search,
  Ic_marker2,
  Ic_marker_origin,
  Ic_marker_destiny,
  Ic_set_destiny,
  Ic_set_origin,
} from "../../values";
// Styles
import { useStyles } from "../../style";

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y";

const depositList = [
  { pos: 1, text: "Depósito Vehicular 2", value: { lat: 20.70863, lng: -103.302264 } },
  { pos: 2, text: "Depósito Vehicular 3", value: { lat: 20.657347, lng: -103.225193 } },
  { pos: 3, text: "Depósito Vehicular 7", value: { lat: 20.668176, lng: -103.316583 } },
  { pos: 4, text: "Depósito Vehicular 8", value: { lat: 20.72417, lng: -103.30492 } },
];

const Marker = ({ display, ic_marker, service }) => (
  <Fragment>
    <img
      alt=""
      src={ic_marker}
      style={{
        width: 40,
        height: 40,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        display: display || service !== 2 ? "none" : "block",
      }}
    />
  </Fragment>
);

const Map = ({ service, setOrigen, setDestiny, setCheck }) => {
  const classes = useStyles();

  const [center, setCenter] = useState({ lat: 20.662926, lng: -103.364936 });
  const [zoom, setZoom] = useState(11);
  const [whatsapp, setWhatsapp] = useState("");

  const [marker1, setMarker1] = useState({ lat: "", lng: "" });
  const [marker2, setMarker2] = useState({ lat: "", lng: "" });
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(true);

  let check3 = false;
  let check4 = false;

  const [auxSelect, setAuxSelect] = useState(0);
  const [auxPosition, setAuxPosition] = useState({ lat: 20.662926, lng: -103.364936 });

  // const zoomData = (data) =>{
  //     setZoom(()=>data)
  // }

  const getLocationWhatsapp = () => {
    console.log("getLocationWhatsapp");
    const values = queryString.parse(whatsapp);
    let auxLocation = "";
    let auxLat = 0;
    let auxLng = 0;

    Object.keys(values).map((key, index) => {
      if (index === 1) {
        auxLocation = values[key];
      }
    });
    auxLocation = auxLocation.split(",");
    console.log("auxLocation: ", auxLocation);

    auxLat = parseFloat(auxLocation[0]);
    auxLng = parseFloat(auxLocation[1]);
    if (typeof auxLat === "number" && typeof auxLng === "number") {
      console.log("Ok");
      setZoom(parseFloat(values.z));
      setCenter({ lat: auxLat, lng: auxLng });
    } else {
      toast.warn("Error");
    }
  };

  const getName = async (center, setAddres) => {
    await geocodeByLatLng({ lat: center.lat, lng: center.lng }).then((results) => {
      if (results) {
        setAddres(results[0].formatted_address);
      }
    });
  };

  const onMove = (data) => {
    setZoom(() => data.zoom);
    setCenter(() => data.center);
    if (check1) {
      getName(center, setAddress1);
      setMarker1(() => data.center);
      setOrigen(() => data.center);
    }
    if (check2) {
      getName(center, setAddress2);
      setMarker2(() => data.center);
      setDestiny(() => data.center);
    }
  };

  const changeLocation = (position) => {
    if (check3) {
      setMarker1(() => position);
    }
    if (check4) {
      setMarker2(() => position);
    }
    setCenter(() => position);
  };

  const setData = (opt) => {
    if (opt === 1) {
      if (check1 === false) {
        getName(center, setAddress1);
        setMarker1(() => center);
        setOrigen(() => center);
      }
      setCheck1(() => false);
      setCheck(() => false);
    }
    if (opt === 2) {
      if (check1 === false) {
        getName(center, setAddress2);
        setMarker2(() => center);
        setDestiny(() => center);
      }
      setCheck2(() => false);
    }
  };

  const handleSelect = (pos) => {
    if (pos) {
      setAuxPosition(depositList[parseFloat(pos)].value);
      setAuxSelect(pos);
    } else {
      setAuxSelect(0);
    }
  };

  const handleVehicleDeposit = () => {
    setCenter(auxPosition);
    setMarker2(auxPosition);
    setZoom(16);
    setData(2);
  };

  useEffect(() => {
    if (check1 === false) {
      check3 = check1;
    }
    if (check2 === false) {
      check4 = check1;
    }
  }, [check1, check2]);
  return (
    <div style={{ width: "100%", paddingBottom: 70, position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img alt="" src={Ic_search} style={{ position: "absolute", zIndex: 3, left: 25, top: 20 }} />
        <SearchBox setCenter={changeLocation} setZoom={setZoom} />
      </div>
      <div style={{ width: "100%", height: 430, position: "relative" }}>
        <InputWhatsapp value={whatsapp} setValue={setWhatsapp} onAction={getLocationWhatsapp} />
        <GoogleMapReact
          bootstrapURLKeys={{ libraries: "drawing", key: keyMap }}
          options={{ fullscreenControl: false, zoomControl: false }}
          center={center}
          zoom={zoom}
          // onZoomAnimationEnd={zoomData}
          onChange={onMove}
          // yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={setHandleGoogleMapApi}
        >
          <Marker lat={marker1.lat} lng={marker1.lng} display={check1} ic_marker={Ic_marker_origin} service={2} />
          <Marker
            lat={marker2.lat}
            lng={marker2.lng}
            display={check2}
            ic_marker={Ic_marker_destiny}
            service={service}
          />
        </GoogleMapReact>
        <img
          alt=""
          src={Ic_marker2}
          style={{
            width: 40,
            height: 40,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -100%)",
          }}
        />
      </div>
      <div style={{ height: 106 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ userSelect: "none", width: 70 }}>{"Origen"}</p>
          <input className={classes.input2} value={check1 ? "" : address1} disabled="disabled" />
          <img alt="" onClick={() => setData(1)} src={Ic_set_origin} style={{ cursor: "pointer" }} />
        </div>

        <div style={{ display: service === 2 ? "flex" : "none", alignItems: "center" }}>
          <p style={{ userSelect: "none", width: 70 }}>{"Destino"}</p>
          <select className={classes.input2} value={auxSelect} onChange={(e) => handleSelect(e.target.value)}>
            <option value={0}></option>
            {depositList.map((i, j) => (
              <option key={`deposit-list-${j}`} value={i.pos}>
                {i.text}
              </option>
            ))}
          </select>
          <img alt="" onClick={handleVehicleDeposit} src={Ic_set_destiny} style={{ cursor: "pointer" }} />
          {/* <input className={classes.input2} value={check2 ? "" : address2} disabled="disabled" />
          <img alt="" onClick={() => setData(2)} src={Ic_set_destiny} style={{ cursor: "pointer" }} /> */}
        </div>
      </div>
      {/* <Extra /> */}
    </div>
  );
};

export default Map;
