import {useStyles} from '../../style';

const InputArea = ({title, value, blockData, setData, type='text'}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', marginTop:5}}>
            <p className={classes.title_input}>{title}</p>
            <textarea type={type}
                // placeholder={title} 
                onChange={e => setData(e.target.value)}
                defaultValue={value} 
                className={classes.input_area}
                disabled={blockData} />
        </div>
    );
};

export default InputArea;