import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { arrow } from "../../values";
const useStyles = makeStyles((theme) => ({
  btn_select: {
    width: "100%",
    height: 50,
    position: "relative",
    border: "none",
    backgroundColor: "#fff",
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  picture: {
    width: 40,
    borderRadius: "100%",
    height: 40,
    marginRight: 20,
    objectFit: "cover",
  },
  options: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    listStyle: "none",
    cursor: "pointer",
    display: "flex",
    "&:hover": {
      background: "#ddd",
    },
    // '& span': {
    //     marginLeft: 20
    // }
  },
  icon_arrow: {
    position: "absolute",
    right: 10,
    top: "40%",
  },
  dropdown: {
    position: "absolute",
    background: "#f1f1f1",
    padding: 0,
    // minWidth: 160,
    width: "100%",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    top: 30,
    maxHeight: 150,

    overflowY: "auto",
    overflowX: "hidden",

    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
}));

const Options = ({ picture, name, city, onAction }) => {
  const classes = useStyles();
  return (
    <li className={classes.options} onClick={onAction}>
      <img alt="" src={picture} className={classes.picture} />
      <span style={{ fontWeight: 600, marginRight: 15 }}>{name}</span>
      <span>{city}</span>
    </li>
  );
};

const SelectBranches = ({ data, list, setNewBranches }) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const [picture, setPicture] = useState(data.PlacePicture ? data.PlacePicture : "");
  const [placeName, setPlaceName] = useState(data.PlaceName ? data.PlaceName : "");

  const handleSelect = (newValue) => {
    setPicture(newValue.Picture);
    setPlaceName(newValue.Name);

    setNewBranches(newValue);
  };
  return (
    <div style={{ position: "relative", display: "inline-block", width: "100%" }} onMouseLeave={() => setActive(false)}>
      <button className={classes.btn_select} onClick={() => setActive(!active)}>
        <img alt="" src={picture} className={classes.picture} />
        <span style={{ fontWeight: 600, marginRight: 15 }}>{placeName}</span>
        <span></span>
        <img alt="arrow" src={arrow} className={classes.icon_arrow} style={{ objectFit: "cover" }} />
      </button>
      <ul className={classes.dropdown} style={{ display: active ? "block" : "none" }}>
        {list &&
          list.map((i, j) => (
            <Options
              key={`select-branches-${j}`}
              picture={i.Picture}
              name={i.Name}
              city={i.City}
              onAction={() => handleSelect(i)}
            />
          ))}
      </ul>
    </div>
  );
};

export default SelectBranches;
