import { useState, useEffect } from "react";
import { Modal, Container, Grid } from "@material-ui/core";

import { useStyles } from "../../style";

const ModalHome = ({ open, setOpen, data }) => {
  const classes = useStyles();
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    // console.log("MODAL USEEFFECT");
    let array = [];
    for (let i in data) {
      const obj = {
        Var: i,
        Value: data[i],
      };
      // console.log("TYPE: ", i, "-->", data[i], "-->", typeof data[i]);
      // console.log("Var: ", i, " Value: ", data[i]);
      if (typeof data[i] === "object") {
        // console.log("Var: ", i, " Value: ", data[i]);
        let stringText = "";
        for (let j in data[i]) {
          stringText += data[i][j] + ", ";
          // console.log("D: ", data[i][j]);
        }

        array.push({
          Var: i,
          Value: stringText,
        });
      }
      if (typeof data[i] !== "object") {
        array.push(obj);
      }
    }
    // console.log(array);
    setJsonData(array);
  }, [data]);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Container maxWidth="lg">
        <div style={{ background: "#fff", borderRadius: 5, height: 600 }}>
          <p style={{ fontWeight: 600, fontSize: 18, paddingTop: 20, marginLeft: 20 }}>{"Socio - Información"}</p>
          <div className={classes.modalScroll}>
            <Container>
              {jsonData.map((item, index) => (
                <div key={index} style={{ borderBottom: "1px solid silver" }}>
                  <Grid container>
                    <Grid item xs={3}>
                      <label>{item.Var}</label>
                    </Grid>
                    <Grid item xs={9}>
                      <label>{item.Value}</label>
                    </Grid>
                  </Grid>
                  {/* <hr/> */}
                </div>
              ))}
            </Container>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalHome;
