import { useState, useEffect } from "react";
import {
  Container,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
// Redux
import { changeView } from "../../redux/actions/Menu";
import { getFares, updateFares } from "../../redux/actions/Fares";
// Values
import { Ic_edit, StateView, stateData } from "../../values";
// Componetns
import LoadingData from "../global/LoadingData";
import Pagination from "../global/ButtonsPagination";
// Styles
import { useStyles } from "../../style";
// Api
import { getData } from "../../utils/fares";
import { formatMoney } from "../../utils";
// Values
import { Ic_sort_white, Ic_sort_gray, listServiceType, listVehiclesType } from "../../values";

import { sortService, sortVehicle, sortMinKm, sortMaxKm, sortTotal, translateService, translateVehicle } from "./index";

const Item = ({ title, status, option, order, setOrder, setOption }) => {
  const handleAction = () => {
    if (status === option) {
      setOrder(!order);
    } else {
      setOrder(false);
    }
    setOption(status);
  };
  return (
    <div onClick={handleAction} style={{ cursor: "pointer" }}>
      {status === option ? (
        <img
          alt=""
          src={Ic_sort_white}
          style={{
            marginRight: 10,
            width: 13,
            transform: order ? "rotate(0deg)" : "rotate(180deg)",
            transitionDuration: "0.3s",
          }}
        />
      ) : (
        <img
          alt=""
          src={Ic_sort_gray}
          style={{ marginRight: 10, width: 13, transform: "rotate(0deg)", transitionDuration: "0.3s" }}
        />
      )}
      <span>{title}</span>
    </div>
  );
};

const Select = ({ value, setValue, list }) => {
  return (
    <select
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{ padding: "7px 0", width: 130, background: "#313f42", border: "none", color: "#fff", paddingRight: 20 }}
    >
      {list &&
        list.map((i) => (
          <option key={`select-${i.value}`} value={i.value}>
            {i.text}
          </option>
        ))}
    </select>
  );
};

const Space = () => {
  return <div style={{ height: 29 }} />;
};

const Tables = ({ data, status, token, records, changeView, getFares, updateFares }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [option, setOption] = useState(0);
  const [order, setOrder] = useState(false);

  const [fares, setFares] = useState([]);
  const [service, setService] = useState(listServiceType[0].value);
  const [vehicle, setVehicle] = useState(listVehiclesType[0].value);

  const edit = (item) => {
    changeView(StateView.state3);
    updateFares(item);
  };
  const GetData = async (page) => {
    const res = await getData(token, `?Page=${page}`);
    getFares(res.data);
    window.scrollTo(0, 0);
  };
  const changePage = (e, v) => {
    setPage(v);
    GetData(v);
  };

  useEffect(() => {
    if (option === 0) {
      const res = sortService(data, order, service, vehicle);
      setFares(res);
    }
    if (option === 1) {
      const res = sortVehicle(data, order, service, vehicle);
      setFares(res);
    }
    if (option === 2) {
      const res = sortTotal(data, order, service, vehicle);
      setFares(res);
    }
    if (option === 3) {
      const res = sortMinKm(data, order, service, vehicle);
      setFares(res);
    }
    if (option === 4) {
      const res = sortMaxKm(data, order, service, vehicle);
      setFares(res);
    }
  }, [order, option, service, vehicle]);

  useEffect(() => {
    if (data) {
      setFares(data);
    }
  }, [data]);

  return (
    <div style={{ position: "relative" }}>
      {status === stateData.state2 ? (
        <Container maxWidth="xl">
          <TableContainer style={{ background: "#fff" }}>
            <Table size="small">
              <TableHead>
                <TableRow style={{ background: "#132327" }}>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    <Item
                      title="Servicio"
                      status={0}
                      option={option}
                      order={order}
                      setOrder={setOrder}
                      setOption={setOption}
                    />
                    <Select value={service} setValue={setService} list={listServiceType} />
                  </TableCell>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    <Item
                      title="Vehículos"
                      status={1}
                      option={option}
                      order={order}
                      setOrder={setOrder}
                      setOption={setOption}
                    />
                    <Select value={vehicle} setValue={setVehicle} list={listVehiclesType} />
                  </TableCell>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    <Item
                      title="Costo"
                      status={2}
                      option={option}
                      order={order}
                      setOrder={setOrder}
                      setOption={setOption}
                    />
                    <Space />
                  </TableCell>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    <Item
                      title="Km Min"
                      status={3}
                      option={option}
                      order={order}
                      setOrder={setOrder}
                      setOption={setOption}
                    />
                    <Space />
                  </TableCell>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    <Item
                      title="Km Max"
                      status={4}
                      option={option}
                      order={order}
                      setOrder={setOrder}
                      setOption={setOption}
                    />
                    <Space />
                  </TableCell>
                  <TableCell align="center" style={{ color: "#fff", fontFamily: "bold" }}>
                    Editar
                    <Space />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fares &&
                  fares.map((item, index) => (
                    <TableRow key={index} style={{ borderBottom: `1px solid #f3f4f7` }}>
                      <TableCell align="center" className={classes.table_text3} style={{ fontFamily: "regular" }}>
                        {translateService(item.Service)}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text3} style={{ fontFamily: "regular" }}>
                        {translateVehicle(item.Vehicle)}
                      </TableCell>
                      {/* <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}><span style={{fontWeight:700}}>$ {formatMoney(item.Amount)} MXN</span></TableCell> */}
                      <TableCell align="center" className={classes.table_text3} style={{ fontFamily: "regular" }}>
                        <span style={{ fontWeight: 700 }}>$ {formatMoney(item.Total)} MXN</span>
                      </TableCell>
                      <TableCell align="center" className={classes.table_text3} style={{ fontFamily: "regular" }}>
                        {item.MinKm}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text3} style={{ fontFamily: "regular" }}>
                        {item.MaxKm}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text3}>
                        <IconButton onClick={() => edit(item)}>
                          <img alt="ic_edit" src={Ic_edit} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Pagination records={records} page={page} changePage={changePage} />     */}
        </Container>
      ) : (
        <LoadingData />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.Fares.fares,
  status: state.Fares.status,
  token: state.Panel.token,
  records: state.Fares.records,
});

export default connect(mapStateToProps, { changeView, getFares, updateFares })(Tables);
