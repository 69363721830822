import { emailformat } from "../index";
import { urlApi, urlPath, get, post, update, deleteRegister } from "../../values/api";
const url = urlApi + urlPath.admins;

export const getData = async (token, extra = "") => {
  return await get(url, token, extra);
};

export const postData = async (token, name, surname, email, image, password1, roles) => {
  const obj = {
    Name: name,
    Surname: surname,
    Email: email,
    Picture: image,
    Roles: roles,
    Password: password1,
  };
  return await post(obj, url, token);
};

export const updateData = async (id, token, name, surname, image, roles) => {
  const obj = {
    Name: name,
    Surname: surname,
    Picture: image,
    Roles: roles,
  };
  return await update(obj, url, id, token);
};

export const deleteData = async (id, token) => {
  return deleteRegister(url, id, token);
};

export const postValidate = (image, name, surname, email, password1, password2) => {
  if (!name) {
    return { flag: false, message: "Parece que algo anda mal con tu nombre." };
  }
  if (!surname) {
    return { flag: false, message: "Parece que algo anda mal con tu apellido." };
  }
  if (!email.match(emailformat)) {
    return { flag: false, message: "Parece que algo anda mal con tu correo." };
  }
  if (!password1) {
    return { flag: false, message: "Parece que algo anda mal con tu contraseña." };
  }
  if (!password2) {
    return { flag: false, message: "Parece que algo anda mal con tu contraseña." };
  }
  if (password1 !== password2) {
    return { flag: false, message: "Tus contraseñas deben de coincidir." };
  }
  if (password1.length < 7 || password2.length < 7) {
    return { flag: false, message: "Tu contraseña tiene que tener mas de 7 caracteres." };
  }
  if (!image) {
    return { flag: false, message: "Parece que algo anda mal con tu fotografia." };
  }
  return { flag: true, message: "" };
};

export const updateValidate = (name, surname) => {
  if (!name) {
    return { flag: false, message: "Parece que algo anda mal con tu nombre." };
  }
  if (!surname) {
    return { flag: false, message: "Parece que algo anda mal con tu apellido." };
  }
  return { flag: true, message: "" };
};
