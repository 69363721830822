import { useState, useEffect, Fragment } from "react";
import { Collapse } from "@material-ui/core";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import { useStyles } from "../../style";

// Styles map
import Style1 from "../global/StyleMap1.json";
import Style2 from "../global/StyleMap2.json";
// Component
import Map from "../global/MapHome";
import Modal from "./Modal";
// Values
import { Ic_options, Ic_location } from "../../values";
import { formatDate } from "../../utils";
// RealTime
import { getPartnerConnected, changeStatus } from "../../utils/home";

const Button = ({ actionButton, statusButton, text, value, display = "none" }) => {
  const classes = useStyles();
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        style={{
          width: 15,
          height: 15,
          borderRadius: 50,
          background: "#b5015a",
          position: "absolute",
          right: 5,
          top: -5,
          zIndex: 9,
          display: display,
        }}
      />
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <button
          onClick={() => actionButton(value)}
          className={statusButton === value ? classes.buttonHome2 : classes.buttonHome1}
        >
          {text}
        </button>
      </div>
    </div>
  );
};

const ButtonPayment = ({ status, id }) => {
  const classes = useStyles();

  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePay = async () => {
    if (window.confirm("¿Confirmas que el pago se ha resibido?")) {
      setLoading(true);
      const res = await changeStatus(`/${id}`);
      if (res.flag) {
        toast.success("Se cambio el estado del servicio");
        setState(true);
      } else {
        toast.warn("Ocurrio un error vuelve a intentarlo");
      }
      setLoading(false);
    }
  };
  return (
    <Fragment>
      {status === "QUOTATION" && !state && (
        <button onClick={handlePay} className={classes.button_payment}>
          {!loading ? (
            "Marcar como pagado"
          ) : (
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              <ReactLoading type={"bars"} color={"white"} style={{ fill: "white", height: 35, width: 35 }} />
            </div>
          )}
        </button>
      )}
    </Fragment>
  );
};

const Card = ({ setOpen, data, position }) => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: 10, marginRight: 10 }}>
      <div style={{ background: "#fff", width: "100%", borderRadius: 5 }}>
        <div style={{ padding: 10 }}>
          <div style={{ display: "flex" }}>
            <img
              alt=""
              src={data.PictureProfile}
              style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 5, marginRight: 20 }}
            />
            <div>
              <p style={{ margin: 0, fontWeight: 600 }}>{data.Name}</p>
              <p style={{ margin: 0 }}>{data.Email}</p>
              <p style={{ margin: 0 }}>{data.Phone}</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <p style={{ margin: 0, fontWeight: 600 }}>{data.Place === "VEHICLE" ? "Vehiculo" : "Sucursal"}</p>
              <p style={{ margin: 0 }}>{data.PlaceName}</p>
              {/* <p>{data.Status}</p> */}
              <ButtonPayment status={data.StatusService} id={data.ServiceId} />
              {/* {data.StatusService && <p style={{ margin: 0 }}>{data.StatusService}</p>} */}
            </div>
            <div>
              <img
                alt=""
                src={Ic_options}
                onClick={() => setOpen(data)}
                className={classes.buttonHomeCard}
                style={{ marginRight: 10 }}
              />

              <img
                alt=""
                src={Ic_location}
                onClick={() =>
                  data.Place === "VEHICLE"
                    ? position(data.Position.Lat, data.Position.Lng)
                    : position(data.PlacePosition.Lat, data.PlacePosition.Lng)
                }
                className={classes.buttonHomeCard}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState([]);

  const [style, setStyle] = useState(Style1);
  const [statusStyle, setStatusStyle] = useState(false);
  const [statusButton, setStatusButton] = useState(0);

  const [position, setPosition] = useState({});
  const [center, setCenter] = useState({ lat: 20.662926, lng: -103.364936 });
  const [marker, setMarker] = useState(false);
  const [zoom, setZoom] = useState(11);

  const [partnerData, setPartnerData] = useState([]);
  const [partnerService, setPartnerService] = useState([]);
  const [partnerLocation, setPartnerLocation] = useState([]);
  const [serviceError, setServiceError] = useState([]);
  const [openArray, setOpenArray] = useState([]);

  const changeStyle = () => {
    if (statusStyle) {
      setStyle(Style1);
      setStatusStyle(false);
    } else {
      setStyle(Style2);
      setStatusStyle(true);
    }
  };

  const actionButton = (status) => {
    setStatusButton(status);
  };

  const newPosition = (lat, lng) => {
    // console.log("LAT: ", lat);
    // console.log("LNG: ", lng);
    setMarker(true);
    setZoom(16);
    setPosition({ lat: lat, lng: lng });
    setCenter({ lat: lat, lng: lng });
  };

  const modalOpenData = (data) => {
    setOpenData(data);
    setOpen(!open);
  };
  const openDataErrors = (pos) => {
    let array = [];
    openArray.forEach((i, index) => {
      if (pos === index) {
        array.push(true);
      } else {
        array.push(false);
      }
    });
    setOpenArray(array);
  };

  useEffect(() => {
    getPartnerConnected(setPartnerData, setPartnerLocation, setPartnerService, setServiceError, setOpenArray);
  }, []);
  return (
    <div>
      <Modal open={open} setOpen={setOpen} data={openData} />
      <div style={{ display: "flex" }}>
        <div style={{ width: "29%" }}>
          <div
            style={{
              width: "100%",
              background: "#fff",
              borderRadius: 5,
              height: 55,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              text={partnerData.length !== 0 ? `(${partnerData.length}) Socio` : "Socio"}
              value={0}
              actionButton={actionButton}
              statusButton={statusButton}
            />
            <Button
              text={partnerService.length !== 0 ? `(${partnerService.length}) Servicios` : "Servicios"}
              value={1}
              actionButton={actionButton}
              statusButton={statusButton}
              display={partnerService.length !== 0 ? "block" : "none"}
            />
            <Button
              text={"Excepciones"}
              value={2}
              actionButton={actionButton}
              statusButton={statusButton}
              display={"none"}
            />
          </div>
          <h3>{"Conectados"}</h3>
          <div className={classes.scrollHomeCard} style={{ display: statusButton === 0 ? "block" : "none" }}>
            {partnerData.map((item, index) => (
              <div key={`partner-service-${index}`}>
                <Card setOpen={modalOpenData} data={item} position={newPosition} />
              </div>
            ))}
          </div>

          <div className={classes.scrollHomeCard} style={{ display: statusButton === 1 ? "block" : "none" }}>
            {partnerService.map((item, index) => (
              <div key={index}>
                <Card setOpen={modalOpenData} data={item} position={newPosition} />
              </div>
            ))}
          </div>
          <div className={classes.scrollHomeCard} style={{ display: statusButton === 2 ? "block" : "none" }}>
            {serviceError.map((item, index) => (
              <div key={index}>
                <div style={{ marginBottom: 10, marginRight: 10 }}>
                  <div style={{ background: "#fff", width: "100%", borderRadius: 5 }}>
                    <div style={{ padding: 10, cursor: "pointer" }} onClick={() => openDataErrors(index)}>
                      <p style={{ margin: 0 }}>
                        <span style={{ fontWeight: 700 }}>{"Codigo: "}</span>
                        {item.Code}
                      </p>
                      <p style={{ margin: 0 }}>
                        <span style={{ fontWeight: 700 }}>{"Mensaje: "}</span>
                        {item.Message}
                      </p>
                      <p style={{ margin: 0 }}>
                        <span style={{ fontWeight: 700 }}>{"Fecha: "}</span>
                        {formatDate(item.Date)}
                      </p>

                      <Collapse in={openArray[index]} timeout="auto" unmountOnExit>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Id: "}</span>
                          {item.Id}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Id de la ciudad: "}</span>
                          {item.CityId}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Tipo de servicio: "}</span>
                          {item.ServiceType}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Nombre del usuario: "}</span>
                          {item.UserName}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Id del usuario: "}</span>
                          {item.UserId}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Teléfono del usuario: "}</span>
                          {item.UserId}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Teléfono del usuario: "}</span>
                          {item.UserPhone}
                        </p>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: 700 }}>{"Token FCM: "}</span>
                          {item.UserTokenFCM}
                        </p>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "1%" }} />
        <div style={{ width: "70%", position: "relative" }}>
          <div style={{ position: "absolute", right: 10, top: 10, zIndex: 10 }}>
            <div className={classes.styleButtonMap} onClick={changeStyle}>
              <label style={{ cursor: "pointer" }}>{"Aa"}</label>
            </div>
          </div>
          <Map
            style={style}
            center={center}
            zoom={zoom}
            position={position}
            marker={marker}
            partner={partnerLocation}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
