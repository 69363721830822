import {useState}   from 'react';
import {Container}  from '@material-ui/core';
import { connect }  from 'react-redux';
import { toast }        from 'react-toastify';

// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateCities}   from '../../redux/actions/Cities';
// Components
import Input        from '../global/Input';
import ButtonsForm  from '../global/ButtonsForm';
import InputArea    from '../global/InputArea';
import MapSelect    from '../global/MapSelect';
import MapView      from '../global/MapView';
// Values
import {StateView, toastText}  from '../../values';
// Api
import {validateData, updateData, postData, deleteData} from '../../utils/cities';

const Form = ({stateView, changeView, data, token, updateCities}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [stateMap, setStateMap]   = useState(stateView===StateView.state2?true:false)
    const [city, setCity]           = useState(data.City?data.City:'')
    const [country, setCountry]     = useState(data.Country?data.Country:'')
    const [state, setState]         = useState(data.State?data.State:'')
    const [area, setArea]           = useState(data.Area?data.Area:[])

    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateCities([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setblockData('')
        setEdit(value)
        if(value===2){
            const validate = validateData(city, state, country, area)
            if(validate.flag){
                const res = await updateData(data.Id, token, city, state, country, area)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateCities([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = validateData(city, state, country, area)
            if(validate.flag){
                const res = await postData(token, city, state, country, area)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateCities([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }

    const NewLocation = (value) =>{
        const array = []
        value.forEach(item =>{
            const obj = {
                lat:    item.lat,
                lng:    item.lng,
            }
            array.push(obj)
        })
        setArea(array)
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            {
                                stateMap?
                                    <MapSelect setLocation={NewLocation} />
                                :
                                    <MapView setState={setStateMap} Area={area} setArea={setArea} blockData={blockData} />
                            }
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Ciudad'} value={city} setData={setCity} blockData={blockData} />
                            <Input title={'Estado'} value={state} setData={setState} blockData={blockData} />
                            <Input title={'País'} value={country} setData={setCountry} blockData={blockData} />
                            <InputArea title={'Area'} value={
                                area && area.map((i, index) => (
                                    index===0 ? "Lat: "+i.lat+", Lng: "+i.lng : " Lat: "+i.lat+", Lng: "+i.lng
                                ))} 
                                setData={setArea} 
                                blockData={'disabled'}/>
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Cities.citiesEdit,
    token:          state.Panel.token,
})
export default connect(mapStateToProps, {changeView, updateCities})(Form);
