import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Ic_picture } from "../../values";

export const useStyles = makeStyles((theme) => ({
  drag_image_default: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 70,
  },
  drag_image_selected: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
  },
}));

const DragImage = ({ image, blockData, setImage, option = "", height = 250, text = "" }) => {
  const classes = useStyles();
  const [img, setImg] = useState(image);

  const handleImage = (imag) => {
    const url = window.URL.createObjectURL(imag.files[0]);
    setImg(url);
    setImage(imag.files[0]);
  };

  useEffect(() => {
    if (image) {
      if (typeof image === "object") {
        const url = window.URL.createObjectURL(image);
        setImg(url);
      } else {
        setImg(image);
      }
    } else {
      setImg("");
    }
  }, [image]);

  return (
    <div>
      <label
        htmlFor={blockData === "disabled" ? "" : `file-upload${option}`}
        style={{ cursor: blockData === "disabled" ? "default" : "pointer", position: "relative" }}
      >
        <p style={{ position: "absolute", top: 10, left: 10, zIndex: 999, width: 150 }}>{text}</p>
        <div style={{ width: "100%", height: height, background: "#ebf5f7", position: "relative" }}>
          <img
            alt="drag_image"
            src={img ? img : Ic_picture}
            className={img ? classes.drag_image_selected : classes.drag_image_default}
          />
        </div>
      </label>

      <input
        id={`file-upload${option}`}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => handleImage(e.target)}
      />
    </div>
  );
};

export default DragImage;
