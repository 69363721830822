import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';
import firebase2 from 'firebase/app';
import {routesApi, urlApi} from '../values/api';

let firebaseConfig = {}

if(urlApi===routesApi.local_sandbox || urlApi===routesApi.local_http || urlApi===routesApi.sandbox){
    firebaseConfig = {
        apiKey: "AIzaSyC8flo4K5QyEtZkPnLi_Jld3zBdv98_FOk",
        authDomain: "llaoz-sandbox.firebaseapp.com",
        databaseURL: "https://llaoz-sandbox.firebaseio.com",
        projectId: "llaoz-sandbox",
        storageBucket: "llaoz-sandbox.appspot.com",
        messagingSenderId: "719046027943",
        appId: "1:719046027943:web:960b2e16edd5fd43054982",
        measurementId: "G-BDZC8VJ0QF"
    }
} else{
    firebaseConfig = {
        apiKey: "AIzaSyDwl4i8ZnmhOOCfUsnERDG25Y3XcTxCITU",
        authDomain: "llaoz-c120d.firebaseapp.com",
        databaseURL: "https://llaoz-c120d.firebaseio.com",
        projectId: "llaoz-c120d",
        storageBucket: "llaoz-c120d.appspot.com",
        messagingSenderId: "1067566268881",
        appId: "1:1067566268881:web:ecc6ad1dc6f554317460f0",
        measurementId: "G-FCT2JH1DSB"
    }
}

firebase.initializeApp(firebaseConfig);

export default firebase;
export const storage        = firebase.storage();
export const RealTimeDb     = firebase2.firestore();
export const db             = firebase.firestore();