import {TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton} from '@material-ui/core';
import {connect}    from 'react-redux';
// Redux
import {changeView} from '../../redux/actions/Menu';
// Values
import {Ic_edit, Ic_delete, StateView} from '../../values';
// Styles
import {useStyles}          from '../../style';
const TablesPayments = ({changeView}) => {
    const classes = useStyles()
    const edit = (item) => {
        changeView(StateView.state3)
    }
    return (
        <div style={{position:'relative', marginTop:20}}>
            <TableContainer style={{background:'#ebf5f7'}}>
                <Table size="small">
                    <TableHead>
                        <TableRow style={{background:'#a0bdca', height:50}}>
                            {
                                ['Id Servicio', 'Socio', 'Usuario', 'Total'].map((item, index) =>
                                    <TableCell align="center" key={index} className={classes.table_title1} style={{fontFamily:'bold', color:'#fff'}}> {item} </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow style={{borderBottom:`2px solid #fff`}}>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                        </TableRow>
                        <TableRow style={{borderBottom:`2px solid #fff`}}>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                        </TableRow>
                        <TableRow style={{borderBottom:`2px solid #fff`}}>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                        </TableRow>
                        <TableRow style={{borderBottom:`2px solid #fff`}}>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                            <TableCell align="center" className={classes.table_text1} style={{fontFamily:'regular'}}>x</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default connect(null, {changeView})(TablesPayments);
