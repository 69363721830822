import { urlApi, urlPath, get, post2 } from "../../values/api";
const url = urlApi + urlPath.getNearestPartner;

export const getData = async (token, extra = "") => {
  return await get(url, token, extra);
};

export const calculateService = async (partnerId, serviceType, vehicle, origin, destination, token) => {
  const urlAux = urlApi + urlPath.calculateService;
  const obj = {
    partnerId,
    serviceType,
    vehicle,
    origin,
    destination,
  };

  return await post2(obj, urlAux, token);
};

export const createNewService = async (
  id,
  name,
  phone,
  serviceType,
  vehicle,
  origin,
  destination,
  originAddress,
  destinationAddress,
  newVehicle,
  newBranches,
  authority,
  others,
  serialNumber,
  plateNumber,
  amountPlates,
  brand,
  subBrand,
  colors,
  model,
  employeeNumber,
  appointmentNumber,
  token
) => {
  const urlAux = urlApi + urlPath.createService;
  const obj = {
    partnerId: id,
    name,
    phone,
    serviceType,
    vehicle,
    origin,
    destination,
    originAddress,
    destinationAddress,
    newVehicle,
    newBranches,
    authority,
    others,
    serialNumber,
    plateNumber,
    amountPlates,
    brand,
    subBrand,
    colors,
    model,
    employeeNumber,
    appointmentNumber,
  };
  // console.log('Obj: ', obj);
  return await post2(obj, urlAux, token);
};

export const verifyData = (name, phone, vehicle, service, origen, destiny, check) => {
  if (service === 2) {
    if (!name) {
      return { flag: false, message: "Parece que algo anda mal con el nombre." };
    }
    if (!phone) {
      return { flag: false, message: "Parece que algo anda mal con el teléfono." };
    }
    if (vehicle === 0) {
      return { flag: false, message: "Parece que algo anda mal con el vehiculo." };
    }
    if (check) {
      return { flag: false, message: "Parece que algo anda mal con el origen." };
    }
    if (!origen) {
      return { flag: false, message: "Parece que algo anda mal con el origen." };
    }
    if (!destiny) {
      return { flag: false, message: "Parece que algo anda mal con el destino." };
    }
  } else {
    if (!name) {
      return { flag: false, message: "Parece que algo anda mal con el nombre." };
    }
    if (!phone) {
      return { flag: false, message: "Parece que algo anda mal con el teléfono." };
    }
    if (vehicle === 0) {
      return { flag: false, message: "Parece que algo anda mal con el vehiculo." };
    }
    if (check) {
      return { flag: false, message: "Parece que algo anda mal con el origen." };
    }
    if (!origen) {
      return { flag: false, message: "Parece que algo anda mal con el origen." };
    }
  }
  return { flag: true, message: "" };
};
