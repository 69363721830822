import { useState, useEffect }     from 'react';
import { Container, Modal }    from '@material-ui/core';
import { connect }      from 'react-redux';

import {es}         from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {TextField}  from '@material-ui/core';
import 'date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';

// Redux
import {changeView} from '../../redux/actions/Menu';
import {updateServices}    from '../../redux/actions/Service';

// Components
import Input        from '../global/Input';
// import ButtonsForm  from '../global/ButtonsForm';
import Button       from '../global/Button';
import MapService   from '../global/MapService';
import DragImage    from '../global/DragImage';

// Values
import {StateView}      from '../../values';
import {formatMoney}    from '../../utils';
// Styles
import {useStyles}  from '../../style';

// Component --------------------------------------------------------
const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
}

const MultipleInput1 = ({text, value1, value2, setValue, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{text}</p>
            <div style={{width:'49%'}}>
                <input type={'text'}
                    onChange={e => setValue(e.target.value)}
                    defaultValue={value1} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%'}}>
                <input type={'text'}
                        onChange={e => setValue(e.target.value)}
                        defaultValue={value2} 
                        className={classes.input}
                        disabled={blockData} />
            </div>
        </div>
    )
}

const MultipleInput2 = ({text, value1, value2, setValue, date, blockData}) =>{
    const classes = useStyles()
    return(
    <div style={{display:'flex', alignItems:'center'}}>
        <p className={classes.title_input}>{text}</p>
        <div style={{width:'25%'}}>
            <input type={'text'}
                onChange={e => setValue(e.target.value)}
                defaultValue={value1} 
                className={classes.input}
                disabled={blockData} />
        </div>
        <div style={{width:'1%'}} />
        <div style={{width:'25%'}}>
            <input type={'text'}
                onChange={e => setValue(e.target.value)}
                defaultValue={value2} 
                className={classes.input}
                disabled={blockData} />
        </div>
        <div style={{width:'1%'}} />
        <div style={{width:'35%', minWidth:200}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <KeyboardDateTimePicker
                    TextFieldComponent={TextFieldComponent}
                    InputAdornmentProps={{position:'end'}}
                    className={classes.input}
                    style={{paddingTop:2, width:'100%'}}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy hh:mm a"
                    value={date}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage={"Fecha incorrecta"}
                    InputProps={{ disableUnderline: true }}
                    disabled={blockData} />
            </MuiPickersUtilsProvider>
        </div>
    </div>
    )
}

const MultipleInput3 = ({text, value, setValue, date, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <p className={classes.title_input}>{text}</p>
            <div style={{width:'49%'}}>
                <input type={'text'}
                    onChange={e => setValue(e.target.value)}
                    defaultValue={value} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <KeyboardDateTimePicker
                        TextFieldComponent={TextFieldComponent}
                        InputAdornmentProps={{position:'end'}}
                        className={classes.input}
                        style={{paddingTop:2, width:'100%'}}
                        id="date-picker-dialog"
                        format="dd/MM/yyyy hh:mm a"
                        value={date}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        invalidDateMessage={"Fecha incorrecta"}
                        InputProps={{ disableUnderline: true }}
                        disabled={blockData} />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

const MultipleInput4 = ({flag, value, setValue, date, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{padding:5, marginRight:10}}>
                <div style={{background:flag?'#66bf41':'red', color:"#fff", width:50, height:33, display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <label>{flag?'ON':'OFF'}</label>
                </div>
            </div>
            <div style={{width:'49%'}}>
                <input type={'text'}
                    onChange={e => setValue(e.target.value)}
                    defaultValue={value} 
                    className={classes.input}
                    disabled={blockData} />
            </div>
            <div style={{width:'1%'}} />
            <div style={{width:'49%'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <KeyboardDateTimePicker
                        TextFieldComponent={TextFieldComponent}
                        InputAdornmentProps={{position:'end'}}
                        className={classes.input}
                        style={{paddingTop:2, width:'100%'}}
                        id="date-picker-dialog"
                        format="dd/MM/yyyy hh:mm a"
                        value={date}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        invalidDateMessage={"Fecha incorrecta"}
                        InputProps={{ disableUnderline: true }}
                        disabled={blockData} />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

const Form = ({stateView, data, token, changeView, updateServices}) => {
    const classes = useStyles()
    const [blockData, setBlockData] = useState('disabled')
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [origin, setOrigin]                   = useState(data.Origin?data.Origin:[])
    const [originAddres, setOriginAddres]       = useState(data.OriginAddress?data.OriginAddress:'')
    const [destination, setDestination]         = useState(data.Destination?data.Destination:[])
    const [destAddres, setDesAddres]            = useState(data.DestinationAddress?data.DestinationAddress:'')

    const [partnerId, setPartnerId]             = useState(data.PartnerId?data.PartnerId:'')
    const [partnerName, setPartnerName]         = useState(data.PartnerName?data.PartnerName:'')
    const [partnerPhone, setPartnerPhone]       = useState(data.PartnerPhone?data.PartnerPhone:'')
    const [partnerCompany, setPartnerCompany]   = useState(data.PartnerCompany?data.PartnerCompany:'')
    const [partnerPosition, setPartnerPosition] = useState(data.PartnerPosition?data.PartnerPosition:[])
    const [partnerPosDate, setPartnetPosDate]   = useState(data.PartnerPositionDate?data.PartnerPositionDate:'')

    const [service, setService]                 = useState(data.Service?data.Service:'')
    const [id, setId]                           = useState(data.Id?data.Id:'')
    const [status, setStatus]                   = useState(data.Status?data.Status:'')
    const [statusDate, setStatusDate]           = useState(data.StatusDate?data.StatusDate:'')

    const [statusRequesting, setStatusRequesting]           = useState(data.StatusRequesting?data.StatusRequesting:{})
    const [statusAccepted, setStatusAccepted]               = useState(data.StatusAccepted?data.StatusAccepted:{})
    const [statusQuotation, setStatusQuotation]             = useState(data.StatusQuotation?data.StatusQuotation:{})
    const [statusComming, setStatusComming]                 = useState(data.StatusComming?data.StatusComming:{})
    const [statusPaying, setStatusPaying]                   = useState(data.StatusPaying?data.StatusPaying:{})
    const [statusService, setStatusService]                 = useState(data.StatusService?data.StatusService:{})
    const [statusCanceledUser, setStatusCanceledUser]       = useState(data.StatusCanceledUser?data.StatusCanceledUser:{})
    const [statusCanceledPartner, setStatusCanceledPartner] = useState(data.StatusCanceledPartner?data.StatusCanceledPartner:{})
    const [statusFinished, setStatusFinished]               = useState(data.StatusFinished?data.StatusFinished:{})

    const [userId, setUserId]                   = useState(data.UserId?data.UserId:'')
    const [userName, setUserName]               = useState(data.UserName?data.UserName:'')
    const [userPhone, setUserPhone]             = useState(data.UserPhone?data.UserPhone:'')
    // const [userPosition, setUserPosition]       = useState(data.UserPosition?data.UserPosition:[])
    const [userPosDate, setUserPostDate]        = useState(data.UserPositionDate?data.UserPositionDate:'')
    const [chatId, setChatId]                   = useState(data.ChatId?data.ChatId:'')
    
    const [totalLlaoz, setTotalLlaoz]           = useState(data.TotalLlaoz?data.TotalLlaoz:'')
    const [totalCheckOut, setTotalCheckOut]     = useState(data.TotalCheckOut?data.TotalCheckOut:'')
    const [totalPartner, setTotalPartner]       = useState(data.TotalPartner?data.TotalPartner:'')       
    // const [totalService, setTotalService]       = useState(data.TotalService?data.TotalService:'')

    // const [vehiclePicture, setVehiclePicture]   = useState(data.VehiclePicture?data.VehiclePicture:'')
    const [placePicture, setPlacePicture]       = useState(data.PlacePicture?data.PlacePicture:'')
    const [partnerPicture, setPartnerPicture]   = useState(data.PartnerPicture?data.PartnerPicture:'')
    const [userPicture, setUserPicture]         = useState(data.UserPicture?data.UserPicture:'')

    const [placeName, setPlaceName]             = useState(data.PlaceName?data.PlaceName:'')
    // const [vehicleName, setVehicleName]         = useState(data.VehicleName?data.VehicleName:'')

    const [open, setOpen]   = useState(false)
    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateServices([])
    }
    return (
        <Container maxWidth="xl">
            <Modal
                open={open}
                onClose={()=>setOpen(!open)}
                style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Container maxWidth="md">
                        <div style={{background:'#fff'}}>
                            <Container>
                                <div style={{height:10}} />
                                <MultipleInput4 flag={statusRequesting.State} value={statusRequesting.Name} date={statusRequesting.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusAccepted.State} value={statusAccepted.Name} date={statusAccepted.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusQuotation.State} value={statusQuotation.Name} date={statusQuotation.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusPaying.State} value={statusPaying.Name} date={statusPaying.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusComming.State} value={statusComming.Name} date={statusComming.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusService.State} value={statusService.Name} date={statusService.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusCanceledUser.State} value={statusCanceledUser.Name} date={statusCanceledUser.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusCanceledPartner.State} value={statusCanceledPartner.Name} date={statusCanceledPartner.StatusDate} blockData={blockData} />
                                <MultipleInput4 flag={statusFinished.State} value={statusFinished.Name} date={statusFinished.StatusDate} blockData={blockData} />
                                <div style={{height:10}} />
                            </Container>
                        </div>
                    </Container>
            </Modal>
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <MapService origen={origin} destination={destination} partner={partnerPosition} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%', position:'relative'}}>
                            <div style={{display:'flex', paddingBottom:50}}>
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={placePicture} option='1' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>VEHICULO/SUCURSAL</p>
                                </div>
                                <div style={{width:75}} />
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={partnerPicture} option='2' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>SOCIO</p>
                                </div>
                                <div style={{width:75}} />
                                <div style={{width:'100%', maxWidth:250, position:'relative'}}>
                                    <DragImage image={userPicture} option='3' blockData={blockData} />
                                    <p style={{position:'absolute', bottom:-40, left:'50%', transform:'translate(-50%, 0)'}}>USUARIO</p>
                                </div>
                            </div>
                            <MultipleInput1 text={'Servicio & Id'} value1={service} value2={id} blockData={blockData} />
                            <div onClick={()=> setOpen(true)} style={{width:'100%', height:40, position:'absolute', cursor:'pointer', zIndex:10}} />
                            <MultipleInput3 text={'Estatus'} value={status} date={statusDate} blockData={blockData} />
                            
                        </div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <MultipleInput1 text={'Origen'} value1={origin.Lat} value2={origin.Lng} blockData={blockData} />
                            <MultipleInput1 text={'Destino'} value1={destination.Lat} value2={destination.Lng} blockData={blockData} />
                            <Input title={'Id Socio'} value={partnerId} blockData={blockData} />
                            <Input title={'Socio'} value={partnerName} blockData={blockData} />
                            <MultipleInput2 text={'Pos Socio'} value1={partnerPosition.Lat} value2={partnerPosition.Lng} date={partnerPosDate} blockData={blockData} />
                            <Input title={'Tel Socio'} value={partnerPhone} blockData={blockData} />
                            <Input title={'Company'} value={partnerCompany} blockData={blockData} />
                            <Input title={'Vehiculo/Sucur.'} value={placeName} blockData={blockData} />
                            
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Dirección O.'} value={originAddres} blockData={blockData} />
                            <Input title={'Dirección D.'} value={destAddres} blockData={blockData} />
                            <Input title={'Id Usuario'} value={userId} blockData={blockData} />
                            <Input title={'Usuario'} value={userName} blockData={blockData} />
                            <MultipleInput2 text={'Pos Usuario'} value1={origin.Lat} value2={origin.Lng} date={userPosDate} blockData={blockData} />
                            <Input title={'Tel Usuario'} value={userPhone} blockData={blockData} />
                            <Input title={'Id Chat'} value={chatId} blockData={blockData} />
                        </div>
                    </div>

                    <div style={{display:'flex', marginTop:20}}>
                        <div style={{width:'48%'}}>
                            <Input title={'T socio'} value={`$ ${formatMoney(totalPartner)}`} blockData={blockData} />
                            <Input title={'Llaoz'} value={`$ ${formatMoney(totalLlaoz)}`} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Pasarela'} value={`$ ${formatMoney(totalCheckOut)}`} blockData={blockData} />
                            <Input title={'Total'} value={`$ ${formatMoney(totalPartner+totalLlaoz+totalCheckOut)}`} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <div style={{background:'#fff', marginTop:5, paddingTop:10, paddingBottom:10}}>
                <Container maxWidth="xl">
                    <Button text={'Volver'} 
                        border={50} background={'#132327'} onClick={Back} />
                </Container>
            </div>
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Services.servicesEdit,
    token:          state.Panel.token,
})
export default connect(mapStateToProps, {changeView, updateServices})(Form);
