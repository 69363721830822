import {reduxState} from '../../values';

export const saveToken = (token, Name, Surname, Image) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.login,
            payload:    token,
            name:       Name,
            surname:    Surname,
            image:      Image,
        })
    }
} 

export const refreshToken = (token) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.refresh,
            Token:      token
        })
    }
} 