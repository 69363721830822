import {reduxState} from '../../values';


let initialState = {}
try {
    initialState = {
        admin: {
            "image":    localStorage.getItem('llaoz-image'), 
            "name":     localStorage.getItem('llaoz-name'), 
            "lastname": localStorage.getItem('llaoz-lastname')
        },
        token: localStorage.getItem('llaoz-key'),
        permissions: JSON.parse(localStorage.getItem('llaoz-roles'))
    }
} 
catch (error) {
    initialState = {
        admin: {
            "image":    localStorage.getItem('llaoz-image'), 
            "name":     localStorage.getItem('llaoz-name'), 
            "lastname": localStorage.getItem('llaoz-lastname')
        },
        token: localStorage.getItem('llaoz-key'),
        permissions: []
    }
}

const panelAdmin = (state=initialState, action) =>{
    if(action.type === reduxState.login){
        return {
            ...state,
            token:  action.payload,
            user:   {
                "image":    action.image,
                "name":     action.name,
                "lastname": action.surname,
            }
        }
    }
    if(action.type === reduxState.refresh){
        return {
            ...state,
            token:  action.Token,
        }
    }
    return state
}

export default panelAdmin