import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateChannels} from '../../redux/actions/Channels';
// Components
import Input            from '../global/Input';
import ButtonsForm      from '../global/ButtonsForm';
import MultipleSelect   from '../global/MultipleSelect';
// Values
import {StateView, toastText, subjectsSelect}   from '../../values';
// Api 
import {updateData, postData, deleteData, validateData}   from '../../utils/channels';

const Form = ({stateView, data, changeView, updateChannels, token}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [email, setEmail]         = useState(data.Email?data.Email:'')
    const [subject, setSubject]     = useState(data.Subject?data.Subject:[])
    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateChannels([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setblockData('')
        setEdit(value)
        if(value===2){
            const validate = validateData(email)
            if(validate.flag){
                const res = await updateData(data.Id, token, email, subject)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateChannels([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = validateData(email)
            if(validate.flag){
                const res = await postData(token, email, subject)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateChannels([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <Input title={'Correo'} type={'email'} value={email} setData={setEmail} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <MultipleSelect title={'Subject'} height={170} def={subjectsSelect} values={subject} setValue={setSubject} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Channels.channelsEdit,
    token:          state.Panel.token,
})

export default connect(mapStateToProps, {changeView, updateChannels})(Form);
