import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunkMiddleware  from 'redux-thunk';

import View             from './reducers/Menu';
import Panel            from './reducers/PanelAdmin';
import Admins           from './reducers/Admins';
import Cities           from './reducers/Cities';
import Users            from './reducers/Users';
import Fares            from './reducers/Fares';
import Companies        from './reducers/Companies';
import Partners         from './reducers/Partners';
import Branches         from './reducers/Branches';
import Vehicles         from './reducers/Vehicles';
import Apps             from './reducers/Apps';
import Contacts         from './reducers/Contact';
import Reports          from './reducers/Reports';
import Notifications    from './reducers/Notifications';
import Services         from './reducers/Service';
import History          from './reducers/History';
import Invoice          from './reducers/Invoice';
import Faqs             from './reducers/Faqs';
import Channels         from './reducers/Channels';
import Permissions      from './reducers/Permissions';

const reducers = combineReducers({
    Permissions,
    Panel,
    View,
    Admins,
    Users,
    Cities,
    Fares,
    Companies,
    Partners,
    Branches,
    Vehicles,
    Apps,
    Contacts,
    Reports,
    Notifications,
    Services,
    History,
    Invoice,
    Faqs,
    Channels
})

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() || compose
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store;