import {useState}   from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Img_xml, Img_pdf, Pdf, Xml} from '../../values';

export const useStyles = makeStyles(theme => ({
    drag_image_default:{
        position:'absolute', 
        top:'50%', 
        left:'50%', 
        transform:'translate(-50%, -50%)', 
        height:70,
    },
    drag_image_selected:{
        width:'100%',
        height:'100%',
        objectFit:'scale-down'
    },
    drag_logo1:{
        width:'100%',
        // maxWidth:1000,
        height:'100%',
        objectFit:'scale-down'
    }
}));

const DragImage = ({file, setData, setDataAux, blockData, accept='application/pdf', option='', height=250,}) => {
    const [files, setFiles] = useState(file)
    
    const handleFile = (value) => {
        // console.log("V: ", value.files[0]);
        const url = window.URL.createObjectURL(value.files[0])
        setFiles(url)
        setData(url)
        setDataAux(value.files[0])
    }
    return (
        <div style={{width:'100%'}}>
            <label htmlFor={blockData === 'disabled' ? '' : `file-upload${option}`} style={{cursor: blockData === 'disabled' ? 'default' : 'pointer', position:'relative'}}>
                <div style={{height:height, background:'#ebf5f7', display:'flex', justifyContent:'center'}}>
                    <img alt="drag_image" 
                        style={{width:'100%', maxWidth:100}}
                        src={accept==='application/pdf'?files?Img_pdf:Pdf:files?Img_xml:Xml} />
                </div>
            </label>
            
            <input id={`file-upload${option}`} type="file" accept={`${accept}`} style={{display:'none'}} onChange={e => handleFile(e.target)} />
        </div>
    );
};

export default DragImage;