import { useState }     from 'react';
import { Container }    from '@material-ui/core';
import { connect }      from 'react-redux';
import { toast }        from 'react-toastify';
// Redux
import {changeView}     from '../../redux/actions/Menu';
import {updateAdmin}    from '../../redux/actions/Admins';
// Components
import DragImage        from '../global/DragImage';
import Input            from '../global/Input';
import ButtonsForm      from '../global/ButtonsForm';
import MultipleSelect   from '../global/MultipleSelect';
// Values
import {StateView, toastText}   from '../../values';
import {restorePassword}        from '../../values/api';
// Api 
import {updateData, postData, deleteData, postValidate, updateValidate}   from '../../utils/admins';
import {deployImage}    from '../../utils';

// const Roles = ['Admins', 'App', 'Branches', 'Cities', 'Companies', 'Contact', 'Fares', 'History', 'Invoice', 'Notifications', 'Partners', 'Payment', 'Reports', 'Service', 'Users', 'Vehicles']

const Form = ({stateView, data, changeView, updateAdmin, token, permissions}) => {
    const [blockData, setblockData] = useState(stateView===StateView.state2?"":"disabled")
    // State buttons ----------------------------------------------------------------
    const [edit, setEdit]           = useState(0)
    const [erase, setErase]         = useState(0)
    const [add, setAdd]             = useState(0)

    // Data -------------------------------------------------------------------------
    const [image, setImage]         = useState(data.Picture?data.Picture:'')
    const [name, setName]           = useState(data.Name?data.Name:'')
    const [surname, setSurname]     = useState(data.Surname?data.Surname:'')
    const [email, setEmail]         = useState(data.Email?data.Email:'')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [roles, setRoles]         = useState(data.Roles?data.Roles:[])
    // Functions --------------------------------------------------------------------
    const Back = () =>{
        changeView(StateView.state1)
        updateAdmin([])
    }

    const Edit = async() =>{
        const value = edit + 1
        setblockData('')
        setEdit(value)
        if(value===2){
            const validate = updateValidate(name, surname)
            if(validate.flag){
                let UrlImage = image
                if(typeof image==='object'){
                    UrlImage = await deployImage(image, "Admins", "admins")
                    setImage(UrlImage)
                }
                const res = await updateData(data.Id, token, name, surname, UrlImage, roles)
                if(res){
                    toast.success(toastText.update)
                    console.log(toastText.update)
                    changeView(StateView.state1)
                    updateAdmin([])
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setEdit(1)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setEdit(1)
            }
        }
    }
    const Add = async() =>{
        const value = add + 1
        setAdd(value)
        if(value===1){
            const validate = postValidate(image, name, surname, email, password1, password2)
            if(validate.flag){
                let UrlImage = image
                if(typeof image=== 'object'){
                    UrlImage = await deployImage(image, "Admins", "admins")
                    setImage(UrlImage)
                }
                const res = await postData(token, name, surname, email, UrlImage, password1, roles)
                if(res){
                    toast.success(toastText.add)
                    console.log(toastText.add)
                    changeView(StateView.state1)
                } else{
                    toast.warn(toastText.warning)
                    console.log(toastText.warning)
                    setAdd(0)
                }
            } else{
                toast.warn(validate.message)
                console.log(validate.message)
                setAdd(0)
            }
        }
    }
    const Delete = async() =>{
        const value = erase + 1
        setErase(value)
        if(value===1){
            const res = await deleteData(data.Id, token)
            if(res){
                toast.success(toastText.delete)
                console.log(toastText.delete)
                changeView(StateView.state1)
                updateAdmin([])
            } else{
                toast.warn(toastText.warning)
                console.log(toastText.warning)
                setErase(0)
            }
        }
    }
    return (
        <Container maxWidth="xl">
            <div style={{background:'#fff', paddingBottom:50, paddingTop:20}}>
                <Container maxWidth="xl">
                    <div style={{display:'flex'}}>
                        <div style={{width:'48%'}}>
                            <DragImage text={"FOTO"} image={image} setImage={setImage} height={300} blockData={blockData} />
                        </div>
                        <div style={{width:'4%'}} />
                        <div style={{width:'48%'}}>
                            <Input title={'Nombre'} value={name} setData={setName} blockData={blockData} />
                            <Input title={'Apellido'} value={surname} setData={setSurname} blockData={blockData} />
                            <Input title={'Email'} type={'email'} value={email} setData={setEmail} blockData={stateView===StateView.state2?'':'disabled'} />
                            <div style={{display:stateView===StateView.state2?'block':'none'}}>
                                <Input title={'Contraseña'} type={'password'} value={password1} setData={setPassword1} blockData={blockData} />
                                <Input title={'Contraseña'} type={'password'} value={password2} setData={setPassword2} blockData={blockData} />
                            </div>
                            <div style={{display:stateView===StateView.state2?'none':'block'}}>
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                    <p onClick={()=>restorePassword(email)}
                                        style={{cursor:'pointer', color:'#5272f1', fontWeight:600, fontSize:14}}>
                                            RESTABLECER CONTRASEÑA
                                    </p>
                                </div>
                            </div>
                            <MultipleSelect title={'Permisos'} def={permissions} values={roles} setValue={setRoles} blockData={blockData} />
                        </div>
                    </div>
                </Container>
            </div>
            <ButtonsForm View={stateView} Back={Back} Edit={Edit} edit={edit} Add={Add} add={add} Delete={Delete} erase={erase} />
        </Container>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    data:           state.Admins.adminsEdit,
    token:          state.Panel.token,
    permissions:    state.Permissions.permissions
})

export default connect(mapStateToProps, {changeView, updateAdmin})(Form);
