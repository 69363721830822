import {Grid} from '@material-ui/core';

import {Ic_car, Ic_motorcycle, Ic_truck, Ic_van} from '../../values';
import {useStyles}  from '../../style';

const ButtonsAvailability = ({value, setValue, background='#266df1', blockData}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', }}>
            <p className={classes.title_input}>{"Disponibilidad"}</p>
            <div style={{display:'flex', flexWrap:'wrap'}}>
                <div className={blockData==='disabled'?classes.paper:classes.paper2} onClick={() => setValue(1)}
                    style={{cursor:blockData==='disabled'?'':'pointer', background:value.MOTORCYCLE?background:'#fff'}}>
                    <img alt="Ic_motorcycle" src={Ic_motorcycle} className={classes.image_availability} />
                    <p className={classes.text_availability} style={{color:value.MOTORCYCLE?'#fff':'#000'}}>{"Motorcyrcle"}</p>
                </div>
                <div className={blockData==='disabled'?classes.paper:classes.paper2} onClick={() => setValue(2)}
                    style={{cursor:blockData==='disabled'?'':'pointer', background:value.CAR?background:'#fff'}}>
                    <img alt="Ic_car" src={Ic_car} className={classes.image_availability} />
                    <p className={classes.text_availability} style={{color:value.CAR?'#fff':'#000'}}>{"Car"}</p>
                </div>
                <div className={blockData==='disabled'?classes.paper:classes.paper2} onClick={() => setValue(3)}
                    style={{cursor:blockData==='disabled'?'':'pointer', background:value.VAN?background:'#fff'}}>
                    <img alt="Ic_van" src={Ic_van} className={classes.image_availability} />
                    <p className={classes.text_availability} style={{color:value.VAN?'#fff':'#000'}}>{"Van"}</p>
                </div>
                <div className={blockData==='disabled'?classes.paper:classes.paper2} onClick={() => setValue(4)}
                    style={{cursor:blockData==='disabled'?'':'pointer', background:value.TRUCK?background:'#fff'}}>
                    <img alt="Ic_truck" src={Ic_truck} className={classes.image_availability} />
                    <p className={classes.text_availability} style={{color:value.TRUCK?'#fff':'#000'}}>{"Truck"}</p>
                </div>
            </div>
            
        </div>
    );
};

export default ButtonsAvailability;