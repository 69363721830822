import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.branches

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, name, address, lat, lng, postalCode, city, company, companyId, availability, picture) =>{
    const obj = {
        Name:               name,
        Address:            address,
        Position:           {Lat:parseFloat(lat), Lng:parseFloat(lng)},
        PostalCode:         postalCode,
        City:               city,
        Company:            company,
        CompanyId:          companyId.trim(),
        Availability:       availability,
        Picture:            picture
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, name, address, lat, lng, postalCode, city, company, companyId, availability, picture) =>{
    const obj = {
        Name:               name,
        Address:            address,
        Position:           {Lat:parseFloat(lat), Lng:parseFloat(lng)},
        PostalCode:         postalCode,
        City:               city,
        Company:            company,
        CompanyId:          companyId.trim(),
        Availability:       availability,
        Picture:            picture
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const postValidate = (name, address, lat, lng, postalCode, city, company, companyId, picture) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con tu nombre.'}
    }
    if(!address){
        return {flag:false, message:'Parece que algo anda mal con la dirección.'}
    }
    if(!lat){
        return {flag:false, message:'Parece que algo anda mal con la latitud.'}
    }
    if(!lng){
        return {flag:false, message:'Parece que algo anda mal con la longitud.'}
    }
    if(!postalCode){
        return {flag:false, message:'Parece que algo anda mal con el codigo postal.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!company || company === 'ERROR'){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
        // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
    }
    if(!companyId){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
    }
    if(!picture){
        return {flag:false, message:'Parece que algo anda mal con la foto.'}
    }
    return {flag:true, message:''}
}

export const updateValidate = (name, address, lat, lng, postalCode, city, company, companyId) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con tu nombre.'}
    }
    if(!address){
        return {flag:false, message:'Parece que algo anda mal con la dirección.'}
    }
    if(!lat){
        return {flag:false, message:'Parece que algo anda mal con la latitud.'}
    }
    if(!lng){
        return {flag:false, message:'Parece que algo anda mal con la longitud.'}
    }
    if(!postalCode){
        return {flag:false, message:'Parece que algo anda mal con el codigo postal.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!company || company === 'ERROR'){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
        // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
    }
    if(!companyId){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
    }
    return {flag:true, message:''}
}