import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.notifications

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, title, message, schedule) =>{
    const obj = {
        Message:    message,
        Schedule:   schedule,
        Title:      title,
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, title, message, schedule) =>{
    const obj = {
        Message:    message,
        Schedule:   schedule,
        Title:      title,
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const dataValidate = (title, message) =>{
    if(!title){
        return {flag:false, message:'Parece que algo anda mal con el titulo.'}
    }
    if(!message){
        return {flag:false, message:'Parece que algo anda mal con el mensaje.'}
    }
    return {flag:true, message:''}
}