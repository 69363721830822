import { useEffect } from 'react';
import { connect } from 'react-redux';
// Redux
import {getUsers}   from '../../redux/actions/Users';
// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// Api
import {getData}    from '../../utils/users';

const Index = ({stateView, token, getUsers}) => {
    const GetData = async() =>{
        const res = await getData(token)
        getUsers(res.data)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div>
            <Head title='Usuarios' view={false} />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
    token:          state.Panel.token
})

export default connect(mapStateToProps, {getUsers})(Index);
