import { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Location, Ic_whatsapp } from '../../values';

const useStyles = makeStyles(theme => ({
    whatsapp_container: {
        background: '#2fd25a',
        minWidth: 35, height: 35,
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        borderRadius: 8,
        marginRight: 10,
        cursor: 'pointer'
    },
    input: {
        paddingLeft: 15,
        paddingRight: 55,
        height: 40,
        // maxWidth: 600, width: '100%',
        borderRadius: 20, border: 'none'
    },
    button: {
        background: '#15398f',
        border: 'none', cursor: 'pointer', borderRadius: 100,
        width: 30, height: 30,
    },
    width_container: {
        maxWidth: 660, width: '90%', position: 'relative',
        animation: '$animation_input 0.25s ease-in',
    },
    '@keyframes animation_input': {
        'from': {
            width: '0%',
            opacity: 0
        },
        'to': {
            width: '90%',
            opacity: 1
        },
    },
}));

const InputWhatsapp = ({ value, setValue, onAction }) => {
    const classes = useStyles()
    const [state, setState] = useState(false)
    return (
        <div style={{ position: 'absolute', left: 8, bottom: 5, zIndex: 2, display: 'flex', width: '100%', height: 40 }}>
            <div onClick={() => setState(!state)} className={classes.whatsapp_container}>
                <img alt='' src={Ic_whatsapp} style={{ width: '70%' }} draggable={false} />
            </div>
            {
                state &&
                <div className={classes.width_container}>
                    <input className={`${classes.input} ${classes.width_container}`}
                        placeholder='Ingresa el link de la ubicación de whatsapp'
                        value={value} onChange={(e) => setValue(e.target.value)} />

                    <button className={classes.button} onClick={onAction} style={{ position: 'absolute', right: '12%', top: 5 }}>
                        <img alt='' src={Location} />
                    </button>
                </div>
            }

        </div>
    );
};

export default InputWhatsapp;