import { connect } from 'react-redux';
// Components
import Head         from '../global/Head';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
const Index = ({stateView}) => {
    return (
        <div>
            <Head title='Pagos' />
            {
                stateView===StateView.state1?
                    <Tables />
                :
                    <Form />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.View.stateView,
})

export default connect(mapStateToProps, {})(Index);
