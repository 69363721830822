import {Container}  from '@material-ui/core';
import Button       from './Button';
import {StateView}  from '../../values';

const ButtonsForm = ({View, Back, Edit, edit, Add, add, Delete, erase}) => {
    return (
        <div style={{background:'#fff', marginTop:5, paddingTop:10, paddingBottom:10}}>
            <Container maxWidth="xl">
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <Button text={'Volver'} 
                            border={50} background={'#132327'} onClick={Back} />
                        <div style={{width:50}} />
                        <div style={{display:`${View === StateView.state3 ? 'none' : ''}`}}>
                            <Button text={'Guardar'} 
                                border={50} background={'#266df1'} 
                                onClick={Add} loading={add===1?true:false} />
                        </div>
                        <div style={{display:Edit?'block':'none'}}>
                            <div style={{display:`${View === StateView.state2 ? 'none' : ''}`}}>
                                <Button text={edit===0?'Editar':'Guardar'} 
                                    border={50} background={'#266df1'} 
                                    onClick={Edit} loading={edit===2?true:false} />
                            </div>
                        </div>
                    </div>
                    <div style={{display:Delete?'block':'none'}}>
                        <div style={{display:`${View === StateView.state2 ? 'none' : ''}`}}>
                            <div style={{display:edit>=1?'':'none'}}>
                                <Button text={'Eliminar'} 
                                    border={50} background={'#6c7b83'} 
                                    onClick={Delete} loading={erase===1?true:false} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ButtonsForm;