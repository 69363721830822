import firebase             from '../../firebase';
import {urlApi, urlPath}    from '../../values/api';

export const VerifyLogin = async(email, password) =>{
    const url   = urlApi+urlPath.validateAdmins+email
    let flag1   = false
    let flag2   = false
    let token   = ""
    let data    = {}

    if(email){
        await fetch(url)
        .then(response =>{
            if(response.status===200){
                flag1 = true
                return response.json()
            }
        })
        .then(res =>{
            if(flag1){
                data.Name       = res.Name
                data.Surname    = res.Surname
                data.Image      = res.Picture
                data.Roles      = res.Roles
            }
        })
    }

    if(email && password && flag1===true){
        await firebase.auth().signInWithEmailAndPassword(email, password)
        .then((user) =>{
            flag2 = true
            token = user.user._lat
        })
        .catch((error) =>{
            console.log("ERROR");
            return flag2
        })
    } else{
        console.log("Email o password incorrecto");
    }

    return {flag:flag2, token, data}
}