import { RealTimeDb } from "../../firebase";

import { urlApi, urlPath, get } from "../../values/api";
const url = urlApi + urlPath.changeStatusService;

const getStatusService = async (id) => {
  let statusService = "";
  await RealTimeDb.collection("Service")
    .doc(id)
    .get()
    .then((query) => {
      statusService = query.data().Status;
    });
  // console.log("statusService: ", statusService);
  return statusService;
};

export const getPartnerConnected = async (
  setPartnerData,
  setPartnerLocation,
  setPartnerService,
  setServiceError,
  setOpenArray
) => {
  await RealTimeDb.collection("Partners").onSnapshot(async (query) => {
    let arrayConnected = [];
    let arrayService = [];
    let location = [];
    let serviceId = "";

    await query.forEach(async (i) => {
      serviceId = i.data().ServiceId;
      try {
        const PlacePosition = {
          Lat: i.data().PlacePosition.x_,
          Lng: i.data().PlacePosition.N_,
        };
        const Position = {
          Lat: i.data().Position.x_,
          Lng: i.data().Position.N_,
        };

        const PlacePosition2 = {
          lat: i.data().PlacePosition.x_,
          lng: i.data().PlacePosition.N_,
        };
        const Position2 = {
          lat: i.data().Position.x_,
          lng: i.data().Position.N_,
        };
        const obj = {
          Id: i.id,
          Name: i.data().Name,
          Surname: i.data().Surname,
          Email: i.data().Email,
          Phone: i.data().Phone,
          PIN: i.data().PIN,
          Rol: i.data().Rol,
          Company: i.data().Company,
          CompanyId: i.data().CompanyId,
          PictureProfile: i.data().PictureProfile,
          PictureDocument: i.data().PictureDocument,
          ServiceId: i.data().ServiceId ? i.data().ServiceId : "",
          Position: Position,
          Status: i.data().Status,
          Place: i.data().Place,
          Plates: i.data().Plates ? i.data().Plates : "",
          PlaceName: i.data().PlaceName,
          PlaceId: i.data().PlaceId,
          PlacePosition: PlacePosition,
          PlacePicture: i.data().PlacePicture,
          PlaceAvailability: i.data().PlaceAvailability,
          DeviceInfo: i.data().DeviceInfo,
          TokenFCM: i.data().TokenFCM,
          Services: i.data().Services,
          Rate: i.data().Rate ? i.data().Rate : 5,
          // ServicesAccepted:   i.data().ServicesAccepted,
          PositionDateTime: i.data().PositionDateTime.toDate().toISOString(),
          // time:               i.data().PositionDateTime,
          StatusService: "",
        };
        if (i.data().ServiceId) {
          const statusService = await getStatusService(i.data().ServiceId);
          obj.StatusService = statusService;
        }

        if (i.data().Status === "CONNECTED") {
          if (i.data().Place === "VEHICLE") {
            location.push(Position2);
          } else {
            location.push(PlacePosition2);
          }
          arrayConnected.push(obj);
        }

        if (i.data().Status === "SERVICE" || i.data().Status === "ACCEPTED") {
          if (i.data().Place === "VEHICLE") {
            location.push(Position2);
          } else {
            location.push(PlacePosition2);
          }
          arrayService.push(obj);
        }
      } catch (error) {}
    });
    // console.log("OBJ: ", array);
    setPartnerData(arrayConnected);
    setPartnerService(arrayService);
    setPartnerLocation(location);
  });

  await RealTimeDb.collection("ServiceErrors").onSnapshot((query) => {
    let array = [];
    let arrayFlag = [];
    query.forEach((i) => {
      const obj = {
        Id: i.id,
        CityId: i.data().CityId,
        Code: i.data().Code,
        Date: i.data().Date.toDate(),
        Message: i.data().Message,
        ServiceType: i.data().ServiceType,
        UserId: i.data().UserId,
        UserName: i.data().UserName,
        UserPhone: i.data().UserPhone,
        UserTokenFCM: i.data().UserTokenFCM,
      };
      array.push(obj);
      arrayFlag.push(false);
    });
    setOpenArray(arrayFlag);
    setServiceError(array);
  });
};

export const changeStatus = async (extra = "", token) => {
  console.log("extra: ", extra);
  return await get(url, token, extra);
};
