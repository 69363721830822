import {reduxState}  from '../../values';


export const updateMenu = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_menu,
            payload:    data
        })
    }
}


export const updateDrawer = state =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.drawer,
            payload:    state
        })
    }
}

export const changeView = view =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.view,
            view:    view
        })
    }
}

export const cleanData = () =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_admin,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_cities,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_users,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_fares,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_companies,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_partners,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_branches,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_vehicles,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_apps,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_contacts,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_service,
            payload:    [],
        })
        dispatch({
            type:       reduxState.update_faqs,
            payload:    [],
        })
    }
}