import { useState, useEffect, useRef } from 'react';
import { Container } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';

import { updatePartners } from '../../redux/actions/Partners';
import { changeView } from '../../redux/actions/Menu';

import SearchBox from '../serviceCenter/SearchBox';


import { updateRadius } from '../../utils/partners';
import { StateView, toastText } from '../../values';

const keyMap = "AIzaSyD-gp80NgkKWltxT7yRoSj4vLVjtBIzJ-Y"
let circle

const Button = ({ onAction, loading }) => {
    return (
        <button onClick={onAction}
            style={{ marginBottom: 20, width: '100%', background: '#266df1', color: '#fff', border: 'none', cursor: 'pointer', height: 40, fontSize: 16, borderRadius: 6, position: 'relative' }}>
            {
                loading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <ReactLoading type={"bars"} color={"white"} style={{ fill: '#fff', height: 40, width: 40 }} />
                    </div>
                    : 'GUARDAR'
            }
        </button>
    )
}

const Map = ({ stateView, data, token, changeView, updatePartners }) => {
    const [position, setPosition] = useState({ lat: 20.662926, lng: -103.364936 })
    const [zoom, setZoom] = useState(11)
    const [size, setSize] = useState(data.Radius ? data.Radius : 2)

    const [loading, setLoading] = useState(false)

    const handleCircle = (google) => {
        circle = new google.maps.Circle({
            strokeColor: '#1E7DFF',
            strokeOpacity: 0.3,
            strokeWeight: 0,
            fillColor: '#1E7DFF',
            fillOpacity: 0.3,
            map: google.map,
            center: position,
            radius: size * 1000,
        })
    }

    const handleClick = ({ lat, lng }) => {
        setPosition({ lat, lng })
        try {
            circle.setCenter({ lat, lng })
        } catch (error) { }
    }

    const handleSize = (newSize) => {
        setSize(newSize)
        circle.setRadius(newSize * 1000)
    }

    const updateInfo = async () => {
        setLoading(true)
        const res = await updateRadius(data.Id, size, position, token)
        if (res) {
            toast.success(toastText.update)
            changeView(StateView.state1)
            updatePartners([])
        } else {
            toast.warn(toastText.warning)
        }
        setLoading(false)
    }

    const mapData = () => {
        setSize(data.Radius)
        if (data.PositionRadius) {
            setPosition({ lat: data.PositionRadius.Lat, lng: data.PositionRadius.Lng })
        } else {
            setPosition({ lat: 20.662926, lng: -103.364936 })
        }
        try {
            circle.setRadius(data.Radius * 1000)
            // circle.setCenter({ lat: data.PositionRadius.Lat, lng: data.PositionRadius.Lng })
            if (data.PositionRadius) {
                circle.setCenter({ lat: data.PositionRadius.Lat, lng: data.PositionRadius.Lng })
            } else {
                circle.setCenter({ lat: 20.662926, lng: -103.364936 })
            }
        } catch (error) { }
    }

    const changeLocation = (position) => {
        // console.log('P: ', position);
        setPosition(() => position)
    }

    useEffect(() => {
        mapData()
    }, [data])
    return (
        <div>
            <Container>
                <div style={{ width: '38%', background: '#fff', position: 'fixed' }}>
                    <Container>
                        <p style={{ margin: 0, paddingTop: 30 }}>{'Define el área dónde el socio ofrece servicio'}</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p>{'Radio'}</p>
                            <input type='range' value={size} min={1} max={10} onChange={e => handleSize(e.target.value)} />
                            <p>{`${size} km`}</p>
                        </div>

                        <div style={{ overflow: 'hidden', borderRadius: 8, marginBottom: 10, position: 'relative' }}>
                            <div style={{ marginLeft: 15 }}>
                                <SearchBox setCenter={changeLocation} setZoom={setZoom} />
                            </div>

                            <div style={{ width: '100%', height: 430 }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: keyMap }}
                                    options={{ fullscreenControl: false }}
                                    tracksViewChanges={false}
                                    center={position}
                                    zoom={zoom}
                                    onClick={e => handleClick({ lat: e.lat, lng: e.lng })}
                                    onGoogleApiLoaded={handleCircle}
                                    yesIWantToUseGoogleMapApiInternals />
                            </div>
                        </div>
                        <Button onAction={updateInfo} loading={loading} />
                    </Container>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = state => ({
    stateView: state.View.stateView,
    data: state.Partners.partnersEdit,
    token: state.Panel.token,
})

export default connect(mapStateToProps, { changeView, updatePartners })(Map);
