import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.vehicles

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, model, year, plates, city, company, companyId, picture, docPicture, docExpire, availability) =>{
    const obj = {
        Model:              model,
        Year:               year,
        Plates:             plates,
        DocumentExpires:    docExpire,
        City:               city,
        Company:            company,
        CompanyId:          companyId.trim(),
        Availability:       availability,
        Picture:            picture,
        DocumentPicture:    docPicture
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, model, year, plates, city, company, companyId, picture, docPicture, docExpire, availability) =>{
    const obj = {
        Model:              model,
        Year:               year,
        Plates:             plates,
        DocumentExpires:    docExpire,
        City:               city,
        Company:            company,
        CompanyId:          companyId.trim(),
        Availability:       availability,
        Picture:            picture,
        DocumentPicture:    docPicture
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const postValidate = (model, year, plates, city, company, companyId, picture, docPicture) =>{
    if(!model){
        return {flag:false, message:'Parece que algo anda mal con el modelo.'}
    }
    if(!year){
        return {flag:false, message:'Parece que algo anda mal con el año.'}
    }
    if(!plates){
        return {flag:false, message:'Parece que algo anda mal con las placas.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!company || company === 'ERROR'){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
        // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
    }
    if(!companyId){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
    }
    if(!picture){
        return {flag:false, message:'Parece que algo anda mal con la foto del vehiculo.'}
    }
    if(!docPicture){
        return {flag:false, message:'Parece que algo anda mal con la foto de la tarjeta de circulación.'}
    }
    return {flag:true, message:''}
}

export const updateValidate = (model, year, plates, city, company, companyId) =>{
    if(!model){
        return {flag:false, message:'Parece que algo anda mal con el modelo.'}
    }
    if(!year){
        return {flag:false, message:'Parece que algo anda mal con el año.'}
    }
    if(!plates){
        return {flag:false, message:'Parece que algo anda mal con las placas.'}
    }
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!company || company === 'ERROR'){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
        // return {flag:false, message:'Parece que algo anda mal con el nombre de la empresa.'}
    }
    if(!companyId){
        return {flag:false, message:'Parece que algo anda mal con el id de la empresa.'}
    }
    return {flag:true, message:''}
}