import {urlApi, urlPath, get, post, update, deleteRegister} from '../../values/api';
const url = urlApi + urlPath.cities

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const postData = async(token, city, state, country, area) =>{
    const obj = {
        City:       city,
        State:      state,
        Country:    country,
        Area:      area
    }
    return await post(obj, url, token)
}

export const updateData = async(id, token, city, state, country, area) =>{
    const obj = {
        City:       city,
        State:      state,
        Country:    country,
        Area:      area
    }
    return await update(obj, url, id, token)
}

export const deleteData = async(id, token) =>{
    return deleteRegister(url, id, token)
}

export const validateData = (city, state, country, area) =>{
    if(!city){
        return {flag:false, message:'Parece que algo anda mal con la ciudad.'}
    }
    if(!state){
        return {flag:false, message:'Parece que algo anda mal con el estado.'}
    }
    if(!country){
        return {flag:false, message:'Parece que algo anda mal con el país.'}
    }
    if(area.length===0){
        return {flag:false, message:'Parece que algo anda mal con el area.'}
    }
    return {flag:true, message:''}
}