import {useState}       from 'react';
import {Container, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton} from '@material-ui/core';
import {connect} from 'react-redux';
// Redux
import {changeView} from '../../redux/actions/Menu';
import {getServices, updateServices} from '../../redux/actions/Service';
// Values
import {Ic_edit, StateView, stateData} from '../../values';
import {formatDate} from '../../utils';
// Components
import LoadingData      from '../global/LoadingData';
import Pagination       from '../global/ButtonsPagination';
// Styles
import {useStyles}  from '../../style';
// Api
import {getData}    from '../../utils/service';

const Tables = ({data, status, token, records, changeView, getServices, updateServices}) => {
    const classes = useStyles()
    const [page,setPage]    = useState(1)

    const edit = (item) => {
        changeView(StateView.state3)
        updateServices(item)
    }
    const GetData = async(page) =>{
        const res = await getData(token, `?Page=${page}`)
        getServices(res.data)
        window.scrollTo(0, 0)
    }
    const changePage = (e,v) =>{
        setPage(v)
        GetData(v)
    }
    const translateService = (service) =>{
        let text = ''
        if(service==='TYRE_MOBILE'){
            text = 'Llantera  móvil'
        }
        if(service==='TYRE'){
            text = 'Llantera fija'
        }
        if(service==='TOW'){
            text = 'Grúa'
        }
        return text
    }
    return (
        <div style={{position:'relative'}}>
            {
                status===stateData.state2?
                    <Container maxWidth="xl">
                        <TableContainer style={{background:'#fff'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow style={{background:'#132327', height:50}}>
                                        {/* {
                                            ['Solicitud', 'Tipo', 'Empresa', 'Socio', 'Usuario', 'Editar'].map((item, index) =>
                                                <TableCell align="center" key={index} className={classes.table_title3} style={{fontFamily:'bold', color:'#fff'}}> {item} </TableCell>
                                            )
                                        } */}
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'20%'}}> {'Solicitud'} </TableCell>
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'20%'}}> {'Tipo'} </TableCell>
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'20%'}}> {'Empresa'} </TableCell>
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'10%'}}> {'Socio'} </TableCell>
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'10%'}}> {'Usuario'} </TableCell>
                                        <TableCell align="center" className={classes.table_title3} style={{fontFamily:'bold', color:'#fff', width:'20%'}}> {'Editar'} </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data&&
                                        data.map((item, index) =>(
                                            <TableRow key={index} style={{borderBottom:`1px solid #f3f4f7`}}>
                                                <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}>{formatDate(item.StatusDate)}</TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}>{translateService(item.Service)}</TableCell>
                                                <TableCell align="center" className={classes.table_text2} style={{fontFamily:'regular'}}><span style={{fontWeight:700}}>{item.PartnerCompany}</span></TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}>{item.PartnerName}</TableCell>
                                                <TableCell align="center" className={classes.table_text3} style={{fontFamily:'regular'}}>{item.UserName}</TableCell>
                                                <TableCell align="center" className={classes.table_text3}>
                                                    <IconButton onClick={() => edit(item)}>
                                                        <img alt="ic_edit" src={Ic_edit} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination records={records} page={page} changePage={changePage} />
                    </Container>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Services.services,
    status:     state.Services.status,
    token:      state.Panel.token,
    records:    state.Services.records
})

export default connect(mapStateToProps, {changeView, getServices, updateServices})(Tables);