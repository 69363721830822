import { useState } from "react";
import {
  Container,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
// Redux
import { changeView } from "../../redux/actions/Menu";
import { getUsers, updateUsers } from "../../redux/actions/Users";
// Values
import { Ic_edit, StateView, stateData } from "../../values";
// Components
import LoadingData from "../global/LoadingData";
import Pagination from "../global/ButtonsPagination";
// Styles
import { useStyles } from "../../style";
// Api
import { getData } from "../../utils/users";

const Tables = ({ data, status, token, records, changeView, getUsers, updateUsers }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const edit = async (item) => {
    const extra = `/${item.Id}`;
    const res = await getData(token, extra);

    if (res.flag) {
      changeView(StateView.state3);
      updateUsers(res.data);
    }
  };
  const GetData = async (page) => {
    const res = await getData(token, `?Page=${page}`);
    getUsers(res.data);
    window.scrollTo(0, 0);
  };
  const changePage = (e, v) => {
    setPage(v);
    GetData(v);
  };
  return (
    <div style={{ position: "relative" }}>
      {status === stateData.state2 ? (
        <Container maxWidth="xl">
          <TableContainer style={{ background: "#fff" }}>
            <Table size="small">
              <TableHead>
                <TableRow style={{ background: "#132327", height: 50 }}>
                  {["Nombre", "Teléfono", "Email", "Editar"].map((item, index) => (
                    <TableCell
                      align="center"
                      key={index}
                      className={classes.table_title1}
                      style={{ fontFamily: "bold", color: "#fff" }}
                    >
                      {" "}
                      {item}{" "}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((item, index) => (
                    <TableRow key={index} style={{ borderBottom: `1px solid #f3f4f7` }}>
                      <TableCell align="center" className={classes.table_text1} style={{ fontFamily: "regular" }}>
                        {item.Name} {item.Surname}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text1} style={{ fontFamily: "regular" }}>
                        {item.Phone}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text1} style={{ fontFamily: "regular" }}>
                        {item.Email}
                      </TableCell>
                      <TableCell align="center" className={classes.table_text1}>
                        <IconButton onClick={() => edit(item)}>
                          <img alt="ic_edit" src={Ic_edit} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination records={records} page={page} changePage={changePage} />
        </Container>
      ) : (
        <LoadingData />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.Users.users,
  status: state.Users.status,
  token: state.Panel.token,
  records: state.Users.records,
});

export default connect(mapStateToProps, { changeView, getUsers, updateUsers })(Tables);
